export default class AttributeMetadata {
  public model: string;
  public pk: number;
  public fields: {
    attributeName: string;
    description: string;
    keywords: string;
    rangeMin: number;
    rangeMax: number;
    mean: number;
    standardDeviation: number;
    unit: string;
  };
}

export interface MetadataTag {
  id: number,
  name: string
}

export interface ValueFormatOptions {
  decimal_places: number,
  group: boolean,
  is_pre: boolean,
  multiply: number
}

export interface ValueFormat {
  id: number,
  is_simple: boolean,
  is_currency: boolean,
  name: string,
  options: ValueFormatOptions,
  unit: string,
}

export interface AttributeMetadataDetails {
  id: number,
  name: string,
  description: string,
  short_description: string | null,
  unit: string,
  tags: Array<MetadataTag> | null,
  main_tag: MetadataTag | null,
  data_version: string | null,
  data_last_update: string | null,
  data_processor: string | null,
  source_provider: string | null,
  source_provider_link: string,
  coverage_general: string | null,
  epsg_code: number,
  min_value: number,
  max_value: number,
  standard_deviation: number,
  mean: number,
  value_formatter?: ValueFormat,
  year: string | null
}
