import React from "react";
import { useSelector } from "react-redux";

import { selectors } from "../../../store/store";

const RestrictedSimpleMode = ({ children }: { children: React.ReactNode }) => {
  const isSimpleMode = useSelector(selectors.user.isSimpleMode);

  if (isSimpleMode) {
    return null;
  }

  return <>{children}</>;
};

export default RestrictedSimpleMode;
