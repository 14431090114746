import React from "react";

const svgButton = `<svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="463.204642pt"
      height="463.409345pt"
      viewBox="0 0 463.204642 463.409345"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(-24.421723,487.782980) scale(0.100000,-0.100000)"
        fill="#b9c6d1"
        stroke="none"
      >
        <path
          d="M2325 4874 c-342 -49 -526 -102 -780 -224 -238 -115 -428 -252 -626
-449 -355 -355 -567 -767 -656 -1276 -25 -143 -25 -587 0 -730 89 -509 301
-921 656 -1276 356 -356 775 -571 1279 -656 153 -26 571 -26 724 0 504 85 923
300 1279 656 356 356 571 775 656 1279 26 153 26 571 0 724 -85 504 -300 923
-656 1279 -354 353 -770 568 -1271 655 -91 16 -529 29 -605 18z m595 -915 c67
-31 140 -102 172 -166 19 -40 23 -64 23 -138 0 -81 -3 -95 -29 -143 -35 -63
-86 -111 -158 -149 -48 -25 -63 -28 -158 -28 -93 0 -112 3 -162 27 -69 32
-136 97 -169 163 -20 41 -24 65 -24 135 0 72 4 93 27 135 26 51 103 131 149
155 88 48 236 52 329 9z m-331 -950 c70 -15 145 -56 193 -105 88 -89 113 -181
97 -359 -8 -83 -29 -171 -95 -408 -105 -371 -125 -460 -127 -562 -2 -94 16
-135 73 -162 53 -25 177 -23 269 5 40 13 75 21 78 18 3 -2 -3 -39 -13 -80
l-18 -76 -50 -19 c-312 -117 -328 -121 -476 -121 -168 -1 -265 29 -352 108
-90 82 -124 176 -114 320 9 123 21 178 110 494 96 338 116 429 116 524 0 138
-41 178 -185 178 -55 0 -103 -7 -154 -22 -40 -13 -75 -22 -77 -20 -5 6 34 150
43 158 12 12 189 76 289 105 148 44 266 51 393 24z"
        />
      </g>
</svg>`;

export default class Info extends React.Component {
  public render() {
    return (
      <svg
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        width="463.204642pt"
        height="463.409345pt"
        viewBox="0 0 463.204642 463.409345"
        preserveAspectRatio="xMidYMid meet"
      >
        <g
          transform="translate(-24.421723,487.782980) scale(0.100000,-0.100000)"
          fill="#000000"
          stroke="none"
        >
          <path
            d="M2325 4874 c-342 -49 -526 -102 -780 -224 -238 -115 -428 -252 -626
-449 -355 -355 -567 -767 -656 -1276 -25 -143 -25 -587 0 -730 89 -509 301
-921 656 -1276 356 -356 775 -571 1279 -656 153 -26 571 -26 724 0 504 85 923
300 1279 656 356 356 571 775 656 1279 26 153 26 571 0 724 -85 504 -300 923
-656 1279 -354 353 -770 568 -1271 655 -91 16 -529 29 -605 18z m595 -915 c67
-31 140 -102 172 -166 19 -40 23 -64 23 -138 0 -81 -3 -95 -29 -143 -35 -63
-86 -111 -158 -149 -48 -25 -63 -28 -158 -28 -93 0 -112 3 -162 27 -69 32
-136 97 -169 163 -20 41 -24 65 -24 135 0 72 4 93 27 135 26 51 103 131 149
155 88 48 236 52 329 9z m-331 -950 c70 -15 145 -56 193 -105 88 -89 113 -181
97 -359 -8 -83 -29 -171 -95 -408 -105 -371 -125 -460 -127 -562 -2 -94 16
-135 73 -162 53 -25 177 -23 269 5 40 13 75 21 78 18 3 -2 -3 -39 -13 -80
l-18 -76 -50 -19 c-312 -117 -328 -121 -476 -121 -168 -1 -265 29 -352 108
-90 82 -124 176 -114 320 9 123 21 178 110 494 96 338 116 429 116 524 0 138
-41 178 -185 178 -55 0 -103 -7 -154 -22 -40 -13 -75 -22 -77 -20 -5 6 34 150
43 158 12 12 189 76 289 105 148 44 266 51 393 24z"
          />
        </g>
      </svg>
    );
  }
}

export { svgButton };
