import React from "react";

import "./CheckBox.css";

const CheckBox = (props: any) => {
  return (
    <label className="checkbox">
      <input disabled={true} type="checkbox" {...props} />
      <span className="checkmark"></span>
    </label>
  );
};

export default CheckBox;
