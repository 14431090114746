import React from "react";

export default class User extends React.Component {
  public render() {
    return (
      <svg
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        width="431.903892pt"
        height="505.650950pt"
        viewBox="0 0 431.903892 505.650950"
        preserveAspectRatio="xMidYMid meet"
      >
        <g
          transform="translate(-40.017234,508.535968) scale(0.100000,-0.100000)"
          fill="#000000"
          stroke="none"
        >
          <path
            d="M2423 5080 c-504 -57 -858 -369 -979 -860 -131 -537 58 -1223 467
-1691 77 -89 94 -137 93 -259 -1 -94 -26 -153 -91 -218 -40 -40 -68 -55 -173
-96 -379 -147 -727 -331 -955 -504 -83 -63 -219 -196 -267 -260 -51 -69 -95
-162 -108 -231 -15 -81 -12 -258 6 -326 46 -177 201 -310 483 -412 368 -133
892 -194 1666 -194 885 0 1458 81 1808 257 202 102 305 216 338 374 17 81 6
301 -18 371 -90 261 -462 554 -1002 788 -97 43 -239 99 -316 127 -163 57 -210
93 -240 183 -21 65 -21 181 1 270 14 56 26 77 78 136 421 477 607 1201 449
1744 -119 412 -425 696 -840 780 -115 23 -297 33 -400 21z"
          />
        </g>
      </svg>
    );
  }
}
