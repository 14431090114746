import React from "react";

export default class Open extends React.Component {
  public render() {
    return (
      <svg
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 511.719942 508.523190"
        preserveAspectRatio="xMidYMid meet"
      >
        <g
          transform="translate(-0.140029,511.622459) scale(0.100000,-0.100000)"
          fill="#000000"
          stroke="none"
        >
          <path
            d="M505 5113 c-45 -6 -123 -32 -180 -60 -130 -65 -232 -179 -287 -323
l-33 -85 -3 -1657 c-2 -1437 0 -1667 13 -1729 45 -218 214 -394 430 -449 73
-19 111 -20 602 -20 l523 0 0 195 0 195 -507 0 c-470 0 -511 2 -548 19 -51 23
-101 79 -114 128 -7 26 -11 428 -11 1226 l0 1187 2170 0 2170 0 0 -1187 c0
-798 -4 -1200 -11 -1226 -13 -49 -63 -105 -114 -128 -37 -17 -82 -19 -647 -19
l-608 0 0 -195 0 -195 623 0 c591 0 628 1 702 20 216 55 385 231 430 449 13
62 15 292 13 1729 l-3 1657 -33 85 c-68 177 -184 288 -382 364 l-55 21 -2055
1 c-1130 1 -2068 -1 -2085 -3z m234 -592 c51 -37 61 -67 61 -192 0 -94 -3
-117 -20 -144 -32 -51 -72 -65 -190 -65 -109 0 -137 8 -180 55 -23 25 -25 35
-28 142 -4 119 4 157 38 188 37 34 91 46 191 42 80 -3 102 -8 128 -26z m578
11 c12 -7 35 -29 50 -49 27 -35 28 -41 28 -147 0 -98 -3 -115 -23 -147 -34
-55 -72 -69 -194 -69 -85 0 -109 4 -136 20 -58 36 -72 72 -72 188 0 142 17
178 97 208 45 17 216 14 250 -4z m3411 -199 l-3 -198 -1575 0 -1575 0 -3 198
-2 197 1580 0 1580 0 -2 -197z"
          />
          <path
            d="M2543 2742 c-28 -4 -38 -15 -537 -571 -399 -444 -416 -465 -416 -503
0 -28 7 -46 25 -63 l24 -25 259 0 c142 0 261 -2 263 -4 2 -2 1 -94 -3 -203
-15 -477 -117 -762 -348 -976 -149 -138 -367 -254 -644 -342 l-71 -23 45 -1
c87 -2 336 20 460 40 424 67 742 218 975 460 243 254 370 576 388 989 l2 55
263 5 c262 5 264 5 283 29 11 13 19 39 19 60 0 35 -28 69 -457 551 -309 346
-466 516 -483 520 -14 3 -35 4 -47 2z"
          />
        </g>
      </svg>
    );
  }
}
