import React, { CSSProperties } from "react";
import "./Button.css";

interface IButtonProps {
  title: string;

  style?: CSSProperties;
  className?: string;
  disabled?: boolean;
  defaultOn?: boolean;
  icon?: React.ReactNode;
  onClick?: () => void;
  submit?: boolean;
  switchOnClick?: (on: boolean) => void;
  switchOnIcon?: React.ReactNode;
  switchOnOff?: boolean;
  switchOnTitle?: string;
  isSecondary?: boolean;
}

interface IButtonState {
  on: boolean;
}

export default class Button extends React.Component<IButtonProps,
  IButtonState> {
  constructor(props: IButtonProps) {
    super(props);
    this.state = {
      on: props.defaultOn ? props.defaultOn! : false,
    };
  }

  public render() {
    const switchOffIconOrTitle = this.props.icon
      ? this.props.icon
      : this.props.title;
    const switchOnIconOrTitle = this.props.switchOnIcon
      ? this.props.switchOnIcon
      : this.props.switchOnTitle;
    return (
      <div className={this.props.className ? this.props.className : ""}
           style={this.props.style ? this.props.style : {}}>
        <button
          className={this.generateClasses()}
          onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
            this.onClick(event)
          }
        >
          {this.state.on ? switchOnIconOrTitle : switchOffIconOrTitle}
        </button>
      </div>
    );
  }

  private onClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    event.preventDefault();
    const buttonEnabled =
      this.props.disabled === undefined || !this.props.disabled;
    if (!buttonEnabled) {
      return;
    }
    if (this.props.onClick) {
      this.props.onClick();
    }
    if (this.props.switchOnOff && this.props.switchOnClick) {
      const on = !this.state.on;
      this.props.switchOnClick(on);
      this.setState({ on });
    }
  }

  private generateClasses(): string {
    const baseClass = "button";
    const classNames = [baseClass];
    if (this.props.icon) {
      classNames.push(`${baseClass}-icon`);
    }
    if (this.props.submit) {
      classNames.push(`${baseClass}-submit`);
    }
    if (this.props.disabled) {
      classNames.push(`${baseClass}-disabled`);
    }
    if (this.props.isSecondary) {
      classNames.push(`${baseClass}-isSecondary`);
    }
    if (this.state.on) {
      classNames.push(`${baseClass}-switch-on`);
    } else {
      classNames.push(`${baseClass}-switch-off`);
    }
    return classNames.join(" ");
  }
}
