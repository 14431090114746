export const COOKIE_FOCUSED_PORTFOLIO = "udl-focused-portfolio";
export const COOKIE_TILE_LAYER_KEY = "udl-tilelayer-key";
export const COOKIE_DEFAULT_OPTIONS = {
  expires: 365,
};

export const ROUTE_MAIN_PAGE = "/";
export const ROUTE_LOGIN_PAGE = "/login";
export const ROUTE_PROFILER_PAGE="/profiler";
export const ROUTE_EXPORT_PAGE="/export";
export const ROUTE_MANAGER = "/manager/";

export const GOOGLE_STREETVIEW_IMAGE_SRC= "https://maps.googleapis.com/maps/api/streetview?size=264x264&source=outdoor&fov=90&heading=235&pitch=10&key=AIzaSyAY0v92QgJnVnFDZC8Es3mTivhmGaSLi1w";
export const GOOGLE_STREETVIEW_IMAGE_SRC_LANDSCAPE= "https://maps.googleapis.com/maps/api/streetview?size=320x213&source=outdoor&fov=90&heading=235&pitch=10&key=AIzaSyAY0v92QgJnVnFDZC8Es3mTivhmGaSLi1w";

