import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { InputLabel, MenuItem, Select } from "@mui/material";

const DropdownLanguage = () => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language !== "" ? i18n.language : "en";
  const [language, setLanguage] = useState(currentLanguage);

  useEffect(() => {
    if (language === "en-GB") {
      i18n.changeLanguage("en");
      setLanguage('en');
    } else {
      i18n.changeLanguage(language);
    }
  }, [language, i18n]);

  const handleChange = async (event: any) => {
    setLanguage(event.target.value);
    window.location.reload();
  };

  return (
    <React.Fragment>
      <InputLabel id="language-label-select">{t("global.language")}</InputLabel>
      <Select labelId="language-label-select" id="language-select" value={language} variant={"standard"}
              onChange={handleChange}>
        <MenuItem value={"de"}>{t("global.german")}</MenuItem>
        <MenuItem value={"en"}>{t("global.english")}</MenuItem>
      </Select>
    </React.Fragment>
  );
};

export default DropdownLanguage;
