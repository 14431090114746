import * as React from "react";
import { useDispatch } from "react-redux";

import { actions } from "../../store/store";
import { ProfilerContent } from "../../components/tools";

import ToolbarProfiler from "./Toolbar/ToolbarProfiler";
import demoData from "./demoData";
import "./IframePage.css";

const IframePage = () => {
  const dispatch = useDispatch();
  // @ts-ignore
  const setObject = (obj: any) => dispatch(actions.profiler.setObject(obj));
  // @ts-ignore
  const setFiles = (obj: any) => dispatch(actions.profiler.setFiles(obj));
  // @ts-ignore
  const setNotes = (obj: any) => dispatch(actions.profiler.setKeyNotes(obj));

  React.useEffect(() => {
    setObject({
      id: 47,
      user: 11,
      query_params: "lat=47.200148032;lng=8.526190054",
      object_id: "3c81cc15-37b7-468e-8a21-f5793e455243",
      name: "Object - lat: 47.200148032, lng: 8.526190054",
      description: null,
      location: {
        latitude: "47.200148032",
        longitude: "8.526190054",
      },
      address: "Jöchlerweg 4, 6340 Baar",
    });
    setFiles(demoData);
    setNotes([]);
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <ToolbarProfiler />
      <main className="profiler">
        <ProfilerContent />
      </main>
    </div>
  );
};

export default IframePage;
