import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";

import { ActivityIndicator } from "../../../elementar";

import "./ContentTabs.css";

interface ContentTabsProps {
  children: React.ReactNode;
  tabs: string[];
  isInline?: boolean;
  disabledTabs?: string[];
  withParmUrl?: boolean;
  showActivity?: boolean;
  errorMessage?: string | undefined;
  hasTabsOnly?: boolean;
}

const getDefaultTab = (tabs: string[], tab: string) => {
  const activeTabIndex = tabs.indexOf(tab);
  return activeTabIndex !== -1 ? activeTabIndex : 0;
};

const ContentTabs = ({
  children,
  tabs,
  isInline,
  withParmUrl = false,
  disabledTabs = [],
  showActivity = false,
  errorMessage = undefined,
  hasTabsOnly = false,
}: ContentTabsProps) => {
  const history = useHistory();
  const location = useLocation();
  const { tab } = queryString.parse(location.search);
  const [activeTab, setActiveTab] = useState(
    getDefaultTab(tabs, (tab as string) || ""),
  );

  useEffect(() => {
    setActiveTab(getDefaultTab(tabs, (tab as string) || ""));
    // eslint-disable-next-line
  }, []);

  const classes = ["content-tabs"];

  if (isInline) {
    classes.push("content-tabs--inline");
  }

  const isDisabled = (tabName: string) => disabledTabs.indexOf(tabName) !== -1;

  return (
    <div className={classes.join(" ")}>
      <div className="content-tabs__tabs">
        {tabs.map((tab, k) => (
          <span
            key={`tab-${k}`}
            className={`${activeTab === k ? "active" : ""} ${
              isDisabled(tab) ? "disabled" : ""
            }`}
            onClick={() => {
              if (!isDisabled(tab)) {
                if (withParmUrl) {
                  history.replace(`${location.pathname}?tab=${tab}`);
                }
                setActiveTab(k);
              }
            }}
          >
            {tab}
          </span>
        ))}
      </div>
      {hasTabsOnly ? children : React.Children.toArray(children)[activeTab]}
      <ActivityIndicator
        showActivity={showActivity}
        errorMessage={errorMessage}
      />
    </div>
  );
};

export default ContentTabs;
