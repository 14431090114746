import { createReducer } from "@reduxjs/toolkit";

import actions from "./manager.actions";
import {
  Portfolio,
  Building,
  Property,
  Unit,
  Log,
  Asset,
} from "./manager.types";

type ObjectTypeOrNull<Obj> = null | Obj;

export interface ManagerState {
  portfolios: Portfolio[];
  portfolio: ObjectTypeOrNull<Portfolio>;
  properties: Property[];
  propertiesLocationColumns: string[];
  property: ObjectTypeOrNull<Property>;
  buildings: Building[];
  building: ObjectTypeOrNull<Building>;
  navigationBuilding: ObjectTypeOrNull<Building>;
  buildingsLocationColumns: string[];
  units: Unit[];
  unit: ObjectTypeOrNull<Unit>;
  unitsLocationColumns: string[];
  portfolioNote: [];
  propertyNote: [];
  buildingNote: [];
  unitlioNote: [];
  logs: Log[];
  assets: Asset[];
  markers: Building[];
}

const initialState: ManagerState = {
  portfolios: [],
  portfolio: null,
  properties: [],
  property: null,
  propertiesLocationColumns: [],
  buildings: [],
  building: null,
  navigationBuilding: null,
  buildingsLocationColumns: [],
  units: [],
  unit: null,
  unitsLocationColumns: [],
  portfolioNote: [],
  propertyNote: [],
  buildingNote: [],
  unitlioNote: [],
  logs: [],
  assets: [],
  markers: [],
};

export default createReducer(initialState, {
  [actions.setPortfolios.type]: (state, action) => {
    state.portfolios = action.payload.sort((a: any, b: any) => a.id - b.id);
  },
  [actions.setPortfolio.type]: (state, action) => {
    state.portfolio = action.payload;
  },
  [actions.setProperties.type]: (state, action) => {
    state.properties = action.payload.sort((a: any, b: any) => a.id - b.id);
  },
  [actions.setProperty.type]: (state, action) => {
    state.property = action.payload;
  },
  [actions.setBuildings.type]: (state, action) => {
    state.buildings = action.payload.sort((a: any, b: any) => a.id - b.id);
  },
  [actions.setBuilding.type]: (state, action) => {
    state.building = action.payload;
  },
  [actions.setNavigationBuilding.type]: (state, action) => {
    state.navigationBuilding = action.payload;
  },
  [actions.setUnits.type]: (state, action) => {
    state.units = action.payload.sort((a: any, b: any) => a.id - b.id);
  },
  [actions.setUnit.type]: (state, action) => {
    state.unit = action.payload;
  },
  [actions.setBuildingsLocationColumns.type]: (state, action) => {
    state.buildingsLocationColumns = action.payload;
  },
  [actions.setUnitsLocationColumns.type]: (state, action) => {
    state.unitsLocationColumns = action.payload;
  },
  [actions.setPropertiesLocationColumns.type]: (state, action) => {
    state.propertiesLocationColumns = action.payload;
  },
  [actions.setLogs.type]: (state, action) => {
    state.logs = action.payload;
  },
  [actions.setAssets.type]: (state, action) => {
    state.assets = action.payload;
  },
  [actions.setMarkers.type]: (state, action) => {
    state.markers = action.payload;
  },
});
