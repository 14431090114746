/* eslint-disable */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Cookies from "js-cookie";

import { selectors } from "../../../store/store";

import "./QuotaBar.css";

interface QuotaBarProps {
  isQuotaLeft: boolean;
}

const QuotaBar: React.FC<QuotaBarProps> = ({ isQuotaLeft }) => {
  const [isHide, setIsHide] = useState(
    isQuotaLeft || JSON.parse(Cookies.get("QuotaBarOff") || "false"),
  );

  let closeAutomaticallyTimeout: any = null;

  useEffect(() => {
    return () => {
      if (closeAutomaticallyTimeout !== null) {
        clearTimeout(closeAutomaticallyTimeout);
      }
    };
  });

  if (isHide) {
    return null;
  }

  closeAutomaticallyTimeout = setTimeout(() => {
    Cookies.set("QuotaBarOff", "true", { expires: 7 });
    setIsHide(true);
  }, 4000);

  return (
    <div className="quota-bar">
      <a
        href="#"
        className="quota-bar__cta--close"
        onClick={() => {
          Cookies.set("QuotaBarOff", "true", { expires: 7 });
          setIsHide(true);
        }}
      />
      Reports limit is over, please contact us to increase the report limit.
      <a className="quota-bar__cta" href="mailto:support@urbandatalab.net">
        Contact us
      </a>
    </div>
  );
};

export default connect((state) => ({
  isQuotaLeft: selectors.user.isQuotaLeft(state),
}))(QuotaBar);
