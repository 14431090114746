import React, { useEffect, useState } from "react";
import ReactMde from "react-mde";
import * as Showdown from "showdown";

import "react-mde/lib/styles/css/react-mde-all.css";

import "./Notes.css";
import { usePrevious } from "../../../util/hooks/usePrevious";
import { IconButton, Tooltip } from "@mui/material";
import { Cancel, Save } from "@mui/icons-material";

const converter = new Showdown.Converter({
  tables: true,
  simplifiedAutoLink: true,
  strikethrough: true,
  tasklists: true,
});

interface NotesProps {
  isFetchingData?: boolean;
  onSaveKeyNote: (value: any) => void;
  note?: any;
}

const DEFAULT_TEXT = "No key notes.";

const Notes = ({ note, isFetchingData, onSaveKeyNote }: NotesProps) => {
  const [value, setValue] = useState(note || DEFAULT_TEXT);
  const previousValue = usePrevious(value);
  const [selectedTab, setSelectedTab] = useState<"write" | "preview" | undefined>("preview");
  const [saveValid, setSaveValid] = useState(false);

  useEffect(() => {
    if (previousValue && (previousValue !== value)) {
      if (value === DEFAULT_TEXT) {
        setSaveValid(false);
        return;
      }
      if(note === value){
        setSaveValid(false);
        return;
      }
      setSaveValid(true);
    } else {
      setSaveValid(false);
    }
    // eslint-disable-next-line
  }, [value]);

  useEffect(() => {
    setValue(note || DEFAULT_TEXT);
  }, [note]);

  const saveKeyNote = () => {
    setSelectedTab("preview");
    setSaveValid(false);
    onSaveKeyNote(value);
  };

  const rejectKeyNote = () => {
    setSelectedTab("preview");
    setValue(note || DEFAULT_TEXT);
  };

  return (
    <div className="key-notes">
      <h5>
        Key Notes
        <span className="key-notes__actions">
          {saveValid && (
            <React.Fragment>

              <Tooltip title={<div>{"Save current notes"}</div>}>
                <IconButton size="small" aria-label="save" onClick={() => {
                  saveKeyNote()
                }}>
                  <Save style={{ color: "white" }} fontSize="small" />
                </IconButton>
              </Tooltip>

              {note !== undefined && (
                <Tooltip title={<div>{"Cancel current changes"}</div>}>
                  <IconButton size="small" aria-label="save" onClick={() => {
                    rejectKeyNote()
                  }}>
                    <Cancel style={{ color: "white" }} fontSize="small" />
                  </IconButton>
                </Tooltip>
              )}
            </React.Fragment>
          )}
          {/*{selectedTab === "preview" ? (*/}
          {/*  <button onClick={() => setSelectedTab("write")}>*/}
          {/*    <FontAwesomeIcon icon={faPen} />*/}
          {/*  </button>*/}
          {/*) : (*/}
          {/*  <>*/}
          {/*    <button onClick={() => saveKeyNote()}>*/}
          {/*      <FontAwesomeIcon icon={faSave} />*/}
          {/*    </button>*/}
          {/*    {note !== undefined && (*/}
          {/*      <button onClick={() => rejectKeyNote()}>*/}
          {/*        <FontAwesomeIcon icon={faTimesCircle} />*/}
          {/*      </button>*/}
          {/*    )}*/}
          {/*  </>*/}
          {/*)}*/}
        </span>
      </h5>
      {isFetchingData ? (
        <div style={{ minHeight: "210px" }} />
      ) : (
        <ReactMde
          value={value}
          onChange={setValue}
          selectedTab={selectedTab}
          onTabChange={setSelectedTab}
          generateMarkdownPreview={(markdown) =>
            Promise.resolve(converter.makeHtml(markdown))
          }
        />
      )}
    </div>
  );
};

export default Notes;
