import { atom } from "recoil";

export interface MapPositionInformation{
  city: string,
  country_code: string
}

const initMapPositionInformationState = (): MapPositionInformation => ({
  city: '',
  country_code: 'ch',
})

export const currentMapPositionInformationState = atom({
  key: 'currentMapPositionInformationState', // unique ID (with respect to other atoms/selectors)
  default: initMapPositionInformationState(), // default value (aka initial value)
});
