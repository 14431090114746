import reducer from "./profiler.reducer";
import actions from "./profiler.actions";
import selectors from "./profiler.selectors";

const profiler = {
  reducer,
  actions,
  selectors,
};

export default profiler;
