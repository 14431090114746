import React from "react";
import Loadable from "react-loadable";

import {AsyncLoadingIndicator} from "../../components/elementar";

import withUser from '../../components/tools/withUser/withUser';


const LoadableComponent = Loadable({
  loader: () => import("./MainPage"),
  loading: AsyncLoadingIndicator
});

class MainPageAsync extends React.Component {
  public render() {
    return <LoadableComponent/>;
  }
}

export default withUser(MainPageAsync)