import { TableCell, TableHead, TableRow } from "@mui/material";
import { AttributeMetadataDetails } from "../../../../util/models/AttributeMetadata";

interface HeadCell {
  disablePadding: boolean;
  id: keyof AttributeMetadataDetails;
  label: string;
  numeric: boolean;
}

const headCells: HeadCell[] = [
  { id: "name", numeric: false, disablePadding: true, label: "Attribute Name" },
  { id: "description", numeric: false, disablePadding: false, label: "Description" },
];

interface EnhancedTableProps {

}

function EnhancedTableHead(props: EnhancedTableProps) {
  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox"></TableCell>
        {headCells.map((headCell) => (
          <TableCell key={headCell.id} align={headCell.numeric ? "right" : "left"}
                     padding={headCell.disablePadding ? "none" : "normal"}>
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default EnhancedTableHead;
