import React from "react";
import { BasePanel, PanelItem } from "..";
import { E, Events } from "../../../util/events";
import { AttributeConfiguration, AttributeMetadata } from "../../../util/models";
import { Input, Slider } from "../../elementar";
import "./Editor.css";
import Mixpanel, { MIXPANEL_EVENTNAME_EDITED_LAYER, MIXPANEL_PAGENAME_SCOUT } from "../../../util/Mixpanel";
import { Translation } from "react-i18next";
import { IconButton, Tooltip } from "@mui/material";
import { FunctionsOutlined, TuneOutlined } from "@mui/icons-material";

interface IProps {
  attributeConfigurations: AttributeConfiguration[];
  availableAttributes: AttributeMetadata[];
}

export default class Editor extends React.Component<IProps, {}> {
  public render() {
    if (this.props.attributeConfigurations.length < 1) {
      return (
        <BasePanel>
          <p>Select an attribute first.</p>
        </BasePanel>
      );
    }
    return (
      <BasePanel>
        <ul>
          {this.props.attributeConfigurations.map((configuration, index) => (
            <li key={index}>
              <PanelItem
                className="editor-entry-container"
                title={this.renderAttributeTitle(configuration, index)}
                //buttons={this.buildPanelItemButtons(configuration, index)}
                buttons={[
                  <div>
                    {configuration.getIsExpert() && (
                      <Translation>
                        {
                          (t, { i18n }) =>
                            <Tooltip title={<div>{t("ratings.simpleMode")}</div>}>
                              <IconButton
                                style={{ padding: "6px" }}
                                onClick={() => {
                                  const attribute = this.props.attributeConfigurations[index];
                                  if (attribute) {
                                    attribute.setIsExpert(false);
                                  }
                                  Events.fire(E.ATTRIBUTE_CONFIGURATION_CHANGED, [
                                    this.props.attributeConfigurations,
                                  ]);
                                }}
                                size="large">
                                <TuneOutlined />
                              </IconButton>
                            </Tooltip>
                        }
                      </Translation>
                    )}
                    {!configuration.getIsExpert() && (
                      <Translation>
                        {
                          (t, { i18n }) =>
                            <Tooltip title={<div>{t("ratings.expertMode")}</div>}>
                              <IconButton
                                style={{ padding: "6px" }}
                                onClick={() => {
                                  const attribute = this.props.attributeConfigurations[index];
                                  if (attribute) {
                                    attribute.setIsExpert(true);
                                  }
                                  Events.fire(E.ATTRIBUTE_CONFIGURATION_CHANGED, [
                                    this.props.attributeConfigurations,
                                  ]);
                                }}
                                size="large">
                                <FunctionsOutlined />
                              </IconButton>
                            </Tooltip>
                        }
                      </Translation>
                    )}
                  </div>]
                }
              >
                {configuration.getIsExpert()
                  ? this.renderExpertMode(configuration, index)
                  : this.renderSimpleMode(configuration, index)}
              </PanelItem>
            </li>
          ))}
        </ul>
      </BasePanel>
    );
  }

  private trackAttributeChange(configuration: AttributeConfiguration) {
    const matchingAttribute = this.getMetadataForConfiguration(configuration);
    if (matchingAttribute === undefined) {
      return;
    }
    Mixpanel.getInstance().trackEvent(MIXPANEL_EVENTNAME_EDITED_LAYER, {
      Component: MIXPANEL_PAGENAME_SCOUT,
      "Layer Name": matchingAttribute.fields.attributeName,
    });
  }

  private renderAttributeTitle(
    configuration: AttributeConfiguration,
    index: number,
  ): string {
    const matchingAttribute = this.getMetadataForConfiguration(configuration);
    if (matchingAttribute === undefined) {
      return "renderAttributeTitle error";
    }
    return matchingAttribute.fields.attributeName;
  }

  private getMetadataForConfiguration(
    configuration: AttributeConfiguration,
  ): AttributeMetadata | undefined {
    return this.props.availableAttributes.find(
      (attribute) => attribute.pk === configuration.attributeKey,
    );
  }

  private transformationChanged(event: React.ChangeEvent<HTMLInputElement>, configuration: AttributeConfiguration) {
    const attribute = this.props.attributeConfigurations[
      Number(event.target.dataset.key)
      ];
    if (attribute) {
      this.trackAttributeChange(configuration);
      attribute.setTransformation(event.target.value);
    }
  }

  private weightChanged(event: React.ChangeEvent<HTMLInputElement>, configuration: AttributeConfiguration) {
    const attribute = this.props.attributeConfigurations[
      Number(event.target.dataset.key)
      ];
    if (attribute) {
      this.trackAttributeChange(configuration);
      attribute.setWeight(event.target.value);
    }
  }

  private normalizationChanged(event: React.ChangeEvent<HTMLInputElement>, configuration: AttributeConfiguration) {
    const attribute = this.props.attributeConfigurations[
      Number(event.target.dataset.key)
      ];
    if (attribute) {
      this.trackAttributeChange(configuration);
      attribute.setNormalization(event.target.checked);
      Events.fire(E.REFRESH_MAP_CLICKED);
    }
  }

  private buildPanelItemButtons(
    configuration: AttributeConfiguration,
    index: number,
  ): React.ReactNode[] {
    const expertButton = (
      <Translation>
        {
          (t, { i18n }) =>
            <Tooltip title={<div>{t("ratings.expertMode")}</div>}>
              <IconButton style={{ padding: "6px" }} size="large">
                <FunctionsOutlined />
              </IconButton>
            </Tooltip>
        }
      </Translation>

      // <Button
      //   icon={<Math />}
      //   key={index}
      //   defaultOn={configuration.getIsExpert()}
      //   switchOnOff={true}
      //   switchOnIcon={<Sliders />}
      //   switchOnTitle="Expert"
      //   switchOnClick={(on: boolean) => {
      //     const attribute = this.props.attributeConfigurations[index];
      //     if (attribute) {
      //       attribute.setIsExpert(on);
      //     }
      //     Events.fire(E.ATTRIBUTE_CONFIGURATION_CHANGED, [
      //       this.props.attributeConfigurations,
      //     ]);
      //   }}
      //   title="Simple"
      // />
    );
    return [expertButton];
  }

  private renderSimpleMode(
    configuration: AttributeConfiguration,
    index: number,
  ) {
    const metaData = this.getMetadataForConfiguration(configuration);
    if (!metaData) {
      return "";
    }
    return (
      <div className="editor-entry-mode-simple">
        <div className="editor-entry-transform">
          <p>Select</p>
          <div className="editor-entry-transform-slider">
            <Slider
              max={metaData.fields.rangeMax}
              min={metaData.fields.rangeMin}
              values={[
                configuration.getRangeMin(),
                configuration.getRangeMax(),
              ]}
              onChangeEnded={(values: readonly number[]) => {
                const minValue = values[0];
                const maxValue = values[1];
                if (
                  minValue < metaData.fields.rangeMin ||
                  maxValue > metaData.fields.rangeMax
                ) {
                  return;
                }
                configuration.setRangeMin(minValue);
                configuration.setRangeMax(maxValue);
                this.trackAttributeChange(configuration);
                Events.fire(E.ATTRIBUTE_CONFIGURATION_CHANGED, [
                  this.props.attributeConfigurations,
                ]);
                Events.fire(E.REFRESH_MAP_CLICKED);
              }}
            />
            <div className="editor-entry-transform-slider-textboxes">
              <Input
                className="editor-entry-transform-slider-textboxes-input"
                inputClassName="editor-entry-transform-slider-textboxes-input-inner"
                defaultValue={metaData.fields.rangeMin.toString()}
                value={configuration.getRangeMin().toString()}
                maxValue={configuration.getRangeMax()}
                minValue={metaData.fields.rangeMin}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  const valueNumber = Number(event.target.value);
                  if (valueNumber < metaData.fields.rangeMin) {
                    return;
                  }
                  configuration.setRangeMin(valueNumber);
                  this.trackAttributeChange(configuration);
                  Events.fire(E.ATTRIBUTE_CONFIGURATION_CHANGED, [
                    this.props.attributeConfigurations,
                  ]);
                  Events.fire(E.REFRESH_MAP_CLICKED);
                }}
                type="decimal"
              />
              <Input
                className="editor-entry-transform-slider-textboxes-input"
                inputClassName="editor-entry-transform-slider-textboxes-input-inner"
                defaultValue={metaData.fields.rangeMax.toString()}
                value={configuration.getRangeMax().toString()}
                maxValue={metaData.fields.rangeMax}
                minValue={configuration.getRangeMin()}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  const valueNumber = Number(event.target.value);
                  if (valueNumber > metaData.fields.rangeMax) {
                    return;
                  }
                  configuration.setRangeMax(valueNumber);
                  this.trackAttributeChange(configuration);
                  Events.fire(E.ATTRIBUTE_CONFIGURATION_CHANGED, [
                    this.props.attributeConfigurations,
                  ]);
                  Events.fire(E.REFRESH_MAP_CLICKED);
                }}
                type="decimal"
              />
            </div>
          </div>
        </div>
        <div className="editor-entry-transform">
          <p>Relevance</p>
          <Slider
            max={5}
            min={-5}
            customStepSize={0.5}
            values={[Number(configuration.getWeight())]}
            onChangeEnded={(values: readonly number[]) => {
              configuration.setWeight(values[0].toString());
              this.trackAttributeChange(configuration);
              Events.fire(E.ATTRIBUTE_CONFIGURATION_CHANGED, [
                this.props.attributeConfigurations,
              ]);
              Events.fire(E.REFRESH_MAP_CLICKED);
            }}
          />
        </div>
        <div>
          <Input
            dataSetKey={index.toString()}
            inputClassName="editor-entry-input"
            label="Normalization"
            type="checkbox"
            labelWidth={84}
            onChange={(event) => this.normalizationChanged(event, configuration)}
          />
        </div>
      </div>
    );
  }

  private renderExpertMode(
    configuration: AttributeConfiguration,
    index: number,
  ) {
    const labelWidth = 84;
    return (
      <div className="editor-entry-mode-expert">
        <Input
          className="editor-entry-transform"
          dataSetKey={index.toString()}
          value={configuration.getTransformation()}
          inputClassName="editor-entry-input"
          label="Transform"
          labelWidth={labelWidth}
          onChange={(event) => this.transformationChanged(event, configuration)}
          onValueChangeEnded={() => Events.fire(E.REFRESH_MAP_CLICKED)}
          placeholder="log(x), x**2, ..."
        />
        <Input
          dataSetKey={index.toString()}
          value={configuration.getWeight()}
          inputClassName="editor-entry-input"
          label="Weight"
          labelWidth={labelWidth}
          onChange={(event) => this.weightChanged(event, configuration)}
          onValueChangeEnded={() => Events.fire(E.REFRESH_MAP_CLICKED)}
          placeholder="7, ..."
        />
        <Input
          dataSetKey={index.toString()}
          inputClassName="editor-entry-input"
          label="Normalization"
          type="checkbox"
          labelWidth={labelWidth}
          onChange={(event) => this.normalizationChanged(event, configuration)}
        />
      </div>
    );
  }
}
