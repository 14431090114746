import React, { useState } from "react";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";

import { UserIcon } from "../../../components/icons";
import { AuthApi } from "../../../util/api";
import { AttributeConfiguration, AttributeMetadata } from "../../../util/models";
import { AdvancedModeRequest, ToolTab, User } from "../../tools";
import { ReactComponent as DocsIcon } from "../../icons/docs.svg";

import "./Toolbar.css";
import { useSetRecoilState } from "recoil";
import {
  currentMetadataDetailsState,
  initCurrentMetadataDetailsState,
} from "../../../recoil/states/currentMetadataDetailsState";
import { LibraryAdd, LibraryBooks } from "@mui/icons-material";
import AttributeRatingComponent from "../AttributeRating/AttributeRating.component";
import AttributeSelectorComponent from "../AttributeSelector/AttributeSelector.component";

interface IToolbarProps {
  attributeConfigurations: AttributeConfiguration[];
  availableAttributes: AttributeMetadata[];
  isSimpleMode: boolean;
}

const Toolbar = (props: IToolbarProps) => {
  const setMetadataDetails = useSetRecoilState(currentMetadataDetailsState);
  const [tabIndex, setTabindex] = useState(-1);
  const { isSimpleMode } = props;


  function onSelect(selectTabIndex: number) {
    setMetadataDetails(initCurrentMetadataDetailsState());
    if (tabIndex === selectTabIndex) {
      selectTabIndex = -1;
    }
    setTabindex(selectTabIndex);
  }

  return (
    <Tabs
      className="tabs"
      selectedIndex={tabIndex}
      onSelect={(tabIndex) => {
        const tabsNams = ["Select", "Ratings", "Docs", "Profile"];
        if (tabsNams[tabIndex] === "Docs") {
          return onSelect(tabIndex);
        }
        return onSelect(tabIndex);
      }}
    >
      <TabList className="tablist">
        <Tab>
          <ToolTab name="Attributes">
            {/*<SelectIcon />*/}
            <LibraryAdd fontSize={"large"} />
          </ToolTab>
        </Tab>
        <Tab>
          <ToolTab name="Ratings">
            <LibraryBooks fontSize={"large"} />
          </ToolTab>
        </Tab>
        <div className="spacer" />
        {
          !props.isSimpleMode && (
            <Tab>
              <ToolTab name="Docs">
                <a
                  href="https://docs.udl.ai/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <DocsIcon />
                </a>
              </ToolTab>
            </Tab>
          )
        }

        <Tab>
          <ToolTab name={new AuthApi().getUsername()} className="profile">
            <UserIcon />
          </ToolTab>
        </Tab>
      </TabList>
      <div className="panel">
        <TabPanel>
          <AttributeSelectorComponent
            isSimpleMode={props.isSimpleMode}
            availableAttributes={props.availableAttributes}
          />
        </TabPanel>
        <TabPanel>
          {isSimpleMode ? (
            <AdvancedModeRequest />
          ) : (
            <AttributeRatingComponent
              availableAttributes={props.availableAttributes}
              attributeConfigurations={props.attributeConfigurations}
            />
          )}
        </TabPanel>
        {
          !props.isSimpleMode && (
            <TabPanel>{null}</TabPanel>
          )}
        <TabPanel>
          <User />
        </TabPanel>
      </div>
    </Tabs>
  );
};

export default Toolbar;
