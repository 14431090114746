import React, { useEffect } from "react";

type Handler = (event: MouseEvent | TouchEvent) => void;
function useOnClickOutside(
  ref: React.RefObject<HTMLElement>,
  handler: Handler | null,
) {
  useEffect(() => {
    const listener = (event: any) => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }

      if (handler) {
        handler(event);
      }
    };

    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);

    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [ref, handler]);
}

export default useOnClickOutside;
