import { atom} from "recoil";
import { AttributeConfiguration } from "../../util/models";

export interface AttributeTemplate {
  name: string,
  configurations: AttributeConfiguration[]
}

export const currentAttributeTemplateState = atom({
  key: "currentAttributeTemplateState", // unique ID (with respect to other atoms/selectors)
  default: [] as AttributeTemplate[], // default value (aka initial value)
});
