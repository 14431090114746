import { Alert } from ".";
import { IAlertButton } from './IAlertButton'

export default class AlertButton implements IAlertButton {
  public title: string;
  public event: (source: Alert) => void;

  constructor(title: string, event: (source: Alert) => void) {
    this.title = title;
    this.event = event;
  }
}
