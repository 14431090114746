import React from "react";
import {
  Handles,
  Slider as CompoundSlider,
  Tracks,
} from "react-compound-slider";
import Handle from "./Handle";
import "./Slider.css";
import Track from "./Track";

interface ISliderProps {
  max: number;
  min: number;
  onChangeEnded: (values: readonly number[]) => void;
  values: number[];
  customStepSize?: number
}

export default class Slider extends React.Component<ISliderProps> {
  public render() {
    const { customStepSize } = this.props;
    const stepSize = customStepSize ? customStepSize : this.determineStepSize(this.props.min, this.props.max);
    return (
      <div className="slider-container">
        <CompoundSlider
          className="slider-root"
          domain={[this.props.min, this.props.max]}
          step={stepSize}
          mode={2}
          values={this.props.values}
          onSlideEnd={(values: readonly number[]) => this.props.onChangeEnded(values)}
        >
          <div className="slider-rail" />
          <Handles>
            {({ handles, getHandleProps }) => (
              <div className="slider-handles">
                {handles.map((handle) => (
                  <Handle
                    key={handle.id}
                    handle={handle}
                    getHandleProps={getHandleProps}
                  />
                ))}
              </div>
            )}
          </Handles>
          <Tracks left={this.props.values.length < 2} right={false}>
            {({ tracks }) => (
              <div className="slider-tracks">
                {tracks.map(({ id, source, target }) => (
                  <Track key={id} source={source} target={target} />
                ))}
              </div>
            )}
          </Tracks>
        </CompoundSlider>
      </div>
    );
  }

  public determineStepSize(min: number, max: number): number {
    if (max === min) {
      return 0.0;
    }
    const spread = max - min;
    if (spread >= 2) {
      return 1;
    } else if (spread >= 0.5) {
      return 0.01;
    } else {
      return 0.001;
    }
  }
}
