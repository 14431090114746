import React from 'react';

import './ProfilerTable.css'

const STRINGS_FOR_HIGHLIGHTED_ROWS = ['Diff.', '(Quartier)']

const ProfilerTable = ({ data }: { data: [string][] }) => {
  if(data.length === 0) return null

  const highlightedRowIndexs = data.reduce((acc, currentValue, currentIndex) => {
    const isHighlighted = 
      STRINGS_FOR_HIGHLIGHTED_ROWS
        .find((stringToFind) => currentValue
          .join()
          .search(stringToFind) !== -1) !== undefined
    if(isHighlighted) {
      return [...acc, currentIndex]
    }
    return [...acc]
  }, [] as number[])

  return (
    <table className="profiler-table">
      <tbody>
        {
          data.map((tr, i) => (
            <tr key={`tr_${i}`} className={highlightedRowIndexs.indexOf(i) !== -1 ? 'shaded' : ''}>
              {
                i === 0 ?
                tr.map((value, k) => (
                  <th key={`th_${k}`} className={k === 0 ? 'lef' : ''}>{value}</th>
                )) : 
                tr.map((value, k) => (
                  <td key={`td_${k}`} className={k === 0 ? 'lef' : ''}>{value}</td>
                ))
              }
            </tr>
          ))
        }
      </tbody>
    </table>
  )
}

export default ProfilerTable;
