import React, { useEffect, useState } from "react";
import ReactMde, { getDefaultToolbarCommands } from "react-mde";
import * as Showdown from "showdown";
import { connect } from "react-redux";

import { actions, selectors } from "../../../store/store";
import apiProfiler from "../../../util/api/profilerApi";

import "react-mde/lib/styles/css/react-mde-all.css";

import "./KeyNotes.css";
import { usePrevious } from "../../../util/hooks/usePrevious";
import { IconButton, Tooltip } from "@mui/material";
import { Cancel, Save } from "@mui/icons-material";

const converter = new Showdown.Converter({
  tables: true,
  simplifiedAutoLink: true,
  strikethrough: true,
  tasklists: true,
});

interface KeyNotesProps {
  slug: string;
  isFetchingData: boolean;
}

const DEFAULT_TEXT = "No key notes.";

const KeyNotes = ({
                    slug,
                    note,
                    profilerObject,
                    updateKeyNote,
                    isFetchingData,
                  }: any) => {
  const [value, setValue] = useState(note?.key_note || DEFAULT_TEXT);
  const previousValue = usePrevious(value);
  const [saveValid, setSaveValid] = useState(false);

  const [selectedTab, setSelectedTab] = useState<"write" | "preview" | undefined>("preview");

  useEffect(() => {
    setValue(note?.key_note || DEFAULT_TEXT);
  }, [note]);

  useEffect(() => {
    if (previousValue && (previousValue !== value)) {
      if (value === DEFAULT_TEXT) {
        setSaveValid(false);
        return;
      }
      if(note?.key_note === value){
        setSaveValid(false);
        return;
      }
      setSaveValid(true);
    } else {
      setSaveValid(false);
    }
    // eslint-disable-next-line
  }, [value])

  const saveKeyNote = () => {
    setSelectedTab("preview");
    setSaveValid(false);
    if (slug === "demo") return;
    if (note === undefined) {
      return apiProfiler
        .createObjectKeyNote({
          objectId: profilerObject?.id as number,
          key_note: value,
          key_note_slug: slug,
        })
        .then(({ data }) => {
          updateKeyNote(data);
        })
        .catch(() => {
          // TBD
        });
    }

    return apiProfiler
      .updateObjectKeyNote({
        objectId: profilerObject?.id as number,
        key_note: value,
        key_note_slug: slug,
      })
      .then(({ data }) => {
        updateKeyNote(data);
      })
      .catch(() => {
        // TBD
      });
  };

  const rejectKeyNote = () => {
    setSelectedTab("preview");
    setValue(note?.key_note || "");
  };

  return (
    <div className="key-notes">
      <h5>
        Key Notes
        <span className="key-notes__actions">
           {saveValid && (
             <React.Fragment>

               <Tooltip title={<div>{"Save current notes"}</div>}>
                 <IconButton size="small" aria-label="save" onClick={() => {
                   saveKeyNote()
                 }}>
                   <Save style={{ color: "white" }} fontSize="small" />
                 </IconButton>
               </Tooltip>

               {note !== undefined && (
                 <Tooltip title={<div>{"Cancel current changes"}</div>}>
                   <IconButton size="small" aria-label="save" onClick={() => {
                     rejectKeyNote()
                   }}>
                     <Cancel style={{ color: "white" }} fontSize="small" />
                   </IconButton>
                 </Tooltip>
               )}
             </React.Fragment>
           )}
        </span>
      </h5>
      {isFetchingData ? (
        <div style={{ minHeight: "210px" }} />
      ) : (
        <ReactMde
          value={value}
          onChange={setValue}
          selectedTab={selectedTab}
          onTabChange={setSelectedTab}
          toolbarCommands={getDefaultToolbarCommands()}
          generateMarkdownPreview={(markdown) =>
            Promise.resolve(converter.makeHtml(markdown))
          }
        />
      )}
    </div>
  );
};

export default connect<any, {}, KeyNotesProps>(
  (state, ownProps: KeyNotesProps) => ({
    note: selectors.profiler.keyNoteBySlug(state)(ownProps.slug),
    profilerObject: selectors.profiler.selectedObject(state),
  }),
  {
    updateKeyNote: actions.profiler.updateKeyNote,
  },
)(KeyNotes);
