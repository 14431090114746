import { atom } from "recoil";
import { ObjectFileType, UserObjectType } from "../../store/modules/profiler/profiler.types";

export const initCurrentObjectState = (): UserObjectType => ({
  id: -1,
  user: -1,
  query_params: null,
  longitude: null,
  latitude: null,
  name: null,
  object_id: null,
  address: null,
  description: null,
  location: {
    latitude: null,
    longitude: null,
  },
});

export const currentObjectState = atom({
  key: "currentObjectState", // unique ID (with respect to other atoms/selectors)
  default: initCurrentObjectState(), // default value (aka initial value)
});


export const currentObjectFilesState = atom({
  key: "currentObjectFilesState", // unique ID (with respect to other atoms/selectors)
  default: [] as ObjectFileType[], // default value (aka initial value)
});
