import { Icon } from "leaflet";
import * as React from "react";
import { Marker, Polygon, Popup } from "react-leaflet";

import iconUrl from 'leaflet/dist/images/marker-shadow.png';
import iconShadowUrl from 'leaflet/dist/images/marker-icon.png';

interface MarkerInterface {
  content: string;
  position: number[];
}

interface MarkerProperties {
  popup: string;
}

interface GeoJSON {
  coordinates: number[];
  properties: MarkerProperties;
  type: string;
}

export interface MarkerDataInterface {
  geo_json: GeoJSON;
}

const UserMarker = ({ content, position }: MarkerInterface) => {
  const marker_icon = new Icon({
    iconAnchor: [12, 41],
    iconSize: [25, 41],
    iconUrl: iconUrl,
    shadowUrl: iconShadowUrl,
  });
  return (
    <Marker icon={marker_icon} position={position}>
      <Popup>{content}</Popup>
    </Marker>
  );
};

export const UserPolygon = ({ content, position }: MarkerInterface) => {
  return (
    <Polygon positions={position}>
      <Popup>{content}</Popup>
    </Polygon>
  );
};

export const UserMarkerList = ({
  markers,
}: {
  markers: MarkerDataInterface[];
}) => {
  const items: React.ReactElement[] = [];
  markers
    .filter((geo) => geo.geo_json.type === "Point")
    .forEach((geo) =>
      items.push(
        <UserMarker
          position={geo.geo_json.coordinates}
          content={geo.geo_json.properties.popup}
        />,
      ),
    );
  return <React.Fragment>{items}</React.Fragment>;
};

export const UserPolygonList = ({
  markers,
}: {
  markers: MarkerDataInterface[];
}) => {
  const items: React.ReactElement[] = [];
  markers
    .filter((geo) => geo.geo_json.type === "Polygon")
    .forEach((geo) =>
      items.push(
        <UserPolygon
          position={geo.geo_json.coordinates}
          content={geo.geo_json.properties.popup}
        />,
      ),
    );
  return <React.Fragment>{items}</React.Fragment>;
};
