import * as React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import {
  ROUTE_EXPORT_PAGE,
  ROUTE_LOGIN_PAGE,
  ROUTE_MAIN_PAGE,
  ROUTE_MANAGER,
  ROUTE_PROFILER_PAGE,
} from "./App.constants";
import { PrivateRoute } from "./components/auth";
import {
  ExportPageAsync,
  IframePage,
  LoginPage,
  MainPageAsync,
  NotFoundPage,
  ProfilerPageAsync,
  ProxyBuildingPageAsync,
  ProxyPropertyPageAsync,
} from "./pages";
import { withTracker } from "./components/tools/withTracker/withTracker";
import { createTheme, StyledEngineProvider, Theme, ThemeProvider } from "@mui/material/styles";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import ScoutPageAsync from "./pages/ScoutPage/ScoutPageAsync";
import SnackbarComponent from "./components/Snackbar/Snackbar.component";
import ProxyPortfolioPageAsync from "./pages/ManagerPages/V2/ProxyPortfolioPage/ProxyPortfolioPageAsync";
import ProxyUnitPageAsync from "./pages/ManagerPages/V2/ProxyUnitPage/ProxyUnitPageAsync";

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

// TODO: Replace TSLint with ESLint
const theme = createTheme({
  components: {
    MuiTypography: {
      styleOverrides: {
        h6: {
          fontSize: "1.0rem",
        },
      }
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: "8px 24px 16px 24px",
        },
      }
    },
    MuiBreadcrumbs:{
      styleOverrides: {
        root: {
          fontSize: '1.2rem'
        },
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: "0.9rem",
          textAlign: "center",
        }
      }
    },
  },
  palette: {
    primary: {
      // Purple and green play nicely together.
      main: "#d3e1ee",
    },
    secondary: {
      // This is green.A700 as hex.
      main: "#4f5d66",
    },
  },
});

export default class App extends React.Component<{}, {}> {
  public render() {
    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <I18nextProvider i18n={i18n}>
            <div className="App">
              <BrowserRouter>
                <Switch>
                  <Route path={ROUTE_LOGIN_PAGE} exact component={withTracker(LoginPage)} />
                  <Route
                    path="/7EDiQkOM3liCxtlcwBcj"
                    exact
                    component={withTracker(IframePage)}
                  />
                  <PrivateRoute
                    path={ROUTE_MAIN_PAGE}
                    exact
                    component={withTracker(MainPageAsync)}
                  />
                  <PrivateRoute
                    path="/scout"
                    exact
                    component={withTracker(ScoutPageAsync)}
                  />
                  <PrivateRoute
                    path={ROUTE_EXPORT_PAGE}
                    exact
                    component={withTracker(ExportPageAsync)}
                  />
                  <PrivateRoute
                    path={ROUTE_PROFILER_PAGE}
                    exact
                    component={withTracker(ProfilerPageAsync)}
                  />


                  <PrivateRoute
                    path={`${ROUTE_MANAGER}portfolio/:portfolioId/property/:propertyId/building/:buildingId/unit/:unitId`}
                    component={withTracker(ProxyUnitPageAsync)}
                  />
                  <PrivateRoute
                    path={`${ROUTE_MANAGER}portfolio/:portfolioId/property/:propertyId/building/:buildingId/unit`}
                    component={withTracker(ProxyUnitPageAsync)}
                  />
                  <PrivateRoute
                    path={`${ROUTE_MANAGER}portfolio/:portfolioId/property/:propertyId/building/:buildingId`}
                    component={withTracker(ProxyBuildingPageAsync)}
                  />
                  <PrivateRoute
                    path={`${ROUTE_MANAGER}portfolio/:portfolioId/property/:propertyId/building`}
                    component={withTracker(ProxyBuildingPageAsync)}
                  />
                  <PrivateRoute
                    path={`${ROUTE_MANAGER}portfolio/:portfolioId/property/:propertyId/`}
                    component={withTracker(ProxyPropertyPageAsync)}
                  />
                  <PrivateRoute
                    path={`${ROUTE_MANAGER}portfolio/:portfolioId/property`}
                    component={withTracker(ProxyPropertyPageAsync)}
                  />

                  <PrivateRoute
                    path={`${ROUTE_MANAGER}portfolio/:portfolioId`}
                    component={withTracker(ProxyPortfolioPageAsync)}
                  />

                  <PrivateRoute
                    path={`${ROUTE_MANAGER}portfolio`}
                    exact
                    component={withTracker(ProxyPortfolioPageAsync)}
                  />

                  <PrivateRoute component={NotFoundPage} />
                </Switch>
              </BrowserRouter>
              <SnackbarComponent/>
            </div>
          </I18nextProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    );
  }
}
