import React, { useEffect } from "react";
import ReactGA, { FieldsObject } from "react-ga";
import { RouteComponentProps } from "react-router-dom";

import env from "../../../env";

if (env.IS_PRODUCTION) {
  ReactGA.initialize(env.GA_ID, { debug: false });
  ReactGA.ga("create", env.GA_ID, "auto", { allowLinker: true });
  ReactGA.ga("require", "linker");
  ReactGA.ga("linker:autoLink", env.GA_DOMAINS);
}

export const withTracker = <P extends RouteComponentProps>(
  WrappedComponent: React.ComponentType<P> | React.ComponentClass,
  options: FieldsObject = {},
) => {
  const trackPage = (page: string) => {
    if (env.IS_PRODUCTION) {
      ReactGA.set({ page, ...options });
      ReactGA.pageview(page);
    }
  };

  return (props: P) => {
    useEffect(() => {
      trackPage(props.location.pathname);
    }, [props.location.pathname]);

    return <WrappedComponent {...props} />;
  };
};
