import React, { useEffect, useState } from "react";
import { AttributeConfiguration, AttributeMetadata } from "../../../util/models";
import { E, Events } from "../../../util/events";
import Mixpanel, { MIXPANEL_EVENTNAME_EDITED_LAYER, MIXPANEL_PAGENAME_SCOUT } from "../../../util/Mixpanel";
import { Input, Slider } from "../../elementar";
import MetadataInfoButtonComponent from "../Selector/MetadataInfoButton/MetadataInfoButton.component";
import { useTranslation } from "react-i18next";
import { IconButton, Paper, Tooltip } from "@mui/material";
import {
  DeleteOutline,
  FunctionsOutlined,
  Save,
  SearchOutlined,
  TuneOutlined,
} from "@mui/icons-material";
import { BasePanel, PanelItem } from "../index";
import Button from "@mui/material/Button";
import MetadataVisualization from "../Selector/MetadataVisualization";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import MapSvg from "../../icons/illustrations/map.svg";
import AddAttributesComponent from "../../dialogs/AddAttributes/AddAttributes.component";
import SaveAttributeConfigurationComponent
  from "../../dialogs/SaveAttributeConfiguration/SaveAttributeConfiguration.component";

import "./AttributeSelector.component.css";
import { useRecoilState } from "recoil";
import { currentAttributeConfigurationsState } from "../../../recoil/states/currentAttributeConfigurationsState";
import {
  currentLayerConfigurationState,
  LAYER_CONFIGURATION_SCALE_FOCAL,
} from "../../../recoil/states/currentLayerConfigurationState";

interface ISelectorProps {
  isSimpleMode: boolean;
  availableAttributes: AttributeMetadata[];
  defaultSelectedAttributes?: AttributeMetadata[];
}

const AttributeSelectorComponent: React.FC<ISelectorProps> = ({
                                                                isSimpleMode,
                                                                availableAttributes,
                                                                defaultSelectedAttributes,
                                                              }) => {

  const [t] = useTranslation();
  const [addAttributesVisible, setAddAttributesVisible] = useState(false);
  const [saveAttributeConfigurationVisible, setSaveAttributeConfigurationVisible] = useState(false);

  const [attributeConfigurations, setAttributeConfigurations] = useRecoilState(currentAttributeConfigurationsState);
  const [currentLayerConfiguration, setCurrentLayerConfiguration] = useRecoilState(currentLayerConfigurationState);

  useEffect(() => {
    if (defaultSelectedAttributes && attributeConfigurations.length === 0) {
      const newAttributes: AttributeConfiguration[] = [];
      defaultSelectedAttributes.forEach(attr => {
        const newAttribute = new AttributeConfiguration();
        newAttribute.attributeKey = attr.pk;
        newAttribute.setRangeMin(attr.fields.rangeMin);
        newAttribute.setRangeMax(attr.fields.rangeMax);
        newAttributes.push(newAttribute);
      });

      Events.fire(E.ATTRIBUTE_CONFIGURATION_CHANGED, [
        [...attributeConfigurations, ...newAttributes],
      ]);
    }
    // eslint-disable-next-line
  }, []);

  function trackAttributeChange(configuration: AttributeConfiguration) {
    const matchingAttribute = getMetadataForConfiguration(configuration);
    if (matchingAttribute === undefined) {
      return;
    }
    Mixpanel.getInstance().trackEvent(MIXPANEL_EVENTNAME_EDITED_LAYER, {
      Component: MIXPANEL_PAGENAME_SCOUT,
      "Layer Name": matchingAttribute.fields.attributeName,
    });
  }

  function getMetadataForConfiguration(
    configuration: AttributeConfiguration,
  ): AttributeMetadata | undefined {
    return availableAttributes.find((attribute) => attribute.pk === configuration.attributeKey);
  }

  function transformationChanged(event: React.ChangeEvent<HTMLInputElement>, configuration: AttributeConfiguration) {
    if (configuration) {
      trackAttributeChange(configuration);
      configuration.setTransformation(event.target.value);
    }
  }

  function weightChanged(event: React.ChangeEvent<HTMLInputElement>, configuration: AttributeConfiguration) {
    if (configuration) {
      trackAttributeChange(configuration);
      configuration.setWeight(event.target.value);
    }
  }

  function normalizationChanged(event: React.ChangeEvent<HTMLInputElement>, configuration: AttributeConfiguration) {
    if (configuration) {
      trackAttributeChange(configuration);
      configuration.setNormalization(event.target.checked);

      updateAttributeConfiguration(configuration);
    }
  }

  function updateAttributeConfiguration(clonedAttributeConfig: AttributeConfiguration) {
    const index = attributeConfigurations.findIndex(config => config.attributeKey === clonedAttributeConfig.attributeKey);
    const copiedConfigs = [...attributeConfigurations];

    copiedConfigs[index] = clonedAttributeConfig;
    setAttributeConfigurations(copiedConfigs);

    //NOTE: trigger load of heatmap
    setCurrentLayerConfiguration({
      attributeMetadataKey: currentLayerConfiguration.attributeMetadataKey,
      scale: currentLayerConfiguration.scale,
      rating: currentLayerConfiguration.rating,
    });
  }

  function renderSimpleMode(
    configuration: AttributeConfiguration,
    index: number,
  ) {
    const metaData = getMetadataForConfiguration(configuration);
    let copiedAttribute = configuration.getCopy();

    if (!metaData) {
      return "";
    }
    return (
      <div className="editor-entry-mode-simple">
        <div className="editor-entry-transform">
          <p>Select</p>
          <div className="editor-entry-transform-slider">
            <Slider
              max={metaData.fields.rangeMax}
              min={metaData.fields.rangeMin}
              values={[
                copiedAttribute.getRangeMin(),
                copiedAttribute.getRangeMax(),
              ]}
              onChangeEnded={(values: readonly number[]) => {
                const minValue = values[0];
                const maxValue = values[1];
                if (
                  minValue < metaData.fields.rangeMin ||
                  maxValue > metaData.fields.rangeMax
                ) {
                  return;
                }
                copiedAttribute.setRangeMin(minValue);
                copiedAttribute.setRangeMax(maxValue);
                trackAttributeChange(copiedAttribute);

                updateAttributeConfiguration(copiedAttribute);
              }}
            />
            <div className="editor-entry-transform-slider-textboxes">
              <Input
                className="editor-entry-transform-slider-textboxes-input"
                inputClassName="editor-entry-transform-slider-textboxes-input-inner"
                defaultValue={metaData.fields.rangeMin.toString()}
                value={copiedAttribute.getRangeMin().toString()}
                maxValue={copiedAttribute.getRangeMax()}
                minValue={metaData.fields.rangeMin}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  const valueNumber = Number(event.target.value);
                  if (valueNumber < metaData.fields.rangeMin) {
                    return;
                  }
                  copiedAttribute.setRangeMin(valueNumber);
                  trackAttributeChange(copiedAttribute);

                  updateAttributeConfiguration(copiedAttribute);

                }}
                type="decimal"
              />
              <Input
                className="editor-entry-transform-slider-textboxes-input"
                inputClassName="editor-entry-transform-slider-textboxes-input-inner"
                defaultValue={metaData.fields.rangeMax.toString()}
                value={copiedAttribute.getRangeMax().toString()}
                maxValue={metaData.fields.rangeMax}
                minValue={copiedAttribute.getRangeMin()}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  const valueNumber = Number(event.target.value);
                  if (valueNumber > metaData.fields.rangeMax) {
                    return;
                  }
                  copiedAttribute.setRangeMax(valueNumber);
                  trackAttributeChange(copiedAttribute);

                  updateAttributeConfiguration(copiedAttribute);
                }}
                type="decimal"
              />
            </div>
          </div>
        </div>
        <div className="editor-entry-transform">
          <p>Relevance</p>
          <Slider
            max={5}
            min={-5}
            customStepSize={0.5}
            values={[Number(copiedAttribute.getWeight())]}
            onChangeEnded={(values: readonly number[]) => {
              copiedAttribute.setWeight(values[0].toString());
              trackAttributeChange(copiedAttribute);

              updateAttributeConfiguration(copiedAttribute);
            }}
          />
        </div>
        <div>
          <Input
            dataSetKey={index.toString()}
            inputClassName="editor-entry-input"
            value={copiedAttribute.getNormalization()?'TRUE': 'FALSE'}
            label="Normalization"
            type="checkbox"
            labelWidth={84}
            onChange={(event) => normalizationChanged(event, copiedAttribute)}
          />
        </div>
      </div>
    );
  }

  function renderExpertMode(
    configuration: AttributeConfiguration,
    index: number,
  ) {
    const labelWidth = 84;

    let copiedAttribute = configuration.getCopy();

    return (
      <div className="editor-entry-mode-expert">
        <Input
          className="editor-entry-transform"
          dataSetKey={index.toString()}
          value={copiedAttribute.getTransformation()}
          inputClassName="editor-entry-input"
          label="Transform"
          labelWidth={labelWidth}
          onChange={(event) => transformationChanged(event, copiedAttribute)}
          onValueChangeEnded={() => updateAttributeConfiguration(copiedAttribute)}
          placeholder="log(x), x**2, ..."
        />
        <Input
          dataSetKey={index.toString()}
          value={copiedAttribute.getWeight()}
          inputClassName="editor-entry-input"
          label="Weight"
          labelWidth={labelWidth}
          onChange={(event) => weightChanged(event, copiedAttribute)}
          onValueChangeEnded={() => updateAttributeConfiguration(copiedAttribute)}
          placeholder="7, ..."
        />
        <Input
          dataSetKey={index.toString()}
          value={copiedAttribute.getNormalization()?'TRUE': 'FALSE'}
          inputClassName="editor-entry-input"
          label="Normalization"
          type="checkbox"
          labelWidth={labelWidth}
          onChange={(event) => normalizationChanged(event, copiedAttribute)}
        />
      </div>
    );
  }

  // function aggregateAllKeywords(attributeMetadatas: AttributeMetadata[]): string {
  //   return attributeMetadatas
  //     .map((metadata) => metadata.fields.keywords)
  //     .map((keywords) => splitKeywordStringIntoKeywords(keywords))
  //     .reduce((summedUpKeywords, currentKeywords) => {
  //       summedUpKeywords.push(...currentKeywords);
  //       return Array.from(new Set(summedUpKeywords));
  //     })
  //     .sort()
  //     .reduce((summedUpString, keyword) => {
  //       if (summedUpString.length === 0) {
  //         return keyword;
  //       }
  //       return `${summedUpString}, ${keyword}`;
  //     });
  // }

  // function splitKeywordStringIntoKeywords(compositeKeywords: string): string[] {
  //   return compositeKeywords.split(";").map((k: string) => k.trim());
  // }

  function removeAttributeClicked(index: number) {
    const modifiedAttributes = attributeConfigurations.slice();
    modifiedAttributes.splice(index, 1);

    setAttributeConfigurations(modifiedAttributes);

    //NOTE: trigger load of heatmap
    const currentLayer = {
      attributeMetadataKey: currentLayerConfiguration.attributeMetadataKey,
      scale: currentLayerConfiguration.scale,
      rating: currentLayerConfiguration.rating,
    };

    if (modifiedAttributes.length === 1) {
      currentLayer.attributeMetadataKey = modifiedAttributes[0].attributeKey!;
      currentLayer.rating = false;
      currentLayer.scale = LAYER_CONFIGURATION_SCALE_FOCAL;
    }
    setCurrentLayerConfiguration(currentLayer);

  }

  // function infoButtonClicked() {
  //   const aggregatedKeywordText = aggregateAllKeywords(
  //     availableAttributes,
  //   );
  //   infoAlert.text =
  //     aggregatedKeywordText.length > 0
  //       ? `The search field allows you to search by attributenames, descriptions or topics. Following topics are supported by the search: ${aggregatedKeywordText}`
  //       : "There are currently no searchable keywords defined.";
  //
  //   infoAlert.show();
  // }

  function buildPanelItemButtons(index: number): React.ReactNode[] {
    const infoButton = (
      <MetadataInfoButtonComponent key={"metaInfoButton"} configurations={attributeConfigurations}
                                   currentIndex={index} />
    );

    const deleteButton = (
      <Tooltip title={<div>{t("global.remove")}</div>}>
        <IconButton
          style={{ padding: "6px" }}
          onClick={() => removeAttributeClicked(index)}
          size="large">
          <DeleteOutline />
        </IconButton>
      </Tooltip>
    );

    const expertModeButton = (

      <Tooltip title={<div>{t("ratings.simpleMode")}</div>}>
        <IconButton
          style={{ padding: "6px" }}
          onClick={() => {
            const attribute = attributeConfigurations[index].getCopy();
            if (attribute) {
              attribute.setIsExpert(false);
            }
            updateAttributeConfiguration(attribute);
          }}
          size="large">
          <TuneOutlined />
        </IconButton>
      </Tooltip>

    );

    const simpleModeButton = (

      <Tooltip title={<div>{t("ratings.expertMode")}</div>}>
        <IconButton
          style={{ padding: "6px" }}
          onClick={() => {
            const attribute = attributeConfigurations[index].getCopy();
            if (attribute) {
              attribute.setIsExpert(true);
            }

            updateAttributeConfiguration(attribute);
          }}
          size="large">
          <FunctionsOutlined />
        </IconButton>
      </Tooltip>

    );

    return [isSimpleMode ? null : attributeConfigurations[index].getIsExpert() ? expertModeButton : simpleModeButton, deleteButton, infoButton];
  }

  return (<BasePanel>
    <Button
      color={"secondary"}
      style={{ marginBottom: 40 }}
      size={"large"}
      variant={"outlined"}
      startIcon={<SearchOutlined />}
      onClick={() => setAddAttributesVisible(true)}
    >
      {t("attributeHandling.findAttributes")}
    </Button>

    {attributeConfigurations.map((attributeConfig, index) => {
      const attributeMetadata = availableAttributes.find(
        (value) => attributeConfig.attributeKey === value.pk,
      );
      if (attributeMetadata) {
        return (
          <PanelItem
            className="select-container"
            key={index}
            title={attributeMetadata.fields.attributeName}
            buttons={buildPanelItemButtons(index)}>

            {
              isSimpleMode
                ? (
                  <MetadataVisualization attributeMetadata={attributeMetadata} />
                )
                : attributeConfig.getIsExpert()
                  ? renderExpertMode(attributeConfig, index)
                  : renderSimpleMode(attributeConfig, index)
            }

          </PanelItem>
        );
      } else {
        return "";
      }
    })}

    {(!isSimpleMode && attributeConfigurations.length === 0) && (
      <Paper elevation={3} style={{ marginLeft: "8px", padding: "16px" }}>
        <Box marginTop={3} display="flex" alignItems="center" flexDirection={"column"}>
          <Typography style={{ marginBottom: "24px" }}
                      variant={"body1"}>{t("attributeHandling.emptyAttributeSelection")}</Typography>
          <img width={200} src={MapSvg} alt={"Map"} />
        </Box>
      </Paper>
    )}

    {(!isSimpleMode && attributeConfigurations.length > 0) && (
      <Button
        style={{ marginTop: 32 }}
        size={"large"}
        variant={"contained"}
        color={"primary"}
        startIcon={<Save />}
        onClick={() => setSaveAttributeConfigurationVisible(true)}
      >
        Save configuration
      </Button>
    )}


    <AddAttributesComponent isVisible={addAttributesVisible}
                            currentAttributeConfigurations={attributeConfigurations}
                            closeRequest={() => setAddAttributesVisible(false)} />

    <SaveAttributeConfigurationComponent isVisible={saveAttributeConfigurationVisible}
                                         currentAttributeConfigurations={attributeConfigurations}
                                         availableAttributes={availableAttributes}
                                         closeRequest={() => setSaveAttributeConfigurationVisible(false)} />
  </BasePanel>);
};

export default AttributeSelectorComponent;
