import BaseApi from "./BaseApi";
import axios from "axios";
import { PositionInformation } from "../../store/modules/user/user.types";

export const SEARCH_ENDPOINT_URL = "https://nominatim.openstreetmap.org/";
export default class SearchApi extends BaseApi {

  public async searchForAddressAsync(address: string, positionInformation: PositionInformation | null): Promise<any[]> {
    let path = `search?q=${address}&format=json`;
    if(positionInformation?.country_code){
      path += `&countrycodes=${positionInformation.country_code}`;
    }
    const searchResponse = await this.getWithEndpoint(SEARCH_ENDPOINT_URL, path);
    if (searchResponse.status === 200) {
      return searchResponse.json();
    }
    return [];
  }

  static async lookupCoordinatesAsync(lat: string | number | null, lon: string | number | null) {
    return axios({
      method: "GET",
      url: `${(SEARCH_ENDPOINT_URL)}reverse`,
      params: {
        lat,
        lon: lon,
        format: "jsonv2",
      },
    });
  }
}
