import { atom } from "recoil";
import { LatLngLiteral } from "leaflet";


const initCurrentInitialCoordinatesState = (): LatLngLiteral => ({
  lat: 47.373,
  lng:  8.5262,
})

export const currentInitialCoordinatesState = atom({
  key: 'currentInitialCoordinatesState', // unique ID (with respect to other atoms/selectors)
  default: initCurrentInitialCoordinatesState(), // default value (aka initial value)
});
