import React from "react";

export default class Trash extends React.Component {
  public render() {
    return (
      <svg
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 434.000000 512.170070"
        preserveAspectRatio="xMidYMid meet"
      >
        <g
          transform="translate(-39.000000,512.029580) scale(0.100000,-0.100000)"
          stroke="none"
        >
          <path
            d="M1970 5105 c-162 -40 -276 -131 -341 -270 -31 -68 -59 -191 -59 -259
     l0 -45 -417 -3 -418 -3 -45 -27 c-47 -27 -83 -78 -95 -135 l-7 -33 -99 0 -99
     0 0 -195 0 -195 2170 0 2170 0 0 195 0 195 -99 0 -99 0 -7 33 c-12 57 -48 108
     -95 135 l-45 27 -417 3 -418 3 0 45 c0 68 -28 191 -59 259 -66 141 -180 231
     -346 270 -85 21 -1093 20 -1175 0z m1117 -408 c35 -19 53 -63 53 -129 l0 -38
     -580 0 -580 0 0 38 c0 57 19 110 43 124 46 24 94 26 557 25 432 -2 478 -4 507
     -20z"
          />
          <path
            d="M790 2132 c0 -1575 0 -1610 20 -1687 55 -215 231 -384 446 -430 103
     -22 2505 -22 2608 0 215 46 391 215 446 430 20 77 20 112 20 1687 l0 1608
     -1770 0 -1770 0 0 -1608z m1180 -162 l0 -1180 -200 0 -200 0 0 1180 0 1180
     200 0 200 0 0 -1180z m790 0 l0 -1180 -200 0 -200 0 0 1180 0 1180 200 0 200
     0 0 -1180z m790 0 l0 -1180 -200 0 -200 0 0 1180 0 1180 200 0 200 0 0 -1180z"
          />
        </g>
      </svg>
    );
  }
}
