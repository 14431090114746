import React from "react";
import { Tooltip } from "@mui/material";

interface ISwitchOption {
  name: string;
  value: string;
}

export default class Switch extends React.Component<ISwitchOption> {
  private getTooltipName(): string{
    switch (this.props.value) {
      case 'global':
        return 'Set legend of layer to nationwide reference';
      case 'local':
        return 'Set the legend of the layer to current view';
      default:
        return 'View the currently active editing';
    }
  }
  public render() {
    return (
      <Tooltip title={<div>{this.getTooltipName()}</div>}>
        <div className="switchoption switchoption-text">{this.props.name}</div>
      </Tooltip>
    );
  }
}
