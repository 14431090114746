import { createReducer } from '@reduxjs/toolkit';
import { filter } from 'ramda';

import { UserObjectType, ObjectFileType, KeyNoteSlugType, KeyNoteType } from './profiler.types';
import {
  SET_OBJECTS,
  CLEAR_OBJECTS,
  SET_OBJECT,
  CLEAR_OBJECT,
  SET_FILES,
  CLEAR_FILES,
  SET_ISFETCHING,
  SET_KEY_NOTES,
  CLEAR_KEY_NOTES,
  SET_KEY_NOTES_SLUG,
  CLEAR_KEY_NOTES_SLUG,
  UPDATE_KEY_NOTE
} from './profiler.actions';

interface InitialState {
  selectedObject: {} | UserObjectType;
  objects: UserObjectType[];
  files: ObjectFileType[];
  isFetching: boolean;
  keyNotes: {
    slugs: KeyNoteSlugType[],
    notes: KeyNoteType[]
  }
}

const initialState: InitialState = {
  selectedObject: {},
  objects: [],
  files: [],
  isFetching: false,
  keyNotes: {
    slugs: [],
    notes: []
  }
};

export default createReducer(initialState, {
  [SET_OBJECTS]: (state, action) => {
    state.objects = action.payload;
  },
  [CLEAR_OBJECTS]: state => {
    state.objects = initialState.objects;
  },
  [SET_OBJECT]: (state, action) => {
    state.selectedObject = action.payload;
  },
  [CLEAR_OBJECT]: state => {
    state.selectedObject = initialState.selectedObject;
  },
  [SET_FILES]: (state, action) => {
    state.files = action.payload;
  },
  [CLEAR_FILES]: state => {
    state.files = initialState.files;
  },
  [SET_ISFETCHING]: (state, action) => {
    state.isFetching = action.payload;
  },
  [SET_KEY_NOTES_SLUG]: (state, action) => {
    state.keyNotes.slugs = action.payload;
  },
  [CLEAR_KEY_NOTES_SLUG]: state => {
    state.keyNotes.slugs = initialState.keyNotes.slugs;
  },
  [SET_KEY_NOTES]: (state, action) => {
    state.keyNotes.notes = action.payload;
  },
  [CLEAR_KEY_NOTES]: state => {
    state.keyNotes.notes = initialState.keyNotes.notes;
  },
  [UPDATE_KEY_NOTE]: (state, action) => {
    state.keyNotes.notes = [
      ...filter(
        note => note.key_note_slug !== action.payload.key_note_slug,
        state.keyNotes.notes
      ),
      action.payload
    ]
  },
});