import { createSelector } from "@reduxjs/toolkit";

import { ManagerState } from "./manager.reducer";

type StateSlice = {
  manager: ManagerState;
};

const userPortfolios = (state: StateSlice) => state.manager.portfolios;
const selectedPortfolio = (state: StateSlice) => state.manager.portfolio;

const userProperties = (state: StateSlice) => state.manager.properties;
const selectedProperty = (state: StateSlice) => state.manager.property;
const propertiesLocationColumns = (state: StateSlice) =>
  state.manager.propertiesLocationColumns;

const userBuildings = (state: StateSlice) => state.manager.buildings;
const selectedBuilding = (state: StateSlice) => state.manager.building;
const selectedNavigationBuilding = (state: StateSlice) => state.manager.navigationBuilding;
const buildingsLocationColumns = (state: StateSlice) =>
  state.manager.buildingsLocationColumns;

const userUnits = (state: StateSlice) => state.manager.units;
const selectedUnit = (state: StateSlice) => state.manager.unit;
const unitsLocationColumns = (state: StateSlice) =>
  state.manager.unitsLocationColumns;
const selectLogs = (state: StateSlice) => state.manager.logs;
const selectAssets = (state: StateSlice) => state.manager.assets;
const selectMarkers = (state: StateSlice) => state.manager.markers;

// eslint-disable-next-line
export default {
  portfolios: createSelector(userPortfolios, (portfolios) => portfolios),
  portfolio: createSelector(selectedPortfolio, (portfolio) => portfolio),
  properties: createSelector(userProperties, (properties) => properties),
  property: createSelector(selectedProperty, (property) => property),
  buildings: createSelector(userBuildings, (buildings) => buildings),
  building: createSelector(selectedBuilding, (building) => building),
  navigationBuilding: createSelector(selectedNavigationBuilding, (building) => building),
  buildingsLocationColumns: createSelector(
    buildingsLocationColumns,
    (columns) => columns,
  ),
  units: createSelector(userUnits, (units) => units),
  unit: createSelector(selectedUnit, (unit) => unit),
  unitsLocationColumns: createSelector(
    unitsLocationColumns,
    (columns) => columns,
  ),
  propertiesLocationColumns: createSelector(
    propertiesLocationColumns,
    (columns) => columns,
  ),
  logs: createSelector(selectLogs, (logs) => logs),
  assets: createSelector(selectAssets, (assets) => assets),
  markers: createSelector(selectMarkers, (markers) => markers),
};
