import clsx from "clsx";
import { debounce, Grid, lighten, TextField, Theme, Toolbar } from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Search } from "@mui/icons-material";
import Box from "@mui/material/Box";
import { forwardRef, useImperativeHandle, useRef } from "react";

const useToolbarStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    highlight:
      theme.palette.mode === "light"
        ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
        : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
    title: {
      flex: "1 1 100%",
    },
  }),
);

interface EnhancedTableToolbarProps {
  onFilterValueChanged: (event: string) => void;
}

const EnhancedTableToolbar = forwardRef((props: EnhancedTableToolbarProps, ref) => {
  const classes = useToolbarStyles();
  const { onFilterValueChanged } = props;
  const textFieldRef = useRef(null);

  const debounceEntry = debounce((value: string) => onFilterValueChanged(value), 400);

  useImperativeHandle(ref, () => ({
    clearValue() {
      if (textFieldRef && textFieldRef.current != null) {
        // @ts-ignore
        textFieldRef.current.focus();
        // @ts-ignore
        textFieldRef.current.value = '';
      }
    },
  }));

  return (
    <Toolbar className={clsx(classes.root)}>
      <Box marginBottom={1}>
        <Grid container spacing={1} alignItems="flex-end">
          <Grid item>
            <Search />
          </Grid>
          <Grid item>
            <TextField variant={'standard'} fullWidth={true} inputRef={textFieldRef} id="input-with-icon-grid" label="Filter..." onChange={(event: any) => {
              let value = event.target.value;
              debounceEntry(value);
            }} />
          </Grid>
        </Grid>
      </Box>
    </Toolbar>
  );
});

export default EnhancedTableToolbar;
