import React from "react";

interface ISwitchOption {
  imageBinary: string;
  value: string;
}

export default class SwitchImageOption extends React.Component<ISwitchOption> {
  public render() {
    return (
      <div className="switchoption switchoption-image">
        <img src={this.props.imageBinary} alt={this.props.value} />
      </div>
    );
  }
}
