import axios from "axios";

import env from "../../env";
import i18n from "i18next";

const APIInstance = axios.create({
  baseURL: `${env.API_URL}/api`,
});

APIInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("authorizationToken");
    if (config.headers != null) {
      config.headers["Authorization"] = `Bearer ${token}`;
      config.headers["accept-language"] = i18n.language;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

APIInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  },
);

export default APIInstance;
