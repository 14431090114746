import classNames from "classnames";

export const useSticky = (hooks: any) => {
  hooks.getHeaderProps.push((props: any, { column }: any) => {
    return [
      props,
      {
        className: classNames({
          grupeTitle: props.colSpan > 1,
          highlighted: column.placeholderOf
            ? column.placeholderOf.isHighlighted
            : column.isHighlighted,
          sticky: column.placeholderOf
            ? column.placeholderOf.isSticky
            : column.isSticky,
        }),
        style: {
          left: column.placeholderOf
            ? column.placeholderOf.isSticky
              ? `${column.placeholderOf.totalLeft}px`
              : undefined
            : column.isSticky
            ? `${column.totalLeft}px`
            : undefined,
        },
      },
    ];
  });
  hooks.getCellProps.push((props: any, { cell }: any) => {
    return [
      props,
      {
        className: classNames("td", `${cell.column.id}_column`, {
          highlighted: cell.column.isHighlighted,
          sticky: cell.column.isSticky,
        }),
        style: {
          left: cell.column.isSticky ? `${cell.column.totalLeft}px` : undefined,
          width: cell.column.hasFixedWIdth
            ? `${cell.column.width}px`
            : undefined,
          minWidth: cell.column.hasFixedWIdth
            ? `${cell.column.width}px`
            : undefined,
        },
      },
    ];
  });
};
