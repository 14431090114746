import React from "react";
import "./Loading.css";
import { CircularProgress } from "@mui/material";
import Box from "@mui/material/Box";

interface ILoadingProps {
  hidden?: boolean;
}

export default class Loading extends React.Component<ILoadingProps> {
  public render() {
    return (
      <Box hidden={this.props.hidden}>
        <CircularProgress size="32px" color="secondary" hidden={this.props.hidden} />
      </Box>
    );
  }
}
