import React from "react";

export default class Search extends React.Component {
  public render() {
    return (
      <svg
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 504.418359 504.363411"
        preserveAspectRatio="xMidYMid meet"
      >
        <g
          transform="translate(0,507.852594) scale(0.100000,-0.100000)"
          fill="#000000"
          stroke="none"
        >
          <path
            d="M1695 5060 c-425 -64 -792 -249 -1089 -546 -754 -753 -753 -1975 0
       -2728 306 -305 695 -494 1132 -550 471 -60 999 77 1367 355 28 21 56 39 61 39
       5 0 46 -37 92 -83 l82 -82 -21 -55 c-31 -82 -24 -184 18 -270 29 -58 87 -120
       530 -561 568 -566 540 -544 698 -544 134 0 176 23 337 183 160 161 183 203
       183 337 0 160 14 142 -562 716 -556 554 -538 539 -683 547 -59 3 -87 -1 -130
       -17 l-55 -21 -82 82 c-46 46 -83 87 -83 92 0 5 18 33 39 61 278 368 415 896
       355 1367 -113 888 -792 1569 -1677 1683 -155 20 -362 18 -512 -5z m495 -351
       c623 -85 1152 -558 1305 -1169 61 -240 62 -525 3 -765 -151 -617 -664 -1086
       -1293 -1181 -191 -29 -439 -17 -625 31 -612 155 -1080 677 -1170 1305 -17 116
       -15 355 4 468 34 206 102 388 214 577 115 192 325 403 517 517 328 196 673
       267 1045 217z"
          />
          <path
            d="M928 3564 c-39 -75 -78 -190 -99 -289 -18 -86 -15 -303 6 -400 109
       -511 541 -865 1055 -865 202 0 415 61 575 165 l40 26 -70 -5 c-89 -6 -256 8
       -360 29 -413 86 -771 368 -969 763 -77 153 -132 383 -139 577 l-2 70 -37 -71z"
          />
        </g>
      </svg>
    );
  }
}
