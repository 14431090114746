import React, { useEffect } from 'react';

const ProfilerGraph = ({ src }: { src: string | undefined}) => {

  const renderPdf = async (src: string) => {
    const PDFJS = window['pdfjs-dist/build/pdf' as any] as any;
    if (typeof PDFJS === 'undefined') {
      console.error('Missing pdf.js')
    }
    const loadingTask = PDFJS.getDocument(src);
    const pdf = await loadingTask.promise;
    const page = await pdf.getPage(1);
    const scale = 1;
    const viewport = page.getViewport({ scale });

    const canvas = document.getElementById('pdf-graph') as any;
    if (canvas !== null) {
      const context = canvas.getContext('2d');
      canvas.height = viewport.height;
      canvas.width = viewport.width;
      page.render({
        canvasContext: context,
        viewport: viewport
      });
    }
  }

  useEffect(() => {
    if(src !== undefined) {
      renderPdf(src)
    }
  }, [src])

  if (src === undefined) {
    return null
  }

  return <canvas id="pdf-graph"></canvas>
}

export default ProfilerGraph;
