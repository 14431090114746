import React from "react";
import { RadioItem } from "..";
import "./RadioGroup.css";

interface IRadioGroupProps {
  defaultValue: string;
  onChange: (value: string) => void;
}

interface IRadioGroupState {
  selected: string;
}

export default class RadioGroup extends React.Component<
  IRadioGroupProps,
  IRadioGroupState
> {
  constructor(props: IRadioGroupProps) {
    super(props);
    this.state = {
      selected: this.props.defaultValue,
    };
  }

  public render() {
    return (
      <div className="radiogroup-container">
        <ul>
          {React.Children.map(
            this.props.children,
            (child: any, index: number) => {
              const radioItem = child as RadioItem;
              return (
                <li
                  className={this.buildRadioItemClassName(radioItem)}
                  onClick={() => this.onClick(radioItem.props.value)}
                >
                  <div className="radio-item-circle">
                    <div className="radio-item-circle-inner" />
                  </div>
                  <span className="radio-item-text">{radioItem}</span>
                </li>
              );
            },
          )}
        </ul>
      </div>
    );
  }

  private onClick(value: string) {
    this.setState({ selected: value });
    this.props.onChange(value);
  }

  private buildRadioItemClassName(item: RadioItem): string {
    const baseClass = "radio-item-container";
    const classes = [baseClass];
    if (item.props.value === this.props.defaultValue) {
      classes.push(`${baseClass}-selected`);
    }
    return classes.join(" ");
  }
}
