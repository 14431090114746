import env from "../../env";
import i18n from "i18next";

export default class BaseApi {
  private backendURL = env.API_URL;


  public async getAuthorizedWithCustomHeaders(
    path: string,
    customHeaders?: any,
    abortSignal?: AbortSignal
  ): Promise<Response> {
    const requestSettings = {
      headers: {
        Authorization: `Bearer ${this.getToken()}`,
        "Content-Type": "application/json",
      },
      method: "GET",
      signal: abortSignal
    };

    if (customHeaders != null) {
      requestSettings.headers = { ...requestSettings.headers, ...customHeaders };
    }
    return this.getWithEndpoint(this.backendURL, path, requestSettings);
  }

  public async getAuthorizedWithEndpoint(
    path: string,
    contentType: string = "application/json",
    abortSignal?: AbortSignal
  ): Promise<Response> {
    const requestSettings = {
      headers: {
        Authorization: `Bearer ${this.getToken()}`,
        "Content-Type": contentType,
        "accept-language": i18n.language
      },
      method: "GET",
      signal: abortSignal
    };
    return this.getWithEndpoint(this.backendURL, path, requestSettings);
  }

  public async getWithEndpoint(
    endpoint: string,
    path: string,
    requestHeaders: any = {},
  ): Promise<Response> {
    requestHeaders.Accept = "application/json";
    const response = await fetch(`${endpoint}/${path}`, requestHeaders);
    return this.parseResponse(response);
  }

  public async postAuthorized(path: string, json: string): Promise<Response> {
    return this.performRequestAuthorizedWithJSONBody(path, json, "POST");
  }

  public async patchAuthorized(path: string, json: string): Promise<Response> {
    return this.performRequestAuthorizedWithJSONBody(path, json, "PATCH");
  }

  public async deleteAuthorized(path: string, json: string): Promise<Response> {
    return this.performRequestAuthorizedWithJSONBody(path, json, "DELETE");
  }

  private async performRequestAuthorizedWithJSONBody(
    path: string,
    jsonBody: string,
    requestMethod: string,
  ): Promise<Response> {
    const requestSettings = {
      body: jsonBody,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${this.getToken()}`,
        "Content-Type": "application/json",
      },
      method: requestMethod,
    };

    const response = await fetch(`${this.backendURL}/${path}`, requestSettings);
    return this.parseResponse(response);
  }

  private getToken(): string {
    const token = localStorage.getItem("authorizationToken");
    return token ? token.toString() : "";
  }

  private parseResponse(response: Response): Response {
    if (response.status === 401) {
      localStorage.clear();
      window.location.reload();
    }
    return response;
  }
}
