import React from "react";
import { Input } from "../../elementar";
import "./SearchSelect.css";

interface ISearchSelectProps {
  inlineIcon: React.ReactNode;
  items: any[];
  className?: string;
  listenToSearch?: boolean;
  onChange?: (value: string) => void;
  onClick?: () => void;
  onSelect: (key: number) => void;
  placeholder: string;
  renderOption: (element: any) => React.ReactNode;
}

interface ISearchSelectState {
  optionListOpen: boolean;
}

export default class SearchSelect extends React.Component<
  ISearchSelectProps,
  ISearchSelectState
> {
  constructor(props: ISearchSelectProps) {
    super(props);
    this.state = {
      optionListOpen: false,
    };
  }

  public render() {
    return (
      <div className={this.buildMainClassName()}>
        <Input
          buttonInside={true}
          buttonOnClick={() => this.insideButtonClicked()}
          inlineIcon={this.props.inlineIcon}
          listenToSearch={this.props.listenToSearch}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            this.filterChanged(event)
          }
          placeholder={this.props.placeholder}
          returnPressed={() => this.selectFirstOption()}
        />
        <div className={this.buildOptionContainerClassName()}>
          <ul>
            {this.props.items.length > 0 ? (
              this.props.items.map((item: any, index: number) => {
                return (
                  <li
                    key={index}
                    onClick={() => this.optionSelected(item)}
                    className="searchselect-option-item"
                  >
                    {this.props.renderOption(item)}
                  </li>
                );
              })
            ) : (
              <li className="searchselect-option-item searchselect-option-item-disabled">
                No entry matches your filter.
              </li>
            )}
          </ul>
        </div>
      </div>
    );
  }

  private openOptionList() {
    this.setState({ optionListOpen: true });
  }

  private closeOptionList() {
    this.setState({ optionListOpen: false });
  }

  private toggleOptionList() {
    this.setState({ optionListOpen: !this.state.optionListOpen });
  }

  private optionSelected(option: any) {
    this.props.onSelect(option);
    this.closeOptionList();
  }

  private selectFirstOption() {
    if (this.props.items.length > 0) {
      this.optionSelected(this.props.items[0]);
    }
  }

  private insideButtonClicked() {
    if (this.props.onClick) {
      this.props.onClick();
      this.closeOptionList();
      return;
    }
    this.toggleOptionList();
  }

  private filterChanged(event: React.ChangeEvent<HTMLInputElement>) {
    const filter = event.target.value;
    if (this.props.onChange) {
      this.props.onChange(filter);
    }
    if (filter.length > 1) {
      this.openOptionList();
    } else {
      this.closeOptionList();
    }
  }

  private buildOptionContainerClassName(): string {
    const baseClass = "searchselect-option-container";
    if (this.state.optionListOpen) {
      return `${baseClass} ${baseClass}-open`;
    }
    return `${baseClass} ${baseClass}-closed`;
  }

  private buildMainClassName(): string {
    const baseClass = "searchselect-container";
    if (this.props.className) {
      return `${baseClass} ${this.props.className}`;
    }
    return baseClass;
  }
}
