import React from "react";

import "./AutoGrowingInput.css";
import { Tooltip } from "@mui/material";

export const getNumber = (value: number) => {
  if (isNaN(value)) return "N/A";
  return Number(value).toFixed(2).toString();
};

export const AutoGrowingField = ({
                                   value,
                                   isEdited,
                                   tooltipMsg = "Overwritten",
                                 }: any) => {

  if (isEdited) {
    return (
      <Tooltip title={tooltipMsg}>
        <span
          className="auto-growing-field auto-growing-input--edited"
          role="textbox"
        >
         {value}
        </span>
      </Tooltip>
    );
  } else {
    return (
      <span
        className="auto-growing-field"
        role="textbox"
      >
      {value}
    </span>
    );
  }
};

const AutoGrowingInput = ({
                            value,
                            onChange,
                            isEdited,
                            type,
                            tooltipMsg = "Overwritten",
                          }: any) => {
  const [isRefreshing, setisRefreshing] = React.useState(false);
  const forceUpdate = () => {
    setisRefreshing(true);
    setTimeout(() => {
      setisRefreshing(false);
    }, 100);
  };
  const onBlur = ({ target }: any) => {
    if (
      type === "rating" &&
      String(target.innerText) !== Number(value).toFixed(2).toString()
    ) {
      return onChange(target.innerText, forceUpdate);
    }

    if (type !== "rating" && target.innerText !== value) {
      return onChange(target.innerText, forceUpdate);
    }
  };
  const onKeyPress = (e: any) => {
    if (e.keyCode === 13 || e.charCode === 13) {
      e.target.blur();
    }
  };

  const canUpdate = onChange != null;

  if (isRefreshing) {
    return null;
  }

  if (isEdited) {
    return (
      <span
        className="auto-growing-input__tooltip tooltip"
        data-tooltip={tooltipMsg}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
      >
        <span
          onKeyPress={onKeyPress}
          onBlur={onBlur}
          className="auto-growing-input auto-growing-input--edited"
          role="textbox"
          contentEditable={canUpdate}
        >
          {type === "rating" ? getNumber(value) : value}
        </span>
      </span>
    );
  }

  return (
    <span
      onKeyPress={onKeyPress}
      onBlur={onBlur}
      className="auto-growing-input"
      role="textbox"
      contentEditable={canUpdate}
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
      }}
    >
      {type === "rating" ? getNumber(value) : value}
    </span>
  );
};

export function ReadOnlyInput({
                                value,
                                isEdited = false,
                                tooltipMsg = "Overwritten",
                              }: any) {
  if (isEdited) {
    return (
      <Tooltip
        title={tooltipMsg}
      >
        <span className="auto-growing-input auto-growing-input--read-only auto-growing-input--edited">
          {getNumber(value)}
        </span>
      </Tooltip>
    );
  }
  return (
    <span className="auto-growing-input auto-growing-input--read-only">
      {getNumber(value)}
    </span>
  );
}

export default AutoGrowingInput;
