import React from "react";
import { GetHandleProps } from "react-compound-slider";

interface IHandleProps {
  getHandleProps: GetHandleProps;
  handle: { id: string; value: number; percent: number };
}

export default class Handle extends React.Component<IHandleProps> {
  public render() {
    return (
      <div
        className="handle"
        style={{
          left: `${this.props.handle.percent}%`,
        }}
        {...this.props.getHandleProps(this.props.handle.id)}
      >
        <div className="handle-text">
          {Math.round(this.props.handle.value * 100) / 100}
        </div>
      </div>
    );
  }
}
