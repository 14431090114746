
const demoData = [
  {
    id: 921,
    report_object: "3c81cc15-37b7-468e-8a21-f5793e455243",
    file: "/demo/report.pdf",
    name: "report.pdf",
    description: null,
  },
  {
    id: 922,
    report_object: "3c81cc15-37b7-468e-8a21-f5793e455243",
    file: "/demo/buildings_and_dwellings.pdf",
    name: "buildings_and_dwellings.pdf",
    description: null,
  },
  {
    id: 923,
    report_object: "3c81cc15-37b7-468e-8a21-f5793e455243",
    file: "/demo/context-access-admin-large.pdf",
    name: "context-access-admin-large.pdf",
    description: null,
  },
  {
    id: 924,
    report_object: "3c81cc15-37b7-468e-8a21-f5793e455243",
    file: "/demo/context-admin-small.pdf",
    name: "context-admin-small.pdf",
    description: null,
  },
  {
    id: 925,
    report_object: "3c81cc15-37b7-468e-8a21-f5793e455243",
    file: "/demo/economics.pdf",
    name: "economics.pdf",
    description: null,
  },
  {
    id: 926,
    report_object: "3c81cc15-37b7-468e-8a21-f5793e455243",
    file: "/demo/population.pdf",
    name: "population.pdf",
    description: null,
  },
  {
    id: 927,
    report_object: "3c81cc15-37b7-468e-8a21-f5793e455243",
    file: "/demo/real_estate_market_rent.pdf",
    name: "real_estate_market_rent.pdf",
    description: null,
  },
  {
    id: 928,
    report_object: "3c81cc15-37b7-468e-8a21-f5793e455243",
    file: "/demo/real_estate_market_sale.pdf",
    name: "real_estate_market_sale.pdf",
    description: null,
  },
  {
    id: 929,
    report_object: "3c81cc15-37b7-468e-8a21-f5793e455243",
    file: "/demo/streetview.jpeg",
    name: "streetview.jpeg",
    description: null,
  },
  {
    id: 930,
    report_object: "3c81cc15-37b7-468e-8a21-f5793e455243",
    file: "/demo/bldg_by_dwelling.csv",
    name: "bldg_by_dwelling.csv",
    description: null,
  },
  {
    id: 931,
    report_object: "3c81cc15-37b7-468e-8a21-f5793e455243",
    file: "/demo/dwell_by_room.csv",
    name: "dwell_by_room.csv",
    description: null,
  },
  {
    id: 932,
    report_object: "3c81cc15-37b7-468e-8a21-f5793e455243",
    file: "/demo/dwell_by_segment.csv",
    name: "dwell_by_segment.csv",
    description: null,
  },
  {
    id: 933,
    report_object: "3c81cc15-37b7-468e-8a21-f5793e455243",
    file: "/demo/emp_by_sector.csv",
    name: "emp_by_sector.csv",
    description: null,
  },
  {
    id: 934,
    report_object: "3c81cc15-37b7-468e-8a21-f5793e455243",
    file: "/demo/households_by_family_type.csv",
    name: "households_by_family_type.csv",
    description: null,
  },
  {
    id: 935,
    report_object: "3c81cc15-37b7-468e-8a21-f5793e455243",
    file: "/demo/mover_by_origin.csv",
    name: "mover_by_origin.csv",
    description: null,
  },
  {
    id: 936,
    report_object: "3c81cc15-37b7-468e-8a21-f5793e455243",
    file: "/demo/pers_by_age.csv",
    name: "pers_by_age.csv",
    description: null,
  },
  {
    id: 937,
    report_object: "3c81cc15-37b7-468e-8a21-f5793e455243",
    file: "/demo/reo_chfm2_r_by_room.csv",
    name: "reo_chfm2_r_by_room.csv",
    description: null,
  },
  {
    id: 938,
    report_object: "3c81cc15-37b7-468e-8a21-f5793e455243",
    file: "/demo/reo_chfm2_s_by_room.csv",
    name: "reo_chfm2_s_by_room.csv",
    description: null,
  },
  {
    id: 939,
    report_object: "3c81cc15-37b7-468e-8a21-f5793e455243",
    file: "/demo/reo_insd_r_by_room.csv",
    name: "reo_insd_r_by_room.csv",
    description: null,
  },
  {
    id: 940,
    report_object: "3c81cc15-37b7-468e-8a21-f5793e455243",
    file: "/demo/reo_insd_s_by_room.csv",
    name: "reo_insd_s_by_room.csv",
    description: null,
  },
  {
    id: 941,
    report_object: "3c81cc15-37b7-468e-8a21-f5793e455243",
    file: "/demo/reo_rent_by_room.csv",
    name: "reo_rent_by_room.csv",
    description: null,
  },
  {
    id: 942,
    report_object: "3c81cc15-37b7-468e-8a21-f5793e455243",
    file: "/demo/reo_sale_by_room.csv",
    name: "reo_sale_by_room.csv",
    description: null,
  },
  {
    id: 943,
    report_object: "3c81cc15-37b7-468e-8a21-f5793e455243",
    file: "/demo/wp_by_sector.csv",
    name: "wp_by_sector.csv",
    description: null,
  },
];
export default demoData;