import React from "react";
import { SliderItem } from "react-compound-slider";

interface ITrackProps {
  source: SliderItem;
  target: SliderItem;
}

export default class Track extends React.Component<ITrackProps> {
  public render() {
    return (
      <div
        className="track"
        style={{
          left: `${this.props.source.percent}%`,
          width: `${this.props.target.percent - this.props.source.percent}%`,
        }}
      />
    );
  }
}
