import React from "react";
import "./PanelItem.css";
import { IconButton, Tooltip } from "@mui/material";
import { ArrowDropDownCircleOutlined, ArrowDropUpOutlined } from "@mui/icons-material";
import { Translation } from "react-i18next";
import Box from "@mui/material/Box";

interface IPanelItemProps {
  className?: string;
  title: string;
  buttons?: React.ReactNode[];
}

interface IPanelItemState {
  folded: boolean;
}

export default class PanelItem extends React.Component<IPanelItemProps,
  IPanelItemState> {
  constructor(props: IPanelItemProps) {
    super(props);
    this.state = {
      folded: true,
    };
  }

  public render() {
    return (
      <div className={this.createClassName()}>
        <div className="panel-item-header">
          <Box textOverflow={"ellipsis"} overflow={"hidden"}>

            {this.props.title}
          </Box>
          <div className="panel-item-buttons">
            {this.state.folded && (
              <Translation>
                {
                  (t, { i18n }) =>
                    <Tooltip title={<div>{t("global.expand")}</div>}>
                      <IconButton
                        style={{ padding: "6px" }}
                        onClick={() => {
                          this.setState({ folded: false });
                        }}
                        size="large">
                        <ArrowDropDownCircleOutlined />
                      </IconButton>
                    </Tooltip>
                }
              </Translation>
            )}

            {!this.state.folded && (
              <Translation>
                {
                  (t, { i18n }) =>
                    <Tooltip title={<div>{t("global.collapse")}</div>}>
                      <IconButton
                        style={{ padding: "6px" }}
                        onClick={() => {
                          this.setState({ folded: true });
                        }}
                        size="large">
                        <ArrowDropUpOutlined />
                      </IconButton>
                    </Tooltip>
                }
              </Translation>

            )}
            {this.props.buttons}
          </div>
        </div>
        <div className="panel-item-content" hidden={this.state.folded}>
          {this.props.children}
        </div>
      </div>
    );
  }

  private createClassName(): string {
    const baseClassName = "panel-item-container";
    const className = [baseClassName];
    if (this.props.className) {
      className.push(this.props.className);
    }
    return className.join(" ");
  }
}
