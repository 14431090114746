import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Checkbox,
  Chip,
  Dialog,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import { useTranslation } from "react-i18next";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import { AttributeMetadataDetails, MetadataTag } from "../../../util/models/AttributeMetadata";
import Box from "@mui/material/Box";
import styles from "./AddAttributes.module.css";
import { AttributeConfiguration } from "../../../util/models";
import useAttributes from "../../../recoil/hooks/UseAttributes";
import EnhancedTableToolbar from "./table/EnhancedTableToolbar.component";
import EnhancedTableHead from "./table/EnhancedTableHead.component";
import Typography from "@mui/material/Typography";
import EmptySvg from "../../icons/illustrations/emptyAttributes.svg";
import { useSetRecoilState } from "recoil";
import { currentAttributeConfigurationsState } from "../../../recoil/states/currentAttributeConfigurationsState";
import {
  currentLayerConfigurationState,
  initCurrentLayerConfigurationState,
  LAYER_CONFIGURATION_SCALE_FOCAL,
} from "../../../recoil/states/currentLayerConfigurationState";

export interface AddAttributesProps {
  isVisible: boolean,
  currentAttributeConfigurations: AttributeConfiguration[],
  closeRequest: () => any
}

const AddAttributesComponent = (props: AddAttributesProps) => {

  const toolbarRef = useRef();
  const { attributes } = useAttributes();
  const [mainTags, setMainTags] = useState(new Map());
  const [currentAttributes, setCurrentAttributes] = useState<any>([]);
  const [filteredAttributes, setFilteredAttributes] = useState([]);

  const [currentSelectedTag, setCurrentSelectedTag] = useState("all");
  const [t] = useTranslation();
  const [checkedAttributes, setCheckedAttributes] = React.useState<AttributeMetadataDetails[]>([]);

  const setAttributeConfigurations = useSetRecoilState(currentAttributeConfigurationsState);
  const setCurrentLayerConfiguration = useSetRecoilState(currentLayerConfigurationState);

  const [isFiltered, setIsFiltered] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);

  function handleToggle(value: AttributeMetadataDetails) {
    const currentIndex = checkedAttributes.indexOf(value);
    const newChecked = [...checkedAttributes];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setCheckedAttributes(newChecked);
  }

  const onMainTagClicked = useCallback((key: any) => {
    if (attributes) {
      if (toolbarRef != null && toolbarRef.current != null) {
        setIsFiltered(false);
        // @ts-ignore
        toolbarRef.current.clearValue();
      }
      if (key === "all") {
        setCurrentAttributes(attributes);
        setCurrentSelectedTag("all");
      } else {
        setCurrentAttributes(mainTags.get(key));
        setCurrentSelectedTag(key);
      }
    }
  }, [attributes, mainTags]);

  function clearStates() {
    setCheckedAttributes(new Array<AttributeMetadataDetails>());
    setIsFiltered(false);
  }

  function saveAttributes() {
    const newAttributes = new Array<AttributeConfiguration>();
    checkedAttributes.forEach(item => {
      const newAttribute = new AttributeConfiguration();
      newAttribute.attributeKey = item.id;
      newAttribute.setRangeMin(item.min_value);
      newAttribute.setRangeMax(item.max_value);
      newAttributes.push(newAttribute);
    });


    newAttributes.forEach(nAttribute=>{
      const existingConfig = props.currentAttributeConfigurations.find((attrConfig: AttributeConfiguration) => attrConfig.attributeKey === nAttribute.attributeKey);
      if(existingConfig!=null){
        nAttribute.setRangeMax(existingConfig.getRangeMax());
        nAttribute.setRangeMin(existingConfig.getRangeMin());
        nAttribute.setNormalization(existingConfig.getNormalization());
        nAttribute.setTransformation(existingConfig.getTransformation());
        nAttribute.setWeight(existingConfig.getWeight());
        nAttribute.setIsExpert(existingConfig.getIsExpert());
      }
    })


    setAttributeConfigurations([...newAttributes]);
    if (newAttributes.length === 1) {
      setCurrentLayerConfiguration({
        attributeMetadataKey: newAttributes[0].attributeKey!,
        rating: false,
        scale: LAYER_CONFIGURATION_SCALE_FOCAL,
      });
    } else {
      setCurrentLayerConfiguration(initCurrentLayerConfigurationState());
    }

    clearStates();
    props.closeRequest();
  }

  function onFilterValueChanged(value: string) {
    if (value !== "" && value.length > 2) {
      setIsFiltered(true);
      filterOptions(value);
    } else {
      setIsEmpty(false);
      setIsFiltered(false);
    }
  }

  function groupBy(list: any, keyGetter: any) {
    const map = new Map();
    list.forEach((item: any) => {

      const key = keyGetter(item);
      if (key) {
        const collection = map.get(key.name);
        if (!collection) {
          map.set(key.name, [item]);
        } else {
          collection.push(item);
        }
      }
    });
    return map;
  }

  function getTagKeywords(tags: MetadataTag[] | null): string[] {
    if (tags != null) {
      return tags.map(t => t.name);
    } else {
      return [];
    }
  }

  function filterOptions(filter: string) {
    const filteredAvailableAttributes = currentAttributes.filter(
      (attribute: AttributeMetadataDetails) => {
        const filterKeywords = filter
          .trim()
          .split(" ")
          .map((k: string) => k.trim());
        let match = true;
        for (const fK of filterKeywords) {
          const fKUpper = fK.toUpperCase();
          const metaDataKeywords = getTagKeywords(attribute.tags);
          const keywordMatch =
            metaDataKeywords.find((mK: string) => {
              return mK.toUpperCase().includes(fKUpper);
            }) !== undefined;
          const attributeNameMatch = attribute.name
            .toUpperCase()
            .includes(fKUpper);
          const attributeDescriptionMatch = attribute.description
            .toUpperCase()
            .includes(fKUpper);
          match =
            keywordMatch || attributeNameMatch || attributeDescriptionMatch;
          if (!match) {
            break;
          }
        }
        return match;
      },
    );
    setIsEmpty(filteredAvailableAttributes.length === 0);
    setFilteredAttributes(filteredAvailableAttributes);
  }

  useEffect(() => {
    if (attributes) {
      const grouped = groupBy(attributes, (a: any) => a.main_tag);
      setMainTags(grouped);
      onMainTagClicked(currentSelectedTag);
    }
    // eslint-disable-next-line
  }, [attributes]);

  useEffect(() => {
    if (props.isVisible) {
      const currentCheckedElements = new Array<AttributeMetadataDetails>();
      if (props.currentAttributeConfigurations.length > 0) {

        for (let i = 0; i < props.currentAttributeConfigurations.length; i++) {
          const l = attributes?.find((el: any) => el.id === props.currentAttributeConfigurations[i].attributeKey);
          if (l !== undefined) {
            currentCheckedElements.push(l);
          }
        }
      }
      setCheckedAttributes(currentCheckedElements);
    }
  }, [props.isVisible, attributes, props.currentAttributeConfigurations]);


  function renderAttributes() {
    let body: any = null;
    if (isFiltered) {
      body = filteredAttributes.map((row: AttributeMetadataDetails) => (
        renderRow(row)
      ));
    } else {
      body = currentAttributes.map((row: AttributeMetadataDetails) => (
        renderRow(row)
      ));
    }
    return body;
  }

  function renderEmptyScreen() {
    return <Paper elevation={2} style={{
      padding: 24,
      width: 300,
      height: 400,
      display: "flex",
      flexFlow: "column",
      alignItems: "center",
      margin: "16px auto",
    }}>
      <Typography style={{ marginTop: 32, textAlign: "center" }} variant={"h6"}>Unfortunately we have not found any
        attributes for your filter.</Typography>
      <img width={200} style={{ marginTop: 32 }} src={EmptySvg} alt={"Map"} />
    </Paper>;
  }

  function renderRow(row: AttributeMetadataDetails) {
    return (
      <TableRow
        selected={checkedAttributes.find(el => el.id === row.id) != null}
        hover key={row.id}>
        <TableCell padding={"checkbox"}>
          <Checkbox color={'secondary'}
            onChange={() => handleToggle(row)}
            checked={checkedAttributes != null && checkedAttributes.length > 0 && checkedAttributes.find(el => el.id === row.id) != null}
          />
        </TableCell>
        <TableCell component="th" scope="row" padding={"none"}>
          {row.name}
        </TableCell>
        <TableCell align="left">{row.description}</TableCell>

      </TableRow>
    );
  }

  return (
    <Dialog maxWidth={"lg"} open={props.isVisible} onClose={props.closeRequest}>
      <DialogTitle id="alert-dialog-slide-title">Choose your attributes</DialogTitle>
      <DialogContent>

        <Box marginBottom={1}>
          <Chip className={`${styles.chip} ${currentSelectedTag === "all" ? styles.selected : ""}`} color={"primary"}
                size="medium" variant="outlined"
                label="All" onClick={() => onMainTagClicked("all")} />
          {
            Array.from(mainTags.keys()).map((key: any) => {
              return (<Chip key={key} className={`${styles.chip} ${currentSelectedTag === key ? styles.selected : ""}`}
                            color={"primary"} size="medium" variant="outlined"
                            label={key} onClick={() => onMainTagClicked(key)} />);
            })
          }


        </Box>
        <EnhancedTableToolbar ref={toolbarRef} onFilterValueChanged={onFilterValueChanged} />

        {isEmpty && (
          renderEmptyScreen()
        )}
        {!isEmpty && (
          <TableContainer className={styles.container} component={Paper}>
            <Table stickyHeader className={styles.table} aria-label="simple table">
              <EnhancedTableHead />
              <TableBody>
                {
                  renderAttributes()
                }
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </DialogContent>
      <DialogActions>
        <Button color="secondary" variant="outlined" onClick={props.closeRequest}>
          {t("global.cancel")}
        </Button>
        <Button variant="contained" color="primary" onClick={() => saveAttributes()}>
          Add attributes
        </Button>

      </DialogActions>
    </Dialog>
  );
};

export default AddAttributesComponent;
