import React from "react";
import { Button } from "../../../components/elementar";
import { Alert } from "../../../util/alert";
import { IAlertButton } from "../../../util/alert/IAlertButton";
import "./AlertBox.css";

interface IAlertProps {
  alert: Alert;
}

export default class AlertBox extends React.Component<IAlertProps> {
  public render() {
    return (
      <div className="alert-container">
        <div className="alert">
          <div className="alert-text">{this.props.alert.text}</div>
          <div className="alert-buttons">
            {this.props.alert.buttons.map(
              (button: IAlertButton, index: number) => {
                return (
                  <Button
                    key={index}
                    title={button.title}
                    onClick={() => button.event(this.props.alert)}
                  />
                );
              },
            )}
            <Button
              key={0}
              title="Cancel"
              onClick={() => {
                this.props.alert.hide();
                if (this.props.alert.onHide) {
                  this.props.alert.onHide();
                }
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}
