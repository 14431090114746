import { createSelector } from "@reduxjs/toolkit";
import { filter, includes, find } from "ramda";

import { ObjectFileType, KeyNoteType } from "./profiler.types";

export const TABS_NAMES: {
  [key: string]: {
    priority: number;
    name: string;
  };
} = {
  "emp_by_sector.csv": {
    priority: 0,
    name: "Beschäftigte",
  },
  "wp_by_sector.csv": {
    priority: 1,
    name: "Arbeitsorte",
  },
  "households_by_family_type.csv": {
    priority: 0,
    name: "Haushalte",
  },
  "pers_by_age.csv": {
    priority: 1,
    name: "Personen",
  },
  "mover_by_origin.csv": {
    priority: 2,
    name: "Segmente",
  },
  "bldg_by_dwelling.csv": {
    priority: 0,
    name: "Gebäude",
  },
  "dwell_by_room.csv": {
    priority: 1,
    name: "Wohnungen",
  },
  "dwell_by_segment.csv": {
    priority: 2,
    name: "Segmente",
  },
  "reo_chfm2_r_by_room.csv": {
    priority: 2,
    name: "Kaufpreis",
  },
  "reo_insd_r_by_room.csv": {
    priority: 1,
    name: "Absorption",
  },
  "reo_rent_by_room.csv": {
    priority: 0,
    name: "Inserate",
  },
  "reo_chfm2_s_by_room.csv": {
    priority: 2,
    name: "Kaufpreis",
  },
  "reo_insd_s_by_room.csv": {
    priority: 1,
    name: "Absorption",
  },
  "reo_sale_by_room.csv": {
    priority: 0,
    name: "Inserate",
  },
};

export const FILES_NAME = {
  ECONOMY: {
    TABLE: ["emp_by_sector.csv", "wp_by_sector.csv"],
    GRAPH: "economics.pdf",
  },
  POPULATION: {
    TABLE: [
      "households_by_family_type.csv",
      "pers_by_age.csv",
      "mover_by_origin.csv",
    ],
    GRAPH: "population.pdf",
  },
  BUILDING_STOCK: {
    TABLE: [
      "bldg_by_dwelling.csv",
      "dwell_by_room.csv",
      "dwell_by_segment.csv",
    ],
    GRAPH: "buildings_and_dwellings.pdf",
  },
  RENTAL_MARKET: {
    TABLE: [
      "reo_chfm2_r_by_room.csv",
      "reo_insd_r_by_room.csv",
      "reo_rent_by_room.csv",
    ],
    GRAPH: "real_estate_market_rent.pdf",
  },
  BUYING_MARKET: {
    TABLE: [
      "reo_chfm2_s_by_room.csv",
      "reo_insd_s_by_room.csv",
      "reo_sale_by_room.csv",
    ],
    GRAPH: "real_estate_market_sale.pdf",
  },
  BOUNDERIES: {
    TABLE: [
      "traveltime.geojson",
      "zoning.geojson",
      "admin.geojson"
    ],
    GRAPH: "real_estate_market_sale.pdf",
  },
};

const userObjectsSelector = (state: any) => state.profiler.objects;
const selectedObjectSelector = (state: any) => state.profiler.selectedObject;
const selectObjectFiles = (state: any) => state.profiler.files;
const selectIsFetching = (state: any) => state.profiler.isFetching;
const selectKeyNotes = (state: any) => state.profiler.keyNotes.notes;

const includesFiles = (filesNames: string[]) => (file: ObjectFileType) =>
  includes(file.name, filesNames);

const includesFile = (fileName: string) => (file: ObjectFileType) =>
  file.name === fileName;

// eslint-disable-next-line
export default {
  objects: createSelector(userObjectsSelector, (objects) => objects),
  selectedObject: createSelector(
    selectedObjectSelector,
    (selectedObject) => selectedObject,
  ),
  files: createSelector(selectObjectFiles, (files) => files),
  isFetching: createSelector(selectIsFetching, (isFetching) => isFetching),
  populationTableFiles: createSelector(selectObjectFiles, (files) =>
    filter(includesFiles(FILES_NAME.POPULATION.TABLE), files),
  ),
  buildingStockTableFiles: createSelector(selectObjectFiles, (files) =>
    filter(includesFiles(FILES_NAME.BUILDING_STOCK.TABLE), files),
  ),
  economyTableFiles: createSelector(selectObjectFiles, (files) =>
    filter(includesFiles(FILES_NAME.ECONOMY.TABLE), files),
  ),
  rentalMarketTableFiles: createSelector(selectObjectFiles, (files) =>
    filter(includesFiles(FILES_NAME.RENTAL_MARKET.TABLE), files),
  ),
  buyingMarketTableFiles: createSelector(selectObjectFiles, (files) =>
    filter(includesFiles(FILES_NAME.BUYING_MARKET.TABLE), files),
  ),
  populationGraphFile: createSelector(selectObjectFiles, (files) =>
    find(includesFile(FILES_NAME.POPULATION.GRAPH))(files),
  ),
  buildingStockGraphFile: createSelector(selectObjectFiles, (files) =>
    find(includesFile(FILES_NAME.BUILDING_STOCK.GRAPH))(files),
  ),
  economyGraphFile: createSelector(selectObjectFiles, (files) =>
    find(includesFile(FILES_NAME.ECONOMY.GRAPH))(files),
  ),
  rentalMarketGraphFile: createSelector(selectObjectFiles, (files) =>
    find(includesFile(FILES_NAME.RENTAL_MARKET.GRAPH))(files),
  ),
  buyingMarketGraphFile: createSelector(selectObjectFiles, (files) =>
    find(includesFile(FILES_NAME.BUYING_MARKET.GRAPH))(files),
  ),
  keyNoteBySlug: createSelector(
    selectKeyNotes,
    (keyNotes: KeyNoteType[]) => (slug: string) =>
      find((note: KeyNoteType) => note.key_note_slug === slug)(keyNotes),
  ),
  tableFiles: createSelector(
    selectObjectFiles,
    (files) => (tableNames: string[]) =>
      filter(includesFiles(tableNames), files),
  ),
  graphFile: createSelector(selectObjectFiles, (files) => (graphName: string) =>
    find(includesFile(graphName))(files),
  ),
};
