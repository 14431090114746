class E {
  public readonly ADDRESS_SEARCHED: string = "ADDRESS_SEARCHED";
  public readonly ATTRIBUTE_CONFIGURATION_CHANGED: string =
    "ATTRIBUTE_CONFIGURATION_CHANGED";
  public readonly REFRESH_MAP_CLICKED: string = "REFRESH_MAP_CLICKED";
  public readonly APPLY_TEMPLATE: string = "APPLY_TEMPLATE";
  public readonly REMOVE_CONFIGURATION: string = "REMOVE_CONFIGURATION";

  public readonly ADD_TEMPLATE: string = "ADD_TEMPLATE";
  public readonly APPLY_COMPOSITION: string = "APPLY_COMPOSITION";
}

export default new E();
