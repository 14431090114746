import React from "react";

export default class Logo extends React.Component {
  public render() {
    return (
      <svg
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1914.500000 306.972377"
        preserveAspectRatio="xMidYMid meet"
      >
        <g
          transform="translate(0.000000,333.000000) scale(0.100000,-0.100000)"
          fill="#000000"
          stroke="none"
        >
          <path
            d="M0 2501 c0 -900 3 -953 56 -1171 143 -579 556 -931 1184 -1009 108
-14 410 -14 520 -1 371 45 637 164 856 384 153 153 252 318 322 536 69 216 82
343 82 772 l0 348 -350 0 -350 0 0 -269 c0 -376 -19 -541 -81 -703 -62 -161
-181 -300 -317 -371 -234 -123 -595 -123 -822 0 -204 110 -323 299 -377 597
-14 78 -17 204 -20 904 l-4 812 -350 0 -349 0 0 -829z"
          />
          <path
            d="M2330 2981 l0 -349 758 -5 c811 -4 837 -6 1008 -58 98 -30 230 -101
296 -159 106 -94 183 -238 214 -398 19 -99 18 -303 -2 -400 -55 -267 -230
-455 -504 -541 -143 -45 -218 -52 -602 -58 l-368 -5 0 -350 0 -350 438 5 c458
5 474 6 672 57 568 145 921 570 989 1192 16 140 13 398 -4 535 -47 352 -168
618 -379 829 -221 221 -519 353 -888 393 -60 7 -388 11 -863 11 l-765 0 0
-349z"
          />
          <path
            d="M5490 2510 c0 -520 4 -856 11 -918 82 -739 510 -1182 1229 -1271 46
-6 232 -11 412 -11 l328 0 0 300 c0 165 -1 300 -2 301 -2 0 -127 4 -278 9
-466 15 -588 49 -756 216 -113 112 -188 279 -225 506 -8 51 -13 318 -16 881
l-5 807 -349 0 -349 0 0 -820z"
          />
          <path
            d="M9920 1434 c-41 -7 -76 -13 -77 -13 -2 -1 -3 -253 -3 -560 0 -524 1
-560 18 -565 91 -29 344 -45 438 -27 146 28 257 107 310 223 27 58 29 72 29
183 0 114 -2 123 -32 187 -54 114 -142 182 -273 209 -73 15 -202 6 -267 -19
l-53 -20 0 203 c0 112 -3 205 -7 208 -5 2 -42 -2 -83 -9z m416 -508 c93 -43
129 -116 129 -256 0 -87 -3 -100 -30 -149 -32 -58 -89 -103 -155 -121 -39 -11
-191 -13 -239 -4 l-31 7 0 248 c0 163 4 250 11 254 39 25 123 44 189 45 58 0
86 -5 126 -24z"
          />
          <path
            d="M18430 1434 c-41 -7 -76 -13 -77 -13 -2 -1 -3 -253 -3 -560 0 -524 1
-560 18 -565 91 -29 344 -45 438 -27 146 28 257 107 310 223 27 58 29 72 29
183 0 114 -2 123 -32 187 -54 114 -142 182 -273 209 -73 15 -202 6 -267 -19
l-53 -20 0 203 c0 112 -3 205 -7 208 -5 2 -42 -2 -83 -9z m416 -508 c93 -43
129 -116 129 -256 0 -87 -3 -100 -30 -149 -32 -58 -89 -103 -155 -121 -39 -11
-191 -13 -239 -4 l-31 7 0 248 c0 163 4 250 11 254 39 25 123 44 189 45 58 0
86 -5 126 -24z"
          />
          <path
            d="M12915 1326 c-5 -3 -36 -7 -67 -11 l-58 -6 0 -509 0 -509 58 -8 c31
-4 145 -8 252 -7 167 1 206 4 275 22 239 65 376 207 407 420 24 166 -21 320
-127 431 -66 70 -138 110 -260 147 -81 24 -108 27 -280 30 -104 2 -194 2 -200
0z m372 -142 c140 -29 231 -94 281 -203 21 -45 27 -75 30 -154 5 -114 -7 -171
-51 -246 -41 -68 -119 -125 -212 -153 -58 -18 -95 -22 -220 -23 l-150 0 -3
398 -2 397 125 0 c76 0 156 -6 202 -16z"
          />
          <path
            d="M7962 938 c3 -373 4 -385 26 -440 44 -109 141 -190 267 -223 77 -20
252 -20 328 0 124 32 216 104 263 208 l29 62 3 388 3 387 -86 0 -85 0 0 -342
c0 -189 -3 -360 -6 -381 -11 -65 -63 -137 -123 -170 -70 -39 -177 -48 -267
-22 -71 20 -145 88 -165 150 -9 28 -14 145 -16 403 l-5 362 -84 0 -85 0 3
-382z"
          />
          <path
            d="M16540 800 l0 -520 365 0 365 0 0 65 0 65 -280 0 -280 0 0 455 0 455
-85 0 -85 0 0 -520z"
          />
          <path
            d="M14963 1288 l-53 -10 0 -392 c0 -418 4 -462 52 -528 46 -66 171 -105
301 -95 83 6 187 33 187 49 0 16 -22 92 -29 100 -4 4 -28 1 -55 -7 -68 -21
-172 -19 -216 3 -68 34 -74 59 -78 309 l-4 223 176 0 176 0 0 60 0 60 -175 0
-175 0 0 120 0 120 -27 -1 c-16 -1 -52 -6 -80 -11z"
          />
          <path
            d="M9325 1065 c-44 -7 -99 -18 -122 -24 l-43 -12 0 -374 0 -375 85 0 85
0 0 329 0 330 56 7 c61 7 218 1 246 -11 17 -7 20 1 43 96 6 26 5 26 -70 38
-94 14 -176 13 -280 -4z"
          />
          <path
            d="M11025 1074 c-11 -2 -44 -9 -73 -15 l-54 -11 7 -51 c4 -29 10 -55 14
-59 3 -4 26 -2 51 4 25 5 92 11 150 12 85 1 112 -3 143 -18 55 -27 80 -69 85
-139 l5 -59 -39 6 c-106 16 -135 17 -210 7 -166 -21 -261 -79 -290 -178 -10
-35 -13 -68 -9 -96 29 -172 173 -233 493 -209 87 7 171 16 187 20 l30 8 0 275
c0 323 -5 350 -75 420 -65 65 -116 81 -270 85 -69 1 -134 1 -145 -2z m279
-440 l46 -7 0 -118 0 -117 -76 -7 c-131 -13 -237 8 -277 53 -24 26 -31 90 -15
121 34 64 169 96 322 75z"
          />
          <path
            d="M11955 1069 c-38 -5 -94 -14 -122 -20 l-53 -10 0 -380 0 -379 85 0
85 0 0 329 0 329 66 7 c99 11 206 2 245 -21 19 -11 45 -40 59 -65 25 -43 25
-48 28 -311 l4 -268 85 0 85 0 -4 273 c-6 320 -12 350 -86 426 -69 71 -135 93
-287 97 -66 1 -151 -1 -190 -7z"
          />
          <path
            d="M14155 1074 c-11 -2 -44 -9 -73 -15 l-54 -11 7 -51 c4 -29 10 -55 14
-59 3 -4 26 -2 51 4 25 5 92 11 150 12 85 1 112 -3 143 -18 55 -27 80 -69 85
-139 l5 -59 -39 6 c-106 16 -135 17 -210 7 -166 -21 -261 -79 -290 -178 -10
-35 -13 -68 -9 -96 29 -172 173 -233 493 -209 87 7 171 16 187 20 l30 8 0 275
c0 323 -5 350 -75 420 -65 65 -116 81 -270 85 -69 1 -134 1 -145 -2z m279
-440 l46 -7 0 -118 0 -117 -76 -7 c-131 -13 -237 8 -277 53 -24 26 -31 90 -15
121 34 64 169 96 322 75z"
          />
          <path
            d="M15775 1074 c-11 -2 -44 -9 -73 -15 l-54 -11 7 -51 c4 -29 10 -55 14
-59 3 -4 26 -2 51 4 25 5 92 11 150 12 85 1 112 -3 143 -18 55 -27 80 -69 85
-139 l5 -59 -39 6 c-106 16 -135 17 -210 7 -166 -21 -261 -79 -290 -178 -10
-35 -13 -68 -9 -96 29 -172 173 -233 493 -209 87 7 171 16 187 20 l30 8 0 275
c0 323 -5 350 -75 420 -65 65 -116 81 -270 85 -69 1 -134 1 -145 -2z m279
-440 l46 -7 0 -118 0 -117 -76 -7 c-131 -13 -237 8 -277 53 -24 26 -31 90 -15
121 34 64 169 96 322 75z"
          />
          <path
            d="M17595 1074 c-11 -2 -44 -9 -73 -15 l-54 -11 7 -51 c4 -29 10 -55 14
-59 3 -4 26 -2 51 4 25 5 92 11 150 12 85 1 112 -3 143 -18 55 -27 80 -69 85
-139 l5 -59 -39 6 c-106 16 -135 17 -210 7 -166 -21 -261 -79 -290 -178 -10
-35 -13 -68 -9 -96 29 -172 173 -233 493 -209 87 7 171 16 187 20 l30 8 0 275
c0 323 -5 350 -75 420 -65 65 -116 81 -270 85 -69 1 -134 1 -145 -2z m279
-440 l46 -7 0 -118 0 -117 -76 -7 c-131 -13 -237 8 -277 53 -24 26 -31 90 -15
121 34 64 169 96 322 75z"
          />
        </g>
      </svg>
    );
  }
}
