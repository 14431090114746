import * as React from "react";
import * as ReactDOM from "react-dom";
import { Provider } from "react-redux";
import TagManager from "react-gtm-module";
import { hotjar } from "react-hotjar";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import App from "./App";
import store from "./store/store";
import env from "./env";

import "./index.css";
import * as serviceWorker from "./registerServiceWorker";
import { RecoilRoot } from "recoil";

if (env.IS_PRODUCTION) {
  TagManager.initialize({ gtmId: env.GTM_ID });
}

Sentry.init({
  dsn: env.SENTRY_DSN,
  environment: env.ENVIRONMENT,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
});

if (env.HOTJAR_ID && env.HOTJAT_SV) {
  hotjar.initialize(Number(env.HOTJAR_ID), Number(env.HOTJAT_SV));
}

ReactDOM.render(
  <Provider store={store}>
    <RecoilRoot>
      <App />
    </RecoilRoot>
  </Provider>,

  document.getElementById("root") as HTMLElement,
);

serviceWorker.unregister();
