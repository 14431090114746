import { atom } from "recoil";

export interface CompositeConfiguration {
  configuration_id: number,
  weight: number
}

export interface Composite {
  id: number,
  name: string,
  config: CompositeConfiguration[]
}

export const currentAttributeCompositeState = atom({
  key: "currentAttributeCompositeState", // unique ID (with respect to other atoms/selectors)
  default: null, // default value (aka initial value)
});
