import React, { useCallback, useEffect, useState } from "react";
import * as L from "leaflet";
import "./Boundaries.modules.css";
import { GeoJSON, MapContainer as LeafletMap, TileLayer } from "react-leaflet";
import { useRecoilValue } from "recoil";
import {
  cartoTileLayerConfiguration
} from "../../../../recoil/states/currentTileLayerState";
import Box from "@mui/material/Box";
import { currentObjectFilesState, currentObjectState } from "../../../../recoil/states/currentObjectState";
import axios from "axios";
import _ from "lodash";
import { ObjectFileType } from "../../../../store/modules/profiler/profiler.types";
import Typography from "@mui/material/Typography";

const BoundariesComponent = (props: {
  geoJsonElements: string[]
}) => {


  const currentObject = useRecoilValue(currentObjectState);
  const currentObjectFiles = useRecoilValue(currentObjectFilesState);

  const [map, setMap] = useState<L.Map | null>(null);
  const [travelTimeBounds, setTravelTimeBounds] = useState<any>();
  const [travelTime, setTravelTime] = useState();
  const [travelTimelegend, setTravelTimelegend] = useState<[]>();

  const [zoningMap, setZoningMap] = useState<L.Map | null>(null);
  const [zoningBounds, setZoningBounds] = useState<any>();
  const [zoning, setZoning] = useState();
  const [zoningLegend, setZoningLegend] = useState<[]>();

  const [adminMap, setAdminMap] = useState<L.Map | null>(null);
  const [adminBounds, setAdminBounds] = useState<any>();
  const [admin, setAdmin] = useState();
  const [adminLegend, setAdminLegend] = useState<[]>();


  useEffect(() => {
    if (map && travelTimeBounds) {
      map.fitBounds(travelTimeBounds);
    }
  }, [map, travelTimeBounds]);

  useEffect(() => {
    if (zoningMap && zoningBounds) {
      zoningMap.fitBounds(zoningBounds);
    }
  }, [zoningMap, zoningBounds]);

  useEffect(() => {
    if (adminMap && adminBounds) {
      adminMap.fitBounds(adminBounds);
    }
  }, [adminMap, adminBounds]);

  useEffect(() => {
    if (currentObjectFiles.length > 0 && currentObject.id > 0) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentObjectFiles, currentObject]);

  function style(feature: any) {
    return {
      color: feature.properties.color,
    };
  };

  const getData = useCallback(() => {
    if (props.geoJsonElements.length > 0) {
      const jsonFiles: ObjectFileType[] = [];
      console.log(props.geoJsonElements);
      props.geoJsonElements.forEach(element => {
        const currentFileIndex = _.findIndex(currentObjectFiles, { "name": element });
        if (currentFileIndex > -1) {
          jsonFiles.push(currentObjectFiles[currentFileIndex]);
        }
      });

      axios
        .all(jsonFiles.map((file) => axios.get(file.file)))
        .then(
          axios.spread((...json) => {
            json.forEach((f, k) => {
              console.log(jsonFiles[k].name);
              console.log(f.data);
              const b = L.geoJSON(f.data).getBounds();

              if (jsonFiles[k].name === "traveltime.geojson") {
                setTravelTimeBounds(b);
                setTravelTime(f.data);
                setTravelTimelegend(f.data.features);
              } else if (jsonFiles[k].name === "zoning.geojson") {
                setZoningBounds(b);
                setZoning(f.data);
                setZoningLegend(f.data.features);
              } else if (jsonFiles[k].name === "admin.geojson") {
                setAdminBounds(b);
                setAdmin(f.data);
                setAdminLegend(f.data.features);
              }
            });

          }),
        )
        .finally(() => {

        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentObjectFiles]);

  // @ts-ignore
  return (
    <div className="profiler__content">
      <h1>Boundaries</h1>
      <div className="profiler__content__data" style={{maxWidth: 'unset'}}>
        <Box display={"flex"} flexWrap={"wrap"} columnGap={4} rowGap={4}>
          <Box>
            <Typography variant={"body1"}>Catchment Area 15min</Typography>
            <div className="boundaries-map">
              <LeafletMap
                zoom={15}
                className="boundaries-map-leaflet"
                whenCreated={setMap}
              >
                <TileLayer url={cartoTileLayerConfiguration().providerURL}
                           attribution={cartoTileLayerConfiguration().providerAttribution} />

                {travelTime && (
                  <GeoJSON data={travelTime} style={style}></GeoJSON>
                )}
              </LeafletMap>
            </div>
            {travelTimelegend && (
              <Box marginTop={2} display={"flex"}>
                {travelTimelegend.map((feature: any) => {
                  return <Box marginRight={2} display={"flex"} alignItems={"center"}>
                    <div style={{
                      backgroundColor: feature.properties.color,
                      width: 30,
                      height: 15,
                      marginRight: 16,
                    }}></div>
                    <div>{feature.properties.index}</div>
                  </Box>;
                })}
              </Box>
            )}
          </Box>
          <Box>
            <Typography variant={"body1"}>Administrative Boundaries</Typography>
            <div className="boundaries-map">
              <LeafletMap
                zoom={15}
                className="boundaries-map-leaflet"
                whenCreated={setZoningMap}
              >
                <TileLayer url={cartoTileLayerConfiguration().providerURL}
                           attribution={cartoTileLayerConfiguration().providerAttribution} />

                {zoning && (
                  <GeoJSON data={zoning} style={style}></GeoJSON>
                )}
              </LeafletMap>
            </div>
            {zoningLegend && (
              <Box marginTop={2} display={"flex"}>
                {zoningLegend.map((feature: any) => {
                  return <Box marginRight={2} display={"flex"} alignItems={"center"}>
                    <div style={{
                      backgroundColor: feature.properties.color,
                      width: 30,
                      height: 15,
                      marginRight: 16,
                    }}></div>
                    <div>{feature.properties.type}</div>
                  </Box>;
                })}
              </Box>
            )}
          </Box>

          <Box>
            <Typography variant={"body1"}>Administrative Boundaries</Typography>
            <div className="boundaries-map">
              <LeafletMap
                zoom={15}
                className="boundaries-map-leaflet"
                whenCreated={setAdminMap}
              >
                <TileLayer url={cartoTileLayerConfiguration().providerURL}
                           attribution={cartoTileLayerConfiguration().providerAttribution} />

                {admin && (
                  <GeoJSON data={admin} style={style}></GeoJSON>
                )}
              </LeafletMap>
            </div>
            {adminLegend && (
              <Box marginTop={2} display={"flex"}>
                {adminLegend.map((feature: any) => {
                  return <Box marginRight={2} display={"flex"} alignItems={"center"}>
                    <div style={{
                      backgroundColor: feature.properties.color,
                      width: 30,
                      height: 15,
                      marginRight: 16,
                    }}></div>
                    <div>{feature.properties.type}</div>
                  </Box>;
                })}
              </Box>
            )}
          </Box>
        </Box>
      </div>
    </div>
  );
};

export default BoundariesComponent;

