import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import Mixpanel, { MIXPANEL_PAGENAME_DEFAULT } from "./../../util/Mixpanel";


import { ActivityIndicator, Form, Heading1, Input } from "../../components/elementar";
import { AuthApi } from "../../util/api";
import "./LoginPage.css";
import { Translation } from "react-i18next";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import { Box, Checkbox } from "@mui/material";
import Button from "@mui/material/Button";
import env from "../../env";


interface ILoginState {
  errorMessage?: string;
  isBusy: boolean;
  password: string;
  username: string;
  termsConditionsChecked: boolean;
}

class LoginPage extends React.Component<RouteComponentProps<any>, ILoginState> {
  constructor(props: RouteComponentProps<any>) {
    super(props);
    this.state = {
      errorMessage: "",
      isBusy: false,
      password: "",
      username: "",
      termsConditionsChecked: false,
    };
  }

  public handleChange(event: React.ChangeEvent<HTMLInputElement>) {

  };

  public render() {
    const labelWidth = 120;
    return (
      <div className="login-container">
        {
          Mixpanel.getInstance().setPageName(MIXPANEL_PAGENAME_DEFAULT, true)
        }
        <ActivityIndicator
          showActivity={this.state.isBusy}
          errorMessage={this.state.errorMessage}
        />
        <Box>
          <Paper elevation={2}>
            <Box padding={2}>
              <Heading1>Login</Heading1>
              <Form>
                <Input
                  label="Username"
                  type="text"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    this.usernameChanged(event.target.value)
                  }
                  labelWidth={labelWidth}
                  returnPressed={() => {
                    if (this.state.termsConditionsChecked) {
                      this.login();
                    }
                  }}
                />
                <Input
                  label="Password"
                  type="password"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    this.passwordChanged(event.target.value)
                  }
                  labelWidth={labelWidth}
                  returnPressed={() => {
                    if (this.state.termsConditionsChecked) {
                      this.login();
                    }
                  }}
                />

                <Translation>
                  {
                    (t, { i18n }) =>
                      <Box>
                        <Checkbox color={'secondary'} checked={this.state.termsConditionsChecked} onChange={(event) => this.setState({
                          termsConditionsChecked: event.target.checked,
                        })} />
                        <Link variant="body2" color="secondary" href="/assets/210910_AGB_UDL4cust.pdf"
                              download="210910_AGB_UDL4cust.pdf">{t("termsConditions.loginAndDownload")}</Link>
                      </Box>
                  }
                </Translation>

                <Box display={"flex"} marginTop={2} flexDirection={"column"}>
                  <Box display={"flex"} alignSelf={"flex-end"}>

                    <Link alignSelf={"center"} style={{ marginRight: 16 }} variant={"body2"} color={"secondary"}
                          href={env.REGISTER_URL}>Sign up for free</Link>

                    <Link alignSelf={"center"} style={{ marginRight: 16 }} variant={"body2"} color={"secondary"}
                          href={"mailto:support@urbandatalab.net"}>Forgot password?</Link>

                    <Button variant={"contained"} color={"primary"} disabled={!this.state.termsConditionsChecked}
                            onClick={() => this.login()}>Login</Button>
                  </Box>
                </Box>
              </Form>
            </Box>
          </Paper>
        </Box>
      </div>
    );
  }

  private async login() {
    const authApi = new AuthApi();
    this.setState({ isBusy: true });
    authApi
      .getAuthorizationToken(this.state.username, this.state.password)
      .then((response: any) => {
        this.setState({ isBusy: false });
        this.props.history.push("/");
      })
      .catch((reason: Error) => {
        this.setState({
          errorMessage: reason.message,
          isBusy: false,
        });
      });
  }

  private usernameChanged(value: string) {
    this.setState({ username: value });
  }

  private passwordChanged(value: string) {
    this.setState({ password: value });
  }
}

export default withRouter(LoginPage);
