import mixpanel from "mixpanel-browser";
import env from "../env";
import UserProfile from "./models/UserProfile";

// mixpanel.init(env.MIXPANEL_TOKEN);

export const MIXPANEL_PAGENAME_DEFAULT = "App";
export const MIXPANEL_PAGENAME_SCOUT = "Scout";
export const MIXPANEL_PAGENAME_PROFILER = "Profiler";
export const MIXPANEL_PAGENAME_MANAGER = "Manager";

export const MIXPANEL_SUBPAGENAME_MANAGER_PORTFOLIO = "Portfolio";
export const MIXPANEL_SUBPAGENAME_MANAGER_PROPERTIES = "Properties";
export const MIXPANEL_SUBPAGENAME_MANAGER_BUILDINGS = "Buildings";
export const MIXPANEL_SUBPAGENAME_MANAGER_UNITS = "Units";

export const MIXPANEL_EU_RESIDENCY_HOST = "https://api-eu.mixpanel.com";

export const MIXPANEL_EVENTNAME_PAGEVIEWED = "Page Viewed";
export const MIXPANEL_EVENTNAME_LOGIN = "Log-in to app";
export const MIXPANEL_EVENTNAME_LOGOUT = "Log-out of app";
export const MIXPANEL_EVENTNAME_ADD_LOCATION_PORTFOLIO = "Added location to portfolio";
export const MIXPANEL_EVENTNAME_SELECT_LAYER = "Selected Layer in Scout";
export const MIXPANEL_EVENTNAME_EDITED_LAYER = "Edited single Layer";
export const MIXPANEL_EVENTNAME_LOCAL_REPORT = "Started creation of local report";
export const MIXPANEL_EVENTNAME_REGENERATE_REPORT = "Started regeneration of local report";
export const MIXPANEL_EVENTNAME_OPEN_LOCAL_REPORT = "Open existing local report";
export const MIXPANEL_EVENTNAME_EDIT_LOCATION = "Clicked Edit Location";
export const MIXPANEL_EVENTNAME_CANCEL_LOCATION = "Clicked Cancel Location";
export const MIXPANEL_EVENTNAME_EDIT_CLUSTER_LOCATION = "Clicked Edit Cluster Location";
export const MIXPANEL_EVENTNAME_SAVED_SUBRATING = "Saved Template";
export const MIXPANEL_EVENTNAME_DELETED_SUBRATING = "Deleted Template";
export const MIXPANEL_EVENTNAME_LOADED_SUBRATING = "Loaded Template";
export const MIXPANEL_EVENTNAME_DELETED_ENTITY = "Deleted Entity";
export const MIXPANEL_EVENTNAME_GO_TO_MAP = "Clicked Go To Map Location";
export const MIXPANEL_EVENTNAME_EXPORT_MAP = "Clicked Export Map";
export const MIXPANEL_EVENTNAME_EXPORT_CSV = "Clicked Export CSV";
export const MIXPANEL_EVENTNAME_ADDED_COMPOSITION_LAYER = "Added layer to Composite";
export const MIXPANEL_EVENTNAME_SAVED_COMPOSITION = "Saved Composite";
export const MIXPANEL_EVENTNAME_DELETED_COMPOSITION = "Deleted Composite";
export const MIXPANEL_EVENTNAME_LOADED_COMPOSITION = "Loaded Composite";
export const MIXPANEL_EVENTNAME_OPEN_IN_PORTFOLIO = "Clicked Open In Portfolio";
export const MIXPANEL_EVENTNAME_ADD_TO_PORTFOLIO = "Clicked Add To Portfolio";
export const MIXPANEL_EVENTNAME_SEARCH_FOR_LOCATION = "Searched for Location";

export const MIXPANEL_COMPONENT_MARKEDLOCATION = "MarkedLocation";


interface MixpanelProps {
  [key: string]: any;
}

class Mixpanel {
  private static instance: Mixpanel;

  private m: any;
  private userId: string;
  private pageName: string;
  private subPageName: string | null;


  private constructor() {
  }

  static getInstance(): Mixpanel {
    if (!this.instance) {
      this.instance = new this();
      this.instance.m = mixpanel;
      console.log(`Mixpanel_token: ${env.MIXPANEL_TOKEN}`);
      this.instance.m.init(env.MIXPANEL_TOKEN, {
        api_host: MIXPANEL_EU_RESIDENCY_HOST,
      });
    }

    return this.instance;
  }

  private identify(userId: number | null) {
    this.userId = userId != null ? userId.toString() : "-1";
    console.log(`mixpanel-identify userId: ${userId}`);
    this.m.identify(userId);
  }

  setUser(user: UserProfile) {
    console.log(`mixpanel User: ${JSON.stringify(user)}`);
    this.identify(user.id);
    this.m.people.set({
      "$name": user.username,
      "$first_name": user.first_name,
      "$last_name": user.last_name,
      "$email": user.email,
      "DateJoined": user.date_joined,
      "UserID": this.userId,
      "Organization": user.organization,
    });
  }

  setPageName(pageName: string, disposeSubPageName: boolean = false) {
    console.log("pagename: " + pageName);
    this.pageName = pageName;
    if (disposeSubPageName) {
      this.subPageName = null;
    }
  }

  setSubPageName(subPageName: string) {
    this.subPageName = subPageName;
  }

  getPageName(): string {
    if (this.pageName != null && this.subPageName != null) {
      return `${this.pageName} - ${this.subPageName}`;
    } else if (this.pageName != null) {
      return this.pageName;
    }
    console.log("WARNING: no pageName set: fallback App");
    return MIXPANEL_PAGENAME_DEFAULT;
  }

  trackEvent(eventName: string, props: MixpanelProps) {
    console.log(`mixpanel-track: Event: ${eventName}, props: ${JSON.stringify(props)}, userId: ${this.userId}, pageName: ${this.getPageName()}`);
    const newProps = { ...props };
    newProps.PageName = this.getPageName();

    this.m.track(eventName, newProps);
  }

  dispose() {
    this.subPageName = null;
  }
}

export default Mixpanel;
