import React from "react";

export default class Select extends React.Component {
  public render() {
    return (
      <svg
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        width="507.332977pt"
        height="508.178499pt"
        viewBox="0 0 507.332977 508.178499"
        preserveAspectRatio="xMidYMid meet"
      >
        <g
          transform="translate(-3.661474,510.000000) scale(0.100000,-0.100000)"
          fill="#000000"
          stroke="none"
        >
          <path
            d="M1919 5077 c-24 -13 -56 -41 -70 -63 -14 -21 -241 -628 -503 -1349
-492 -1350 -494 -1357 -467 -1426 32 -85 124 -136 214 -119 60 12 87 28 120
72 15 20 203 520 472 1258 245 673 447 1226 449 1228 4 4 2517 -495 2523 -502
14 -14 -573 -1734 -815 -2386 -335 -903 -497 -1186 -751 -1314 -144 -73 -381
-75 -501 -3 -175 104 -261 294 -278 616 l-7 133 -1110 449 c-611 247 -1113
449 -1116 449 -3 0 -14 -46 -24 -102 -22 -120 -25 -347 -6 -448 46 -249 184
-467 379 -597 61 -41 1793 -832 1937 -885 133 -49 247 -70 372 -70 563 3 876
243 1178 904 197 429 634 1644 1043 2903 136 416 152 470 152 499 0 51 -24
103 -65 143 -37 35 -55 44 -117 57 -298 65 -2895 576 -2926 576 -21 0 -59 -10
-83 -23z"
          />
          <path
            d="M2259 3987 c-66 -35 -101 -93 -101 -167 0 -117 90 -200 217 -200 144
0 236 144 176 276 -46 100 -189 145 -292 91z"
          />
          <path
            d="M2908 3820 c-51 -16 -94 -49 -118 -92 -47 -84 -20 -201 58 -253 49
-32 894 -265 967 -266 63 -2 94 11 139 55 77 78 74 188 -7 273 -37 40 -29 37
-522 172 -416 114 -472 126 -517 111z"
          />
          <path
            d="M1975 3184 c-107 -39 -156 -164 -105 -270 40 -82 163 -133 251 -104
87 29 140 98 141 184 1 100 -65 176 -171 196 -65 12 -66 12 -116 -6z"
          />
          <path
            d="M2545 3006 c-22 -8 -56 -33 -76 -55 -83 -93 -56 -240 54 -298 16 -8
220 -78 455 -155 368 -122 436 -142 492 -142 74 -1 120 23 160 80 33 49 38
138 12 191 -40 77 -54 83 -525 238 -243 80 -462 147 -487 150 -27 3 -61 -1
-85 -9z"
          />
          <path
            d="M1640 2418 c-66 -36 -102 -94 -103 -166 -1 -129 97 -212 237 -200
185 15 242 254 83 352 -67 41 -155 47 -217 14z"
          />
          <path
            d="M2245 2218 c-50 -10 -107 -55 -129 -102 -38 -81 -9 -195 64 -244 54
-36 912 -314 970 -314 135 0 232 151 172 268 -43 84 -55 89 -520 243 -238 78
-451 145 -474 149 -24 5 -61 4 -83 0z"
          />
        </g>
      </svg>
    );
  }
}
