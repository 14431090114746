import React from "react";

export default class Save extends React.Component {
  public render() {
    return (
      <svg
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        width="512.295925pt"
        height="452.664984pt"
        viewBox="0 0 512.295925 452.664984"
        preserveAspectRatio="xMidYMid meet"
      >
        <g
          transform="translate(0.149855,472.610919) scale(0.100000,-0.100000)"
          fill="#000000"
          stroke="none"
        >
          <path
            d="M530 4720 c-254 -29 -463 -217 -515 -465 -22 -105 -22 -3485 0 -3589
47 -223 242 -416 459 -455 83 -15 4089 -15 4172 0 217 39 412 232 459 455 22
104 22 2895 0 2999 -41 194 -180 356 -372 433 l-68 27 -1250 5 -1250 5 -7 67
c-8 84 -21 124 -67 213 -47 92 -144 189 -237 237 -137 72 -128 71 -719 74
-291 1 -564 -2 -605 -6z m4108 -1013 c23 -15 52 -46 64 -70 l23 -42 0 -1430 0
-1430 -24 -42 c-14 -24 -43 -55 -65 -70 l-41 -28 -2035 0 -2035 0 -41 28 c-22
15 -51 46 -65 70 l-24 42 -3 1408 c-1 927 1 1422 8 1446 15 57 50 102 99 128
l44 23 2027 -2 2026 -3 42 -28z"
          />
          <path
            d="M1900 3339 c-131 -22 -257 -121 -299 -235 -21 -55 -21 -64 -19 -952
l3 -897 24 -49 c32 -65 75 -112 136 -151 106 -67 79 -65 815 -65 736 0 709 -2
815 65 61 39 104 86 136 151 l24 49 0 625 0 626 -35 57 c-68 113 -274 323
-647 660 -144 130 -108 122 -533 124 -201 0 -390 -3 -420 -8z m763 -203 c82
-35 97 -88 97 -338 0 -191 6 -210 69 -228 20 -5 109 -10 198 -10 89 0 173 -4
186 -9 40 -16 99 -73 118 -116 17 -37 19 -79 19 -572 0 -352 -4 -541 -11 -560
-15 -40 -57 -82 -104 -104 -38 -17 -78 -19 -675 -19 -593 0 -638 2 -675 19
-51 23 -102 81 -110 123 -3 18 -4 409 -3 869 l3 836 28 41 c16 22 48 50 70 61
40 20 59 21 400 21 281 0 365 -3 390 -14z"
          />
        </g>
      </svg>
    );
  }
}
