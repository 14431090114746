import React from "react";
import { Snackbar } from "@mui/material";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import { snackbarState } from "../../recoil/states/snackbarState";
import { useRecoilState } from "recoil";


const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const SnackbarComponent = () => {
  const [toastState, setToastState] = useRecoilState(snackbarState);
  const { vertical, horizontal, message, open, severity } = toastState;

  const handleCloseToast = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setToastState({ ...toastState, open: false });
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical, horizontal }}
      autoHideDuration={4000}
      open={open}
      message={message}
      key={vertical + horizontal}
      onClose={handleCloseToast}
    >
      <Alert severity={severity} onClose={handleCloseToast}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default SnackbarComponent;
