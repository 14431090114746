import { SnackbarOrigin } from "@mui/material";
import { AlertColor } from '@mui/material/Alert';
import { atom } from "recoil";

export enum Severity{
  Info = 'info',
  Success = 'success',
  Warning = 'warning',
  Error = 'error'
}

export interface SnackbarState extends SnackbarOrigin {
  open: boolean;
  message: string;
  severity: AlertColor;
}

export const initSnackbarState = (): SnackbarState => ({
  open: false,
  message: "",
  vertical: "bottom",
  horizontal: "center",
  severity: Severity.Info,
});

export const snackbarState = atom({
  key: "snackbarState", // unique ID (with respect to other atoms/selectors)
  default: initSnackbarState(), // default value (aka initial value)
});

