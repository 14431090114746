import React from "react";
import { ErrorOutline} from "@mui/icons-material";
import Box from "@mui/material/Box";

interface IErrorProps {
  hidden?: boolean;
}

export default class Error extends React.Component<IErrorProps> {
  public render() {
    if (this.props.hidden) {
      return "";
    }
    return (
      <Box className="activity-message-error">
        <ErrorOutline fontSize="large" color="inherit" />
      </Box>
    );
  }
}
