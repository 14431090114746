import React from "react";

import "./AdvancedModeRequest.css";

const AdvancedModeRequest = () => (
  <div className="advanced-mode-request">
    <p>Request Advanced mode to enable this feature</p>
    <a href="mailto:support@urbandatalab.net">Contact sales</a>
  </div>
);

export default AdvancedModeRequest;
