class Events {
  private events: Map<string, CallableFunction[]> = new Map<
    string,
    CallableFunction[]
  >();

  public register(event: string, callback: CallableFunction) {
    let callbacks = this.events.get(event);
    if (callbacks) {
      callbacks.push(callback);
    } else {
      callbacks = [callback];
      this.events.set(event, callbacks);
    }
  }

  public unregister(event: string, callback: CallableFunction) {
    if (this.events.has(event)) {
      const callbacks = this.events.get(event);
      if (callbacks) {
        const callbackIndex = callbacks.indexOf(callback);
        callbacks.splice(callbackIndex, 1);
      }
    }
  }

  public fire(event: string, parameters?: any[]) {
    this.events.forEach((callbacks: CallableFunction[], key: string) => {
      if (key === event) {
        if (parameters) {
          callbacks.map((c) => c(...parameters));
        } else {
          callbacks.map((c) => c());
        }
      }
    });
  }
}

export default new Events();
