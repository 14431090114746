import React from "react";
import { SwitchOption } from "..";
import "./Switch.css";

interface ISwitchProps {
  selectedValue: string;
  disabled?: boolean;
  onSelect: (value: string) => void;
}

interface ISwitchState {
  selected: string;
}

export default class Switch extends React.Component<ISwitchProps,
  ISwitchState> {
  constructor(props: ISwitchProps) {
    super(props);
    this.state = {
      selected: this.props.selectedValue,
    };
  }

  public render() {
    return (
      <div className={this.buildSwitchClassName()}>
        <ul>
          {React.Children.map(
            this.props.children,
            (child: any, index: number) => {
              const switchOption = child as SwitchOption;
              return (
                <li className={this.buildOptionClassName(switchOption)}
                    onClick={() => this.optionClicked(switchOption.props.value)}>
                  {switchOption}
                </li>
              );
            },
          )}
        </ul>
      </div>
    );
  }

  private optionClicked(value: string) {
    if (!this.props.disabled) {
      this.props.onSelect(value);
      this.setState({ selected: value });
    }
  }

  private buildSwitchClassName(): string {
    const baseClass = "switch-container";
    const classNames = [baseClass];
    if (this.props.disabled) {
      classNames.push(`${baseClass}-disabled`);
    }
    return classNames.join(" ");
  }

  private buildOptionClassName(option: SwitchOption): string {
    const baseClass = "switch-switchoption";
    const classNames = [baseClass];
    if (option.props.value === this.state.selected) {
      classNames.push(`${baseClass}-selected`);
    }
    if (option.props.value === this.props.selectedValue) {
      classNames.push(`${baseClass}-default`);
    }
    return classNames.join(" ");
  }
}
