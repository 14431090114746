import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

import { selectors, actions } from "../../../store/store";
import { SQMApi } from "../../../util/api";
import { useSetRecoilState } from "recoil";
import { currentInitialCoordinatesState } from "../../../recoil/states/currentInitialCoordinatesState";

const withCheckUser = (Component: any) => {
  const WrappedComponent = (props: any) => {
    const history = useHistory();
    const setUserInitialCoordinates = useSetRecoilState(currentInitialCoordinatesState);
    useEffect(() => {
      const sqmApi = new SQMApi();
      sqmApi
        .getUserProfile()
        .then((data) => {
          props.setUserProfile(data);
          if (data.location) {
            setUserInitialCoordinates({ lat: data.location.lat, lng: data.location.lon });
          }

        })
        .catch(() => {
          localStorage.clear();
          history.push("/login");
        });
      // eslint-disable-next-line
    }, []);

    if (props.profile.username === null) {
      return null;
    }
    return <Component {...props} />;
  };
  return connect(
    (state) => ({
      profile: selectors.user.profile(state),
    }),
    {
      setUserProfile: actions.user.setUserProfile,
    },
  )(WrappedComponent);
};

export default withCheckUser;
