import React from "react";
import { Translation } from "react-i18next";
import { Error, Loading } from "../../icons";
import "./ActivityIndicator.css";
import Box from "@mui/material/Box";

interface IActivityIndicatorProps {
  showActivity: boolean;
  errorMessage?: string;
  loadingMessage?: string;
}

export default class ActivityIndicator extends React.Component<IActivityIndicatorProps> {
  constructor(props: IActivityIndicatorProps) {
    super(props);
    this.state = {
      showActivity: false,
    };
  }

  public render() {
    if (!this.props.showActivity && !this.props.errorMessage) {
      return "";
    }
    return (
      <div className="activity-indicator-container">
        <div className="activity-indicator-inner">
          <div className="activity-indicator-icon">
            <Loading hidden={!this.props.showActivity} />
            <Error hidden={this.props.showActivity} />
          </div>
          <div className="activity-indicator-message">
            <p className="activity-message-info" hidden={!this.props.showActivity}>
              <Translation>
                {
                  (t, { i18n }) => {
                    if (this.props.loadingMessage) {
                      return <Box component="span">{this.props.loadingMessage}</Box>;
                    }
                    return <Box component="span">{t("messages.loading")}</Box>;
                  }
                }
              </Translation>
            </p>
            <p className="activity-message-error" hidden={this.props.showActivity}>
              {this.props.errorMessage}
            </p>
          </div>
        </div>
      </div>
    );
  }
}
