import React from "react";

export default class Export extends React.Component {
  public render() {
    return (
      <svg
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        width="512.256743pt"
        height="511.529429pt"
        viewBox="0 0 512.256743 511.529429"
        preserveAspectRatio="xMidYMid meet"
      >
        <g
          transform="translate(0.128372,512.029429) scale(0.100000,-0.100000)"
          fill="#000000"
          stroke="none"
        >
          <path
            d="M2040 5104 c-201 -43 -372 -202 -441 -409 -11 -32 -15 -218 -19 -890
l-5 -850 -405 -5 c-333 -4 -410 -8 -434 -20 -51 -26 -55 -43 -22 -108 25 -47
163 -190 830 -858 497 -497 826 -818 866 -846 112 -78 155 -84 251 -37 58 29
147 114 882 848 450 449 832 836 848 861 52 78 50 110 -7 140 -24 12 -101 16
-434 20 l-405 5 -5 850 c-4 672 -8 858 -19 890 -70 210 -241 367 -446 410 -99
21 -938 20 -1035 -1z"
          />
          <path
            d="M2 928 c4 -435 5 -455 25 -511 76 -203 201 -325 403 -393 53 -18 128
-19 2130 -19 2002 0 2077 1 2130 19 202 68 327 190 403 393 20 56 21 76 25
511 l3 452 -195 0 -195 0 -3 -422 c-3 -376 -5 -426 -20 -454 -22 -40 -81 -92
-116 -101 -35 -10 -4029 -10 -4064 0 -35 9 -94 61 -116 101 -15 28 -17 78 -20
454 l-3 422 -195 0 -195 0 3 -452z"
          />
        </g>
      </svg>
    );
  }
}
