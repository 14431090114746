import React from "react";
import { useSetRecoilState } from "recoil";
import { currentMetadataDetailsState } from "../../../../recoil/states/currentMetadataDetailsState";
import { IconButton, Tooltip } from "@mui/material";
import { InfoOutlined } from "@mui/icons-material";
import { AttributeConfiguration } from "../../../../util/models";
import { SQMApi } from "../../../../util/api";
import { useTranslation } from "react-i18next";

interface MetadataInfoProps {
  configurations: AttributeConfiguration[],
  currentIndex: number
}

const MetadataInfoButtonComponent = ({ configurations, currentIndex }: MetadataInfoProps) => {
  const setAttributeDetails = useSetRecoilState(currentMetadataDetailsState);
  const [t] = useTranslation();
  const sqmApi = new SQMApi();

  async function metadataDetailsClicked(index: number) {
    const infoAttribute = configurations[index];

    if (infoAttribute) {
      const value = await sqmApi.getAttributeDetails(infoAttribute.attributeKey);
      setAttributeDetails(value);
    }
  }

  return (
    <Tooltip title={<div>{t("metadataDetails.showDetails")}</div>}>
      <IconButton
        style={{ padding: "6px" }}
        onClick={() => metadataDetailsClicked(currentIndex)}
        size="large">
        <InfoOutlined />
      </IconButton>
    </Tooltip>
  );
};

export default MetadataInfoButtonComponent;