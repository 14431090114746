import { atom } from "recoil";


export interface ManagerContentDetailState {
  collapsed: boolean;
  height: number;
}

export const initManagerContentDetailState = (): ManagerContentDetailState => ({
  collapsed: false,
  height: 50
});

export const managerContentDetailState = atom({
  key: "managerContentDetailState", // unique ID (with respect to other atoms/selectors)
  default: initManagerContentDetailState(), // default value (aka initial value)
});
