import React from "react";
import ReactDOM from "react-dom";
import { IAlertButton } from "./IAlertButton";
import { AlertBox } from "../../components/alert";

export default class Alert {
  public text: string = "";
  public buttons: IAlertButton[] = [];
  public data: any;
  public onHide?: () => void;

  public show() {
    const AlertInstance = React.createElement(AlertBox, {
      alert: this,
    });
    ReactDOM.render(
      AlertInstance,
      document.getElementById("alert-stack") as HTMLElement,
    );
  }

  public hide() {
    ReactDOM.unmountComponentAtNode(
      document.getElementById("alert-stack") as HTMLElement,
    );
  }
}
