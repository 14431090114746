import * as React from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { AuthApi } from "../../../util/api";

export default class PrivateRoute extends React.Component<RouteProps, any> {
  public render() {
    const isAuthenticated = new AuthApi().isAuthenticated();
    if (isAuthenticated) {
      return <Route {...this.props} />;
    }
    return <Route render={() => <Redirect to="/login" />} />;
  }
}
