import React from "react";
import Loadable from "react-loadable";

import {AsyncLoadingIndicator} from "../../components/elementar";
import withUser from '../../components/tools/withUser/withUser';

const LoadableComponent = Loadable({
  loader: () => import("./ExportPage"),
  loading: AsyncLoadingIndicator
});

class ExportPageAsync extends React.Component {
  public render() {
    return <LoadableComponent/>;
  }
}

export default withUser(ExportPageAsync)