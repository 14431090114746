import { createAction } from "@reduxjs/toolkit";

import {
  Portfolio,
  Building,
  Property,
  Unit,
  Log,
  Asset,
} from "./manager.types";

const SET_PORTFOLIOS = "manager/SET_PORTFOLIOS";
const SET_PORTFOLIO = "manager/SET_PORTFOLIO";
const SET_PROPERTIES = "manager/SET_PROPERTIES";
const SET_PROPERTY = "manager/SET_PROPERTY";
const SET_BUILDINGS = "manager/SET_BUILDINGS";
const SET_BUILDING = "manager/SET_BUILDING";
const SET_NAVIGATION_BUILDING = "manager/SET_NAVIGATION_BUILDING";
const SET_UNITS = "manager/SET_UNITS";
const SET_UNIT = "manager/SET_UNIT";
const SET_BUILDING_LOCATION_COLUMNS = "manager/SET_BUILDING_LOCATION_COLUMNS";
const SET_UNITS_LOCATION_COLUMNS = "manager/SET_UNITS_LOCATION_COLUMNS";
const SET_PROPERTIES_LOCATION_COLUMNS =
  "manager/SET_PROPERTIES_LOCATION_COLUMNS";
const SET_LOGS = "manager/SET_LOGS";
const SET_ASSETS = "manager/SET_ASSETS";
const SET_MARKERS = "manager/SET_MARKERS";

// eslint-disable-next-line
export default {
  setPortfolios: createAction<Portfolio[], typeof SET_PORTFOLIOS>(
    SET_PORTFOLIOS,
  ),
  setPortfolio: createAction<Portfolio | null, typeof SET_PORTFOLIO>(
    SET_PORTFOLIO,
  ),
  setProperties: createAction<Property[], typeof SET_PROPERTIES>(
    SET_PROPERTIES,
  ),
  setProperty: createAction<Property | null, typeof SET_PROPERTY>(SET_PROPERTY),
  setBuildings: createAction<Building[], typeof SET_BUILDINGS>(SET_BUILDINGS),
  setBuilding: createAction<Building | null, typeof SET_BUILDING>(SET_BUILDING),
  setNavigationBuilding: createAction<Building | null, typeof SET_NAVIGATION_BUILDING>(SET_NAVIGATION_BUILDING),
  setUnits: createAction<Unit[], typeof SET_UNITS>(SET_UNITS),
  setUnit: createAction<Unit | null, typeof SET_UNIT>(SET_UNIT),
  setBuildingsLocationColumns: createAction<string[]>(
    SET_BUILDING_LOCATION_COLUMNS,
  ),
  setUnitsLocationColumns: createAction<string[]>(SET_UNITS_LOCATION_COLUMNS),
  setPropertiesLocationColumns: createAction<string[]>(
    SET_PROPERTIES_LOCATION_COLUMNS,
  ),
  setLogs: createAction<Log[]>(SET_LOGS),
  setAssets: createAction<Asset[]>(SET_ASSETS),
  setMarkers: createAction<Building[]>(SET_MARKERS),
};
