import { atom } from "recoil";
import { AttributeMetadataDetails } from "../../util/models/AttributeMetadata";

export const initCurrentMetadataDetailsState = (): AttributeMetadataDetails => (
  {
    id: -1,
    name: "",
    description: "",
    short_description: null,
    unit: "",
    tags: null,
    main_tag: null,
    min_value: 0,
    max_value: 0,
    standard_deviation: 0,
    mean: 0,
    year: null,
    coverage_general: null,
    data_last_update: null,
    data_processor: null,
    data_version: null,
    epsg_code: 0,
    source_provider: null,
    source_provider_link: "",
    value_formatter: undefined,
  }
);


export const currentMetadataDetailsState = atom({
  key: "currentMetadataDetailsState", // unique ID (with respect to other atoms/selectors)
  default: initCurrentMetadataDetailsState(), // default value (aka initial value)
});
