import React, { useEffect, useState } from "react";
import { Alert, AlertButton } from "../../../util/alert";
import { AttributeConfiguration, AttributeMetadata, UserConfiguration } from "../../../util/models";
import { filter, find, propEq, sort } from "ramda";
import { SQMApi } from "../../../util/api";
import _ from "lodash";
import { E, Events } from "../../../util/events";
import "./AttributeRating.component.css";
import Mixpanel, {
  MIXPANEL_EVENTNAME_ADDED_COMPOSITION_LAYER,
  MIXPANEL_EVENTNAME_DELETED_COMPOSITION, MIXPANEL_EVENTNAME_DELETED_SUBRATING,
  MIXPANEL_EVENTNAME_LOADED_COMPOSITION,
  MIXPANEL_EVENTNAME_LOADED_SUBRATING, MIXPANEL_EVENTNAME_SAVED_COMPOSITION, MIXPANEL_EVENTNAME_SAVED_SUBRATING,
  MIXPANEL_PAGENAME_SCOUT,
} from "../../../util/Mixpanel";
import { Translation } from "react-i18next";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
  Paper,
  TextField,
  Tooltip,
} from "@mui/material";
import { DeleteOutlined, ExpandMore, InfoOutlined, PublishOutlined } from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import { BasePanel, PanelItem } from "../index";
import { Slider } from "../../elementar";
import Button from "@mui/material/Button";
import { useRecoilState, useSetRecoilState } from "recoil";
import { currentAttributeTemplateState } from "../../../recoil/states/currentAttributeTemplateState";
import { currentAttributeConfigurationsState } from "../../../recoil/states/currentAttributeConfigurationsState";
import { currentAttributeCompositeState } from "../../../recoil/states/currentAttributeCompositeState";
import {
  currentLayerConfigurationState,
  defaultLayerConfigurationForComposite, initCurrentLayerConfigurationState, LAYER_CONFIGURATION_SCALE_FOCAL,
} from "../../../recoil/states/currentLayerConfigurationState";
import { Severity, snackbarState } from "../../../recoil/states/snackbarState";

interface IProps {
  attributeConfigurations: AttributeConfiguration[];
  availableAttributes: AttributeMetadata[];
}

const AttributeRatingComponent: React.FC<IProps> = ({ availableAttributes, attributeConfigurations }) => {
  // const reallyDeleteAlert = new Alert();
  const overrideAlert = new Alert();

  const [configurationName, setConfigurationName] = useState<string>("");
  const [savedAttributeTemplates, setSavedAttributeTemplates] = useState<UserConfiguration[]>([]);
  const [userCompositionDraft, setUserCompositionDraft] = useState<any[]>([]);
  const [userCompositions, setUserCompositions] = useState<any[]>([]);

  const setAttributeConfigurations = useSetRecoilState(currentAttributeConfigurationsState);
  const setAttributeComposite = useSetRecoilState(currentAttributeCompositeState);
  const setCurrentLayerConfiguration = useSetRecoilState(currentLayerConfigurationState);
  const [currentSnackbarState, setCurrentSnackbarState] = useRecoilState(snackbarState);

  const [currentAttributeTemplates, setCurrentAttributeTemplates] = useRecoilState(currentAttributeTemplateState);

  useEffect(() => {
    // createReallyDeleteAlert();
    createOverrideAlert();

    loadUserRatings();
    // eslint-disable-next-line
  }, []);

  function sortCompositions(compositions: []) {
    return sort((a: any, b: any) => a.id - b.id, compositions);
  }

  function getAttributeNameForId(id: number): string {
    const matchingAttribute = availableAttributes.find(
      (attributeMetadata) => {
        return attributeMetadata.pk === id;
      },
    );
    if (matchingAttribute !== undefined) {
      return matchingAttribute.fields.attributeName;
    }
    return "?";
  }

  async function loadUserRatings() {
    const sqmApi = new SQMApi();
    let userConfigurations = await sqmApi.getMyConfigurations();
    userConfigurations = _.orderBy(userConfigurations, ["name"]);
    const userCompositions = await sqmApi.getUserCompositions();

    setSavedAttributeTemplates(userConfigurations);
    setUserCompositions(sortCompositions(userCompositions));
  }

  async function applySavedTemplate(userConfiguration: UserConfiguration) {
    Events.fire(E.APPLY_TEMPLATE, [userConfiguration.configurations]);
    Events.fire(E.ADD_TEMPLATE, [userConfiguration]);

    const filtered = filter(
      (configuration) => configuration.name !== userConfiguration.name,
      currentAttributeTemplates,
    );
    const newAttributeTemplates = [...filtered, userConfiguration];

    setCurrentAttributeTemplates(newAttributeTemplates);
    setAttributeConfigurations(userConfiguration.configurations);

    if (userConfiguration.configurations.length === 1) {
      setCurrentLayerConfiguration({
        attributeMetadataKey: userConfiguration.configurations[0].attributeKey!,
        rating: false,
        scale: LAYER_CONFIGURATION_SCALE_FOCAL,
      });
    } else {
      setCurrentLayerConfiguration(initCurrentLayerConfigurationState());
    }

    Mixpanel.getInstance().trackEvent(MIXPANEL_EVENTNAME_LOADED_SUBRATING, {
      "Component": MIXPANEL_PAGENAME_SCOUT,
      "Sub Rating Name": userConfiguration.name,
    });
  }

  async function applyComposition(composition: any) {
    //  Events.fire(E.APPLY_COMPOSITION, [composition]);

    setAttributeComposite(composition);
    setCurrentLayerConfiguration(defaultLayerConfigurationForComposite());

    Mixpanel.getInstance().trackEvent(MIXPANEL_EVENTNAME_LOADED_COMPOSITION, {
      "Component": MIXPANEL_PAGENAME_SCOUT,
      "Composite Name": composition.name,
    });
  }

  async function removeComposition(composition: any) {
    const sqmApi = new SQMApi();
    const alert = new Alert();
    alert.buttons.push(
      new AlertButton("Delete", async () => {
        await sqmApi.removeUserCompositions(composition.id);
        const userCompositions = await sqmApi.getUserCompositions();
        setUserCompositions(sortCompositions(userCompositions));

        Mixpanel.getInstance().trackEvent(MIXPANEL_EVENTNAME_DELETED_COMPOSITION, {
          "Component": MIXPANEL_PAGENAME_SCOUT,
          "Composite Name": composition.name,
        });

        alert.hide();
      }),
    );
    alert.text = `Do you really want to delete the composite "${composition.name}"?`;
    alert.show();
  }

  async function deleteTemplate(userConfiguration: UserConfiguration) {
    const alert = new Alert();
    alert.buttons.push(
      new AlertButton("Delete", (source: Alert) =>
        reallyDeleteSubRating(source),
      ),
    );
    alert.text = `Do you really want to delete the template "${userConfiguration.name}"?`;
    alert.data = userConfiguration;
    alert.show();
  }

  // function createReallyDeleteAlert() {
  //   reallyDeleteAlert.buttons.push(
  //     new AlertButton("Delete", (source: Alert) =>
  //       reallyDeleteSubRating(source),
  //     ),
  //   );
  // }

  function createOverrideAlert() {
    overrideAlert.buttons.push(
      new AlertButton("Override", (source: Alert) =>
        reallyOverrideSubRating(source),
      ),
    );
  }

  function addConfigurationToCompositionDraft(userConfiguration: any) {
    Mixpanel.getInstance().trackEvent(MIXPANEL_EVENTNAME_ADDED_COMPOSITION_LAYER, {
      "Component": MIXPANEL_PAGENAME_SCOUT,
      "Composite Name": userConfiguration.name,
    });

    const newConfiguration = find(propEq("name", userConfiguration.name))(
      userCompositionDraft,
    );

    if (newConfiguration) {
      setUserCompositionDraft(filter(
        ({ name }) => name !== userConfiguration.name,
        userCompositionDraft,
      ));
      return;
    }

    setUserCompositionDraft([
      ...userCompositionDraft,
      userConfiguration,
    ]);

  }

  // function deleteConfiguration(userConfiguration: UserConfiguration) {
  //   reallyDeleteAlert.text = `Do you really want to delete the template "${userConfiguration.name}"?`;
  //   reallyDeleteAlert.data = userConfiguration;
  //   reallyDeleteAlert.show();
  // }

  async function reallyOverrideSubRating(alert: Alert) {
    const sqmApi = new SQMApi();
    await sqmApi.saveMyConfiguration(
      configurationName,
      attributeConfigurations,
    );

    Mixpanel.getInstance().trackEvent(MIXPANEL_EVENTNAME_SAVED_SUBRATING, {
      "Component": MIXPANEL_PAGENAME_SCOUT,
      "Sub Rating Name": configurationName,
    });
    alert.hide();
    loadUserRatings();
    setConfigurationName("");
  }

  async function reallyDeleteSubRating(alert: Alert) {
    const userConfiguration = alert.data as UserConfiguration;
    const sqmApi = new SQMApi();
    await sqmApi
      .deleteMyConfiguration(userConfiguration.id)
      .then(() => {
        loadUserRatings();

        Mixpanel.getInstance().trackEvent(MIXPANEL_EVENTNAME_DELETED_SUBRATING, {
          "Component": MIXPANEL_PAGENAME_SCOUT,
          "Sub Rating Name": userConfiguration.name,
        });

        alert.hide();
      })
      .catch((e) => {
        alert.hide();
        const errorAlert = new Alert();
        errorAlert.text = e.message;
        errorAlert.show();
      });

  }

  function renderPanelItemButtons(
    userConfiguration: UserConfiguration,
  ): React.ReactNode[] {
    const deleteButton = (
      <Translation key={"deleteActionRenderItem"}>
        {
          (t, { i18n }) =>
            <Tooltip title={<div>{t("contentActions.deleteAction")}</div>}>
              <IconButton
                style={{ padding: "6px" }}
                onClick={() => deleteTemplate(userConfiguration)}
                size="large">
                <DeleteOutlined />
              </IconButton>
            </Tooltip>
        }
      </Translation>
    );
    const selectButton = (
      <Translation key={"loadRatingsRenderItem"}>
        {
          (t, { i18n }) =>
            <Tooltip title={<div>{t("ratings.loadRating")}</div>}>

              <IconButton
                style={{ padding: "6px" }}
                onClick={() => applySavedTemplate(userConfiguration)}
                size="large">
                <PublishOutlined />
              </IconButton>
            </Tooltip>
        }
      </Translation>
    );
    return [selectButton, deleteButton];
  }

  async function saveUserComposition(e: any) {
    e.preventDefault();
    if (e.target["composition-name"].value === "") {
      return alert("Missing composition name");
    }
    const compositionName = e.target["composition-name"].value;

    const sqmApi = new SQMApi();
    try {
      await sqmApi.saveUserCompositions(
        e.target["composition-name"].value,
        userCompositionDraft.map((config) => ({
          configuration_id: config.id,
          weight: config.weight,
        })),
      );

      Mixpanel.getInstance().trackEvent(MIXPANEL_EVENTNAME_SAVED_COMPOSITION, {
        "Component": MIXPANEL_PAGENAME_SCOUT,
        "Composite Name": compositionName,
      });

      setCurrentSnackbarState({
        ...currentSnackbarState,
        open: true,
        severity: Severity.Success,
        message: `Composition "${compositionName}" successfully saved.`,
      });

      const userCompositions = await sqmApi.getUserCompositions();
      setUserCompositions(sortCompositions(userCompositions));
      setUserCompositionDraft([]);
    } catch (e) {

    }
  }

  function updateDraftCompositionWeight(value: number, id: string | number) {
    setUserCompositionDraft(userCompositionDraft.map((config) => {
      if (config.id === id) {
        return {
          ...config,
          weight: value,
        };
      }

      return config;
    }));
  }

  async function updateCompositionWeight(
    value: number,
    composition: any,
    id: string | number,
  ) {
    const updatedComposition = {
      ...composition,
      config: composition.config.map((config: any) => {
        if (config.configuration_id === id) {
          return {
            ...config,
            weight: value,
          };
        }

        return config;
      }),
    };

    // Events.fire(E.APPLY_COMPOSITION, [updatedComposition]);

    const sqmApi = new SQMApi();
    await sqmApi.updateUserComposition(composition.id, updatedComposition);
    const userCompositions = await sqmApi.getUserCompositions();
    setUserCompositions(sortCompositions(userCompositions));

    setAttributeComposite(updatedComposition);
    setCurrentLayerConfiguration(defaultLayerConfigurationForComposite());

  }


  function renderCompositionDraft() {
    if (userCompositionDraft.length <= 0) return null;
    return (
      <form
        className="composition-draft-wrapper"
        onSubmit={saveUserComposition}
      >
        <Paper elevation={3} style={{ marginLeft: 8, marginTop: 16, padding: 16 }}>
          <Typography style={{ marginBottom: 16 }} variant={"h6"}>Create new composition from:</Typography>
          {userCompositionDraft.map((config, key) => (
            <PanelItem title={config.name} key={key}>
              <p>Weight</p>
              <Slider
                max={1}
                min={0.01}
                customStepSize={0.01}
                values={[config.weight]}
                onChangeEnded={(values: readonly number[]) => {
                  updateDraftCompositionWeight(values[0], config.id);
                }}
              />
            </PanelItem>
          ))}

          <div className="input-container">
            <TextField autoFocus variant={"outlined"} name={"composition-name"} label={"Composition Name"}></TextField>
          </div>
          <div className="composition-draft-wrapper__buttons">

            <Button color="secondary" variant="outlined" onClick={() =>
              setUserCompositionDraft([])
            }>
              Clear
            </Button>
            <Button variant="contained" color="primary" style={{ marginLeft: 8 }} type={"submit"}>
              Save
            </Button>
          </div>
        </Paper>

      </form>
    );
  }

  function getConfigName(id: number) {
    const config = savedAttributeTemplates.find(
      (config) => config.id === id,
    );
    return config ? config.name : null;
  }

  function renderUserTemplates() {
    if (savedAttributeTemplates.length <= 0)
      return <p>You don't have any templates</p>;
    return (
      <div className="user-template-wrapper" style={{ width: "100%" }}>
        {savedAttributeTemplates.map((userConfiguration, key) => (
          <PanelItem key={key}
                     title={userConfiguration.name}
                     buttons={renderPanelItemButtons(userConfiguration)}
          >
            <>
              <ul className="selected-attributes-list">
                {userConfiguration.configurations.map(
                  (attributeConfig, index) => (
                    <li key={index}>
                      {getAttributeNameForId(
                        attributeConfig.attributeKey!,
                      )}
                    </li>
                  ),
                )}
              </ul>
              <button
                className="button button-composition"
                onClick={() =>
                  addConfigurationToCompositionDraft({
                    ...userConfiguration,
                    weight: 0.5,
                  })
                }
              >
                Add to composition
              </button>
            </>
          </PanelItem>
        ))}
      </div>
    );
  }

  function renderUserCompositions() {
    if (userCompositions.length <= 0)
      return <p>You don't have any compositions</p>;
    return (
      <div className="user-compositions-wrapper" style={{ width: "100%" }}>
        {userCompositions.map((composition, key) => (
          <PanelItem
            key={key}
            title={composition.name}
            buttons={[
              <Translation key={"ratings.loadRating"}>
                {
                  (t, { i18n }) =>
                    <Tooltip title={<div>{t("ratings.loadRating")}</div>}>
                      <IconButton
                        style={{ padding: "6px" }}
                        onClick={() => applyComposition(composition)}
                        size="large">
                        <PublishOutlined />
                      </IconButton>
                    </Tooltip>
                }
              </Translation>,
              <Translation key={"contentActions.deleteAction"}>
                {
                  (t, { i18n }) =>
                    <Tooltip title={<div>{t("contentActions.deleteAction")}</div>}>
                      <IconButton
                        style={{ padding: "6px" }}
                        onClick={() => removeComposition(composition)}
                        size="large">
                        <DeleteOutlined />
                      </IconButton>
                    </Tooltip>
                }
              </Translation>,
            ]}
          >
            <ul>
              {composition.config.map((config: any, key: any) => (
                <li key={key}>
                  <p>{getConfigName(config.configuration_id)}</p>
                  <Slider
                    max={1}
                    min={0.01}
                    customStepSize={0.01}
                    values={[config.weight]}
                    onChangeEnded={(values: readonly number[]) => {
                      updateCompositionWeight(
                        values[0],
                        composition,
                        config.configuration_id,
                      );
                    }}
                  />
                </li>
              ))}
            </ul>
          </PanelItem>
        ))}
      </div>
    );
  }

  return (
    <BasePanel>
      {renderCompositionDraft()}

      <Accordion style={{ marginTop: "16px", marginLeft: "8px" }}>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Translation>
            {
              (t, { i18n }) =>

                <React.Fragment>
                  <Tooltip title={<div>{t("attributeHandling.infoTooltipTemplates")}</div>}>
                    <IconButton style={{ padding: "0", marginRight: "8px" }} size="large">
                      <InfoOutlined />
                    </IconButton>
                  </Tooltip>
                  <Typography>{t("attributeHandling.savedTemplates")}</Typography>
                </React.Fragment>

            }
          </Translation>

        </AccordionSummary>
        <AccordionDetails>
          {renderUserTemplates()}
        </AccordionDetails>
      </Accordion>

      <Accordion style={{ marginTop: "16px", marginLeft: "8px" }}>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Translation>
            {
              (t, { i18n }) =>

                <React.Fragment>
                  <Tooltip title={<div>{t("attributeHandling.infoTooltipCompositions")}</div>}>
                    <IconButton style={{ padding: "0", marginRight: "8px" }} size="large">
                      <InfoOutlined />
                    </IconButton>
                  </Tooltip>
                  <Typography>{t("attributeHandling.savedCompositions")}</Typography>
                </React.Fragment>

            }
          </Translation>

        </AccordionSummary>
        <AccordionDetails>
          {renderUserCompositions()}
        </AccordionDetails>
      </Accordion>
    </BasePanel>
  );
};

export default AttributeRatingComponent;
