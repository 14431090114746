import React from "react";
import "./BasePanel.css";

interface IBasePanelProps {
  className?: string;
}

export default class BasePanel extends React.Component<IBasePanelProps> {
  public render() {
    return <div className={this.buildClassName()}>{this.props.children}</div>;
  }

  private buildClassName(): string {
    const baseClassName = "panel-base";
    const classNames = [baseClassName];
    if (this.props.className) {
      classNames.push(this.props.className);
    }
    return classNames.join(" ");
  }
}
