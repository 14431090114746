import React from "react";

export default class List extends React.Component {
  public render() {
    return (
      <svg
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 491.940515 433.268221"
        preserveAspectRatio="xMidYMid meet"
      >
        <g
          transform="translate(0,472.768221) scale(0.09,-0.09)"
          fill="#000000"
          stroke="none"
        >
          <path
            d="M195 4726 c-5 -3 -27 -7 -48 -11 -49 -8 -111 -63 -132 -119 -14 -36
     -16 -80 -13 -283 l3 -241 30 -43 c17 -23 50 -53 75 -66 44 -24 48 -24 296 -21
     l251 3 40 28 c22 15 50 46 64 70 23 40 24 51 27 254 4 244 -5 307 -49 357 -57
     65 -80 70 -319 73 -118 1 -219 1 -225 -1z"
          />
          <path
            d="M1355 4525 c-5 -2 -22 -6 -38 -9 -39 -9 -104 -71 -118 -114 -32 -97
     7 -199 93 -243 36 -18 89 -19 1755 -19 1651 0 1720 1 1758 19 167 77 145 311
     -34 361 -33 9 -3389 14 -3416 5z"
          />
          <path
            d="M109 3520 c-47 -25 -86 -71 -99 -118 -13 -49 -13 -456 1 -505 14 -51
     61 -102 112 -122 36 -13 81 -16 288 -13 l246 3 41 28 c23 15 52 46 64 70 21
     39 23 57 26 254 4 235 -4 298 -45 351 -50 66 -78 72 -353 71 -222 0 -248 -2
     -281 -19z"
          />
          <path
            d="M1337 3340 c-83 -16 -151 -103 -148 -191 3 -84 35 -135 111 -175 34
     -18 95 -19 1750 -19 l1715 0 44 23 c146 77 146 271 1 348 -34 18 -95 19 -1735
     20 -935 1 -1717 -1 -1738 -6z"
          />
          <path
            d="M115 2341 c-50 -22 -91 -69 -105 -119 -13 -48 -13 -456 0 -504 14
     -48 53 -94 101 -118 35 -18 59 -20 281 -20 273 0 301 6 351 72 41 53 49 116
     45 351 -3 197 -5 215 -26 254 -12 24 -41 55 -64 70 l-41 28 -251 3 c-227 2
     -255 0 -291 -17z"
          />
          <path
            d="M1350 2161 c-52 -11 -96 -37 -123 -73 -32 -42 -41 -73 -38 -126 4
     -81 33 -126 106 -165 l40 -22 1720 0 1720 0 42 24 c87 50 126 155 89 242 -15
     36 -65 88 -106 110 -21 12 -306 14 -1720 15 -932 1 -1711 -1 -1730 -5z"
          />
          <path
            d="M110 1157 c-25 -13 -58 -43 -75 -66 l-30 -43 -3 -241 c-3 -203 -1
     -247 13 -283 20 -54 82 -110 132 -121 21 -4 139 -8 263 -8 251 0 271 4 329 71
     44 50 53 113 49 357 -3 203 -4 214 -27 254 -14 24 -42 55 -64 70 l-40 28 -251
     3 c-248 3 -252 3 -296 -21z"
          />
          <path
            d="M1293 959 c-108 -53 -140 -205 -61 -295 67 -76 -107 -69 1823 -69
     l1730 0 41 27 c131 85 121 267 -18 339 -36 18 -86 19 -1755 19 -1718 0 -1718
     0 -1760 -21z"
          />
        </g>
      </svg>
    );
  }
}
