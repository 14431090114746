import React from "react";

export default class Edit extends React.Component {
  public render() {
    return (
      <svg
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        width="508.988476pt"
        height="509.892024pt"
        viewBox="0 0 508.988476 509.892024"
        preserveAspectRatio="xMidYMid meet"
      >
        <g
          transform="translate(-0.101434,511.892024) scale(0.100000,-0.100000)"
          fill="#000000"
          stroke="none"
        >
          <path
            d="M505 5113 c-44 -6 -123 -32 -180 -60 -130 -65 -232 -179 -287 -323
l-33 -85 -3 -1852 c-3 -2057 -7 -1928 64 -2073 48 -97 160 -210 254 -257 141
-70 81 -67 1149 -71 530 -1 968 1 972 5 4 4 31 87 59 183 28 96 53 183 56 193
5 16 -47 17 -1003 17 l-1009 0 -44 23 c-49 24 -84 69 -100 126 -6 23 -10 621
-10 1714 l0 1677 1970 0 1970 0 0 -826 c0 -782 1 -826 18 -821 120 40 164 48
247 48 50 -1 99 -2 110 -3 20 -3 20 3 20 937 0 1044 3 992 -67 1135 -47 94
-161 206 -258 254 -144 70 -17 66 -2040 64 -1004 0 -1838 -3 -1855 -5z"
          />
          <path d="M990 3345 l0 -195 195 0 195 0 0 195 0 195 -195 0 -195 0 0 -195z" />
          <path d="M1770 3345 l0 -195 985 0 985 0 0 195 0 195 -985 0 -985 0 0 -195z" />
          <path d="M990 2560 l0 -200 193 2 192 3 0 195 0 195 -192 3 -193 2 0 -200z" />
          <path
            d="M1774 2747 c-2 -7 -3 -96 -2 -197 l3 -185 983 -3 982 -2 0 200 0 200
-980 0 c-788 0 -982 -3 -986 -13z"
          />
          <path
            d="M4495 2320 c-22 -11 -97 -77 -167 -147 l-127 -128 292 -298 c161
-164 297 -298 303 -297 20 0 266 261 281 298 19 46 19 119 -1 168 -22 52 -345
379 -401 405 -53 25 -129 24 -180 -1z"
          />
          <path d="M990 1775 l0 -195 195 0 195 0 0 195 0 195 -195 0 -195 0 0 -195z" />
          <path
            d="M1770 1775 l0 -195 703 0 702 0 195 195 195 195 -898 0 -897 0 0
-195z"
          />
          <path
            d="M3497 1357 l-498 -492 -110 -375 c-60 -206 -109 -383 -109 -393 0
-25 54 -77 80 -77 11 0 188 49 393 110 l371 109 496 496 495 495 -310 310
-310 309 -498 -492z m-173 -633 c66 -13 123 -28 127 -32 12 -13 61 -294 52
-302 -4 -4 -93 -33 -198 -64 l-190 -56 -42 43 -42 42 40 135 c89 303 77 273
107 265 14 -3 80 -17 146 -31z"
          />
        </g>
      </svg>
    );
  }
}
