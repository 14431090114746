import React from "react";
import "./ToolTab.css";

interface IToolTabProps {
  name: string;
  isDisabled?: boolean;
  className?: string;
}

export default class ToolTab extends React.Component<IToolTabProps> {
  public render() {
    return (
      <div className={`tab ${this.props.isDisabled ? "tab--disable" : ""} ${this.props.className}`}>
        <div className="tab-icon">{this.props.children}</div>
        <div className="tab-text">{this.props.name}</div>
      </div>
    );
  }
}
