import { createAction } from "@reduxjs/toolkit";
import { PositionInformation } from "./user.types";

export const SET_USER_PROFILE = "user/SET_USER_PROFILE";
export const SET_USER_POSITION_INFORMATION = "user/SET_USER_POSITION_INFORMATION";
export const SET_USER_CURRENT_ZOOM = "user/SET_USER_CURRENT_ZOOM";
export const CLEAR_USER_PROFILE = "user/CLEAR_USER_PROFILE";

// eslint-disable-next-line
export default {
  setUserProfile: createAction(SET_USER_PROFILE),
  setUserPositionInformation: createAction<PositionInformation, typeof SET_USER_POSITION_INFORMATION>(SET_USER_POSITION_INFORMATION),
  setUserCurrentZoom: createAction<number, typeof SET_USER_CURRENT_ZOOM>(SET_USER_CURRENT_ZOOM),
  clearUserProfile: createAction(CLEAR_USER_PROFILE),
};
