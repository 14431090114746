import React from "react";

export default class Visualize extends React.Component {
  public render() {
    return (
      <svg
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        width="510.049425pt"
        height="511.281283pt"
        viewBox="0 0 510.049425 511.281283"
        preserveAspectRatio="xMidYMid meet"
      >
        <g
          transform="translate(-1.928934,511.929826) scale(0.100000,-0.100000)"
          fill="#000000"
          stroke="none"
        >
          <path
            d="M2329 5105 c-38 -13 -2216 -1678 -2257 -1724 -49 -55 -66 -142 -41
-208 13 -35 55 -80 92 -99 15 -8 193 -58 395 -110 202 -52 375 -98 384 -102
13 -5 -76 -78 -369 -302 -438 -335 -487 -376 -504 -427 -28 -85 8 -191 79
-233 19 -11 200 -63 404 -116 204 -53 379 -99 389 -103 15 -5 -69 -73 -373
-306 -436 -333 -475 -366 -494 -412 -34 -80 -3 -194 65 -239 49 -32 2678 -715
2761 -717 43 -1 65 4 96 23 33 20 2012 1864 2103 1960 32 34 61 98 61 138 0
78 -63 162 -139 186 -25 8 -188 38 -361 67 -173 29 -319 55 -323 59 -5 4 168
173 383 375 216 202 400 381 411 398 25 43 34 108 19 152 -15 44 -62 100 -102
121 -16 9 -185 42 -375 74 -190 33 -347 61 -348 62 -1 2 171 165 383 363 212
198 398 377 413 398 38 53 46 113 23 178 -21 58 -54 90 -116 113 -44 16 -2557
447 -2600 445 -12 0 -39 -6 -59 -14z m1886 -1870 c149 -25 274 -47 279 -48 10
-3 -1672 -1581 -1690 -1585 -11 -3 -2144 544 -2151 552 -5 5 746 576 759 576
7 0 326 -81 707 -180 441 -114 713 -180 744 -180 27 0 64 7 82 16 18 8 244
213 502 455 257 241 475 439 483 439 9 0 137 -20 285 -45z m-2 -1182 c152 -26
277 -50 277 -54 0 -4 -379 -362 -841 -796 -526 -492 -848 -787 -858 -785 -43
9 -2108 544 -2123 550 -14 5 70 74 354 292 204 157 376 286 382 288 6 1 317
-77 691 -174 374 -97 705 -179 735 -184 104 -14 94 -21 613 466 259 244 477
443 482 444 6 0 135 -21 288 -47z"
          />
        </g>
      </svg>
    );
  }
}
