import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { Chip, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { AttributeConfiguration, AttributeMetadata, UserConfiguration } from "../../../util/models";
import { TransitionProps } from "@mui/material/transitions";
import Slide from "@mui/material/Slide";
import Box from "@mui/material/Box";
import styles from "./SaveAttributeConfiguration.module.css";
import Typography from "@mui/material/Typography";
import { SQMApi } from "../../../util/api";
import Mixpanel, { MIXPANEL_EVENTNAME_SAVED_SUBRATING, MIXPANEL_PAGENAME_SCOUT } from "../../../util/Mixpanel";
import { useRecoilState } from "recoil";
import { Severity, snackbarState } from "../../../recoil/states/snackbarState";

export interface SaveAttributeConfigurationProps {
  isVisible: boolean,
  currentAttributeConfigurations: AttributeConfiguration[],
  availableAttributes: AttributeMetadata[];
  closeRequest: () => any
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const SaveAttributeConfigurationComponent = (props: SaveAttributeConfigurationProps) => {
  const { isVisible, closeRequest, currentAttributeConfigurations, availableAttributes } = props;
  const { t } = useTranslation();
  const [templateName, setTemplateName] = useState("");
  const [templateNameDuplicate, setTemplateNameDuplicate] = useState(false);
  const [templateErrorText, setTemplateErrorText] = useState("");
  const [userConfigurations, setUserConfigurations] = useState<UserConfiguration[]>([]);

  const [currentSnackbarState, setCurrentSnackbarState] = useRecoilState(snackbarState);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    clearTextFieldErrorHandling();
    setTemplateName(event.target.value);
  };

  function clearTextFieldErrorHandling() {
    setTemplateErrorText("");
    setTemplateNameDuplicate(false);
  }

  async function loadUserConfigurations() {
    const sqmApi = new SQMApi();
    let userConfigurations = await sqmApi.getMyConfigurations();
    setUserConfigurations(userConfigurations);
  }

  async function saveTemplate() {

    const alreadyDefinedConfig = userConfigurations.find(
      (userConfig: UserConfiguration) => {
        return userConfig.name === templateName;
      },
    );

    if (alreadyDefinedConfig) {
      setTemplateNameDuplicate(true);
      setTemplateErrorText(t("dialogs.saveAttributeConfiguration.errorText", { templateName: templateName }));
    } else {
      const sqmApi = new SQMApi();
      await sqmApi.saveMyConfiguration(
        templateName,
        currentAttributeConfigurations,
      );

      Mixpanel.getInstance().trackEvent(MIXPANEL_EVENTNAME_SAVED_SUBRATING, {
        "Component": MIXPANEL_PAGENAME_SCOUT,
        "Sub Rating Name": templateName,
      });

      setCurrentSnackbarState({
        ...currentSnackbarState,
        open: true,
        severity: Severity.Success,
        message: t("dialogs.saveAttributeConfiguration.notificationSaveSuccess", { templateName: templateName }),
      });

      setTemplateName("");
      clearTextFieldErrorHandling();
      closeRequest();
    }


  }

  useEffect(() => {
    if (isVisible) {
      loadUserConfigurations();
    }
  }, [isVisible]);

  return (
    <Dialog open={isVisible} TransitionComponent={Transition} onClose={closeRequest}>
      <DialogTitle id="alert-dialog-slide-title">{t("dialogs.saveAttributeConfiguration.title")}</DialogTitle>
      <DialogContent style={{ width: 400 }}>
        <Box marginBottom={3}>
          <Box marginBottom={2}><Typography
            variant={"body2"}>{t("dialogs.saveAttributeConfiguration.selectedAttributes")}</Typography></Box>
          {
            currentAttributeConfigurations.map((attribute: AttributeConfiguration, index) => {
              const attributeMetadata = availableAttributes.find(
                (value) => attribute.attributeKey === value.pk,
              );
              if (attributeMetadata) {
                return (<Chip className={styles.chip} key={index} size="small" variant="outlined"
                              label={attributeMetadata.fields.attributeName} />);
              }
              return <div key={index}></div>;
            })
          }

        </Box>

        <Box marginBottom={1}>
          <Box marginBottom={2}><Typography
            variant={"body2"}>{t("dialogs.saveAttributeConfiguration.newTemplateName")}</Typography></Box>
          <TextField error={templateNameDuplicate} autoFocus id="standard-uncontrolled" value={templateName}
                     helperText={templateErrorText}
                     onChange={handleChange} label={t("dialogs.saveAttributeConfiguration.textBoxPlaceholder")} />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" variant="outlined" onClick={() => {
          setTemplateName("");
          clearTextFieldErrorHandling();
          props.closeRequest();
        }}>
          {t("global.cancel")}
        </Button>
        <Button variant="contained" color="primary" disabled={templateName === ""} onClick={saveTemplate}>
          {t("global.save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SaveAttributeConfigurationComponent;
