import React from "react";
import { connect } from "react-redux";
import { withRouter, RouteComponentProps } from "react-router-dom";
import queryString from "query-string";

import ContentData from "./Content/ContentData";
import { selectors } from "../../../store/store";
import { ObjectFileType } from "../../../store/modules/profiler/profiler.types";
import { Loading } from "../../icons";
import { UserObjectType } from "../../../store/modules/profiler/profiler.types";
import { FILES_NAME } from "../../../store/modules/profiler/profiler.selectors";

import "./ProfilerContent.css";
import BoundariesComponent from "./Boundaries/Boundaries.component";
import { useSetRecoilState } from "recoil";
import { currentObjectFilesState } from "../../../recoil/states/currentObjectState";

interface ProfilerContentProps extends RouteComponentProps {
  profilerFiles: ObjectFileType[];
  profilerIsFetching: boolean;
  profilerObject: UserObjectType;
}

const ProfilerContent = ({
  location,
  profilerFiles,
  profilerIsFetching,
  profilerObject,
}: ProfilerContentProps) => {
  const parsedQuery = queryString.parse(location.search);

  const setObjectFiles = useSetRecoilState(currentObjectFilesState);

  const getContent = (path: string) => {
    console.log(`contentProfiler: ${path}`);
    switch (path) {
      case "economy":
        return (
          <ContentData
            title="Economy"
            tableNames={FILES_NAME.ECONOMY.TABLE}
            graphName={FILES_NAME.ECONOMY.GRAPH}
            slug="economy"
          />
        );
      case "population":
        return (
          <ContentData
            title="Population"
            tableNames={FILES_NAME.POPULATION.TABLE}
            graphName={FILES_NAME.POPULATION.GRAPH}
            slug="population"
          />
        );
      case "building-stock":
        return (
          <ContentData
            title="Building stock"
            tableNames={FILES_NAME.BUILDING_STOCK.TABLE}
            graphName={FILES_NAME.BUILDING_STOCK.GRAPH}
            slug="building_stock"
          />
        );
      case "rental-market":
        return (
          <ContentData
            title="Rental market"
            tableNames={FILES_NAME.RENTAL_MARKET.TABLE}
            graphName={FILES_NAME.RENTAL_MARKET.GRAPH}
            slug="rental_market"
          />
        );
      case "buying-market":
        return (
          <ContentData
            title="Buying market"
            tableNames={FILES_NAME.BUYING_MARKET.TABLE}
            graphName={FILES_NAME.BUYING_MARKET.GRAPH}
            slug="buying_market"
          />
        );
      default:
        return (
          <BoundariesComponent
            geoJsonElements={FILES_NAME.BOUNDERIES.TABLE}
          />
        );
    }
  };

  if (profilerIsFetching) {
    return (
      <div className="profiler__nodata">
        <div>
          <Loading />
          <p>Loading...</p>
        </div>
      </div>
    );
  }

  setObjectFiles(profilerFiles);
  if (profilerFiles.length === 0 && !profilerIsFetching) {
    return (
      <div className="profiler__nodata">
        <div className="highlighted">
          <p>No data for selected entity</p>
        </div>
      </div>
    );
  }

  const [label, ...restLabel] = profilerObject.address
    ? profilerObject.address.split(",")
    : [
        profilerObject.object_id,
        profilerObject.latitude,
        profilerObject.longitude,
      ];

  return (
    <>
      <div className="profiler__header">
        <h2>
          {label} <small>{restLabel.join(", ")}</small>
        </h2>
      </div>
      {getContent(parsedQuery.details as string)}
    </>
  );
};

export default connect((state) => ({
  profilerObject: selectors.profiler.selectedObject(state),
  profilerFiles: selectors.profiler.files(state),
  profilerIsFetching: selectors.profiler.isFetching(state),
}))(withRouter(ProfilerContent));
