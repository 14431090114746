import React from "react";
import "./Form.css";

export default class Form extends React.Component {
  public render() {
    return (
      <div>
        <form>{this.props.children}</form>
      </div>
    );
  }
}
