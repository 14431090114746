import { createAction } from '@reduxjs/toolkit';

export const SET_OBJECTS = 'profiler/SET_OBJECTS';
export const CLEAR_OBJECTS = 'profiler/CLEAR_OBJECTS';
export const SET_OBJECT = 'profiler/SET_OBJECT';
export const CLEAR_OBJECT = 'profiler/CLEAR_OBJECT';
export const SET_FILES = 'profiler/SET_FILES';
export const CLEAR_FILES = 'profiler/CLEAR_FILES';
export const SET_ISFETCHING = 'profiler/SET_ISFETCHING'
export const SET_KEY_NOTES = 'profiler/SET_KEY_NOTES';
export const CLEAR_KEY_NOTES = 'profiler/CLEAR_KEY_NOTES';
export const SET_KEY_NOTES_SLUG = 'profiler/SET_KEY_NOTES_SLUG';
export const CLEAR_KEY_NOTES_SLUG = 'profiler/CLEAR_KEY_NOTES_SLUG';
export const UPDATE_KEY_NOTE = 'profiler/UPDATE_KEY_NOTE';

// eslint-disable-next-line
export default {
  setObjects: createAction(SET_OBJECTS),
  clearObjects: createAction(CLEAR_OBJECTS),
  setObject: createAction(SET_OBJECT),
  clearObject: createAction(CLEAR_OBJECT),
  setFiles: createAction(SET_FILES),
  clearFiles: createAction(CLEAR_FILES),
  setIsFetching: createAction(SET_ISFETCHING),
  setKeyNotes: createAction(SET_KEY_NOTES),
  clearKeyNotes: createAction(CLEAR_KEY_NOTES),
  setKeyNotesSlugs: createAction(SET_KEY_NOTES_SLUG),
  clearKeyNotesSlugs: createAction(CLEAR_KEY_NOTES_SLUG),
  updateKeyNote: createAction(UPDATE_KEY_NOTE),
};
