import React from "react";
import { Heading1 } from "../../components/elementar";
import "./NotFoundPage.css";

export default class NotFoundPage extends React.Component<{}> {
  public render() {
    return (
      <div className="sitenotfound-container">
        <div>
          <Heading1>Sorry, this site cannot be found.</Heading1>
          <p>Try another address or report the issue.</p>
        </div>
      </div>
    );
  }
}
