import React, { useCallback, useState } from "react";
import { Paragraph, SearchSelect } from "../../../components/elementar";
import SearchApi from "../../../util/api/SearchApi";
import { SearchIcon } from "../../icons";
import "./Search.css";
import { debounce } from "@mui/material";
import { PositionInformation } from "../../../store/modules/user/user.types";
import { currentSearchLocationState, SearchResult } from "../../../recoil/states/currentSearchLocationState";
import { useRecoilValue, useSetRecoilState } from "recoil";
import Mixpanel, { MIXPANEL_EVENTNAME_SEARCH_FOR_LOCATION, MIXPANEL_PAGENAME_SCOUT } from "../../../util/Mixpanel";
import { currentMapPositionInformationState } from "../../../recoil/states/currentMapPositionInformation";

const Search = () => {

  const [searchResults, setSearchResults] = useState<any>([]);
  const positionInformation = useRecoilValue(currentMapPositionInformationState);
  const setSearchResult = useSetRecoilState(currentSearchLocationState);

  const debounceSearch = debounce((value: string, positionInformation: PositionInformation) => search(value, positionInformation), 400);

  const renderFilterItem = useCallback((searchResult: any) => {
    const parts = searchResult.display_name.split(",");
    const title = parts[0];
    const rest = parts.slice(1, parts.length).join(",");
    return (
      <div>
        <Paragraph>{title}</Paragraph>
        <p>{rest}</p>
      </div>
    );

  }, []);

  async function search(searchText: string, positionInformation: PositionInformation) {
    const numResults = 7;
    if (searchText.length < 4) {
      return;
    }
    const searchApi = new SearchApi();
    const searchResults = await searchApi.searchForAddressAsync(searchText, positionInformation);
    if (searchResults == null) {
      return;
    }
    setSearchResults(searchResults.slice(0, numResults));
  }

  function selectFirstResult() {
    if (searchResults.length > 0) {
      selectSearchResult(searchResults[0]);
    }
  }

  function selectSearchResult(result: any) {
    if (
      result.lat !== undefined &&
      result.lon !== undefined &&
      result.boundingbox !== undefined &&
      result.boundingbox.length === 4
    ) {
      const bbox = result.boundingbox;
      const bounds = [[bbox[0], bbox[2]], [bbox[1], bbox[3]]];

      const searchResult: SearchResult = {
        focusLat: result.lat,
        focusLon: result.lon,
        bounds: bounds,
      };

      setSearchResults([]);

      setSearchResult([searchResult]);
      Mixpanel.getInstance().trackEvent(MIXPANEL_EVENTNAME_SEARCH_FOR_LOCATION, {
        "Component": MIXPANEL_PAGENAME_SCOUT,
      });

    }
  }

  return (
    <div className="search-container">
      <SearchSelect
        className="search-input"
        inlineIcon={<SearchIcon />}
        items={searchResults}
        listenToSearch={true}
        onChange={(value: string) => {debounceSearch(value, positionInformation)}}
        onClick={() => selectFirstResult()}
        onSelect={(item: any) => selectSearchResult(item)}
        placeholder="Search for address ..."
        renderOption={(item: any) => renderFilterItem(item)}
      />
    </div>
  );
};
export default Search;
