import React, { useState, useEffect, useRef } from "react";
import {
  useTable,
  UseTableOptions,
  useSortBy,
  // usePagination,
} from "react-table";
import classNames from "classnames";
import { useSticky } from "./tablePlugins";
import clsx from "clsx";

import { ReactComponent as SortIcon } from "../../icons/sort.svg";
import { ReactComponent as ArrowDownIcon } from "../../icons/arrowDown.svg";
import { ReactComponent as ArrowUpIcon } from "../../icons/arrowUp.svg";

import "./Table.css";
import { useRecoilValue } from "recoil";
import { currentManagerTableHeight } from "../../../recoil/states/styles/styleStates";

// TODO: Ask about pagnation

function Table({
                 columns,
                 data,
                 activeObjectID = -1,
                 onRowClick,
                 onDoubleClick,
                 setMaxHeight,
               }: UseTableOptions<any> & {
  activeObjectID?: number;
  onRowClick?: (row: any) => void;
  onDoubleClick?: (row: any) => void;
  className?: string;
  setMaxHeight?: boolean;
}) {
  const [scrolledY, setScrolledY] = useState(false);
  const [scrolledX, setScrolledX] = useState(false);
  // const tableWrapper = useRef(null);
  const activeRow = useRef(null);
  const currentTableHeight = useRecoilValue(currentManagerTableHeight);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    // page,
    // canPreviousPage,
    // canNextPage,
    // pageOptions,
    // pageCount,
    // gotoPage,
    // nextPage,
    // previousPage,
    // setPageSize,
    // state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
    },
    useSortBy,
    // usePagination,
    useSticky,
  );

  const checkScrollChange = (e: any) => {
    if (e.target.scrollTop > 0 && !scrolledY) {
      setScrolledY(true);
    }
    if (e.target.scrollTop === 0 && scrolledY) {
      setScrolledY(false);
    }
    if (e.target.scrollLeft > 0 && !scrolledX) {
      setScrolledX(true);
    }
    if (e.target.scrollLeft === 0 && scrolledX) {
      setScrolledX(false);
    }
  };

  useEffect(() => {
    if (activeRow && activeRow.current !== null) {
      (activeRow.current as any).scrollIntoView({
        block: "center",
        inline: "nearest",
      });
    }
  }, []);

  useEffect(() => {
    if (currentTableHeight > 0) {
      if (activeRow && activeRow.current !== null) {
        (activeRow.current as any).scrollIntoView({
          block: "center",
          inline: "nearest",
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setMaxHeight]);



  if (data.length === 0) {
    return <div className="manager-content__no-rating">No data</div>;
  }

  return (
    <>
      <div
        className={clsx("table__scroll")}
        style={{ height: setMaxHeight ? currentTableHeight : "" }}
        onScroll={checkScrollChange}
      >
        <table
          className={classNames("table", {
            isScrollY: scrolledY,
            isScrollX: scrolledX,
          })}
          {...getTableProps()}
        >
          <thead className="thead">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  <div>
                    {column.render("Header")}
                    {column.canSort && (
                      <>
                        {column.canSort && column.isSorted ? (
                          column.isSortedDesc ? (
                            <ArrowDownIcon className="table__sort_icon" />
                          ) : (
                            <ArrowUpIcon className="table__sort_icon" />
                          )
                        ) : (
                          <SortIcon className="table__sort_icon" />
                        )}
                      </>
                    )}
                  </div>
                </th>
              ))}
            </tr>
          ))}
          </thead>
          <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr
                ref={
                  activeObjectID === (row.original as any).id
                    ? activeRow
                    : undefined
                }
                className={classNames("tr", {
                  active: activeObjectID === (row.original as any).id,
                  clickable: !!onRowClick,
                })}
                onDoubleClick={
                  !!onDoubleClick ? () => onDoubleClick(row.original) : undefined
                }
                onClick={
                  !!onRowClick ? () => onRowClick(row.original) : undefined
                }
                {...row.getRowProps()}
              >
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
          </tbody>
        </table>
      </div>
      {/* {pageCount > 1 && (
        <div className="pagination">
          <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
            {"<<"}
          </button>{" "}
          <button onClick={() => previousPage()} disabled={!canPreviousPage}>
            {"<"}
          </button>{" "}
          <button onClick={() => nextPage()} disabled={!canNextPage}>
            {">"}
          </button>{" "}
          <button
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          >
            {">>"}
          </button>{" "}
          <span>
            Page{" "}
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>{" "}
          </span>
          <span>
            | Go to page:{" "}
            <input
              type="number"
              defaultValue={pageIndex + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                gotoPage(page);
              }}
              style={{ width: "100px" }}
            />
          </span>{" "}
          <select
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
          >
            {[10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </div>
      )} */}
    </>
  );
}

export default Table;
