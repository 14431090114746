import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChartLine,
  faUsers,
  faWarehouse,
  faBoxes,
  faHandsHelping,
} from "@fortawesome/free-solid-svg-icons";
import queryString from "query-string";
import ReactGA from "react-ga";

import { ToolTab } from "../../../components/tools";

import "./ToolbarProfiler.css";

interface IToolbarProfilerProps extends RouteComponentProps {
  temp?: null;
}

interface IToolbarProfilerState {
  tabIndex: number;
}

const getActiveTab = (path: string) => {
  switch (path) {
    case "population":
      return 1;
    case "building-stock":
      return 2;
    case "rental-market":
      return 3;
    case "buying-market":
      return 4;
    default:
      return 0;
  }
};

class ToolbarProfiler extends React.Component<
  IToolbarProfilerProps,
  IToolbarProfilerState
> {
  static USER_TAB_INDEX = 5;
  constructor(props: IToolbarProfilerProps) {
    super(props);
    this.state = {
      tabIndex: 0,
    };
  }

  componentDidMount() {
    const { details } = queryString.parse(this.props.location.search);
    if (details !== undefined) {
      this.setState({
        tabIndex: getActiveTab(details as string),
      });
    }
  }

  public render() {
    return (
      <Tabs
        className="tabs tabs--iframe"
        selectedIndex={this.state.tabIndex}
        onSelect={(tabIndex) => {
          const tabsNams = [
            "Economy",
            "Population",
            "Building stock",
            "Rental market",
            "Buying market",
            "",
            "Profile",
          ];
          ReactGA.event({
            category: "Click",
            action: tabsNams[tabIndex],
            label: "Toolbar Profiler",
          });
          this.onSelect(tabIndex);
        }}
      >
        <TabList className="tablist">
          <Tab>
            <ToolTab name="Economy">
              <FontAwesomeIcon icon={faChartLine} size="2x" />
            </ToolTab>
          </Tab>
          <Tab>
            <ToolTab name="Population">
              <FontAwesomeIcon icon={faUsers} size="2x" />
            </ToolTab>
          </Tab>
          <Tab>
            <ToolTab name="Building stock">
              <FontAwesomeIcon icon={faWarehouse} size="2x" />
            </ToolTab>
          </Tab>
          <Tab>
            <ToolTab name="Rental market">
              <FontAwesomeIcon icon={faBoxes} size="2x" />
            </ToolTab>
          </Tab>
          <Tab>
            <ToolTab name="Buying market">
              <FontAwesomeIcon icon={faHandsHelping} size="2x" />
            </ToolTab>
          </Tab>
        </TabList>
        <div className="panel">
          <TabPanel>{null}</TabPanel>
          <TabPanel>{null}</TabPanel>
          <TabPanel>{null}</TabPanel>
          <TabPanel>{null}</TabPanel>
          <TabPanel>{null}</TabPanel>
        </div>
      </Tabs>
    );
  }

  private onSelect(selectTabIndex: number) {
    this.setState({ tabIndex: selectTabIndex });

    if (selectTabIndex !== ToolbarProfiler.USER_TAB_INDEX) {
      const { history } = this.props;

      switch (selectTabIndex) {
        case 1:
          return history.push(
            `${history.location.pathname}?details=population`,
          );
        case 2:
          return history.push(
            `${history.location.pathname}?details=building-stock`,
          );
        case 3:
          return history.push(
            `${history.location.pathname}?details=rental-market`,
          );
        case 4:
          return history.push(
            `${history.location.pathname}?details=buying-market`,
          );
        default:
          return history.push(`${history.location.pathname}?details=economy`);
      }
    } else {
      if (this.state.tabIndex === selectTabIndex) {
        this.setState({ tabIndex: -1 });
      }
    }
  }
}

export default withRouter(ToolbarProfiler);
