import { createSelector } from "@reduxjs/toolkit";

const userSelector = (state: any) => state.user;
const userProfileSelector = (state: any) => state.user.profile;

// eslint-disable-next-line
export default {
  profile: createSelector(userProfileSelector, (profile) => profile),
  quotaLeft: createSelector(
    userProfileSelector,
    (profile) => profile.reports_limit,
  ),
  isQuotaLeft: createSelector(userProfileSelector, (profile) =>
    profile.reports_limit !== null ? profile.reports_limit > 0 : true,
  ),
  isSimpleMode: createSelector(
    userProfileSelector,
    (profile) => profile.simple_mode_enabled,
  ),
  userInitialCoordinates: createSelector(
    userProfileSelector,
    (profile) => profile.location || { lat: null, lon: null },
  ),
  positionInformation: createSelector(
    userSelector,
    (user) => user.positionInformation,
  ),
  currentZoom: createSelector(
    userSelector,(user) => user.currentZoom
  ),
};
