import React from "react";
import ReactDOM from "react-dom";
import "./Input.css";

interface IInputProps {
  buttonInside?: boolean;
  buttonOnClick?: (value: string) => void;
  className?: string;
  dataSetKey?: string;
  defaultValue?: string;
  inlineIcon?: React.ReactNode;
  inputClassName?: string;
  label?: string;
  labelWidth?: number;
  listenToSearch?: boolean;
  maxValue?: number;
  minValue?: number;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onValueChangeEnded?: (value: string) => void;
  placeholder?: string;
  returnPressed?: () => void;
  type?: string;
  value?: string;
  name?: string;
}

interface IInputState {
  id: number;
  value: string;
}

export default class Input extends React.Component<IInputProps, IInputState> {
  private inputRef: React.RefObject<HTMLInputElement> = React.createRef();

  constructor(props: IInputProps) {
    super(props);

    let predefinedValue = this.props.value;
    if (!predefinedValue) {
      predefinedValue = this.props.defaultValue ? this.props.defaultValue : "";
    }
    this.state = {
      id: this.generateRandomId(),
      value: predefinedValue,
    };
  }

  public componentWillReceiveProps(nextProps: IInputProps) {
    if (this.props.value !== nextProps.value) {
      this.setState({
        value: nextProps.value ? nextProps.value : "",
      });
    }
  }

  public componentDidMount() {
    if (this.props.listenToSearch) {
      document.addEventListener("keydown", (event: KeyboardEvent) =>
        this.onDocumentKeyDown(event),
      );
    }
  }

  public componentWillUnmount() {
    if (this.props.listenToSearch) {
      document.removeEventListener("keydown", (event: KeyboardEvent) =>
        this.onDocumentKeyDown(event),
      );
    }
  }

  public render() {
    let labelStyle;
    if (this.props.labelWidth) {
      labelStyle = { width: this.props.labelWidth };
    }
    return (
      <div className={this.generateClassName()}>
        {this.props.label ? (
          <label
            className="label"
            style={labelStyle}
            htmlFor={this.state.id.toString()}
          >
            {this.props.label}
          </label>
        ) : (
          ""
        )}
        <input
          className={this.generateInputClassName()}
          data-key={this.props.dataSetKey ? this.props.dataSetKey : ""}
          id={this.state.id.toString()}
          onBlur={(event: React.FocusEvent<HTMLInputElement>) =>
            this.onBlur(event)
          }
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            this.onChange(event)
          }
          onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) =>
            this.onInputKeyDown(event)
          }
          placeholder={this.props.placeholder ? this.props.placeholder : ""}
          ref={this.inputRef}
          type={this.props.type ? this.props.type : "text"}
          value={this.state.value}
          checked={this.props.type === "checkbox" ? (this.state.value === "TRUE" ? true : false) : false}
          max={this.props.maxValue || ""}
          min={this.props.minValue || ""}
          name={this.props.name}
        />
        {this.props.buttonInside ? (
          <div
            className="input-button-inside"
            onClick={() => this.buttonInsideOnClick()}
          >
            {this.props.inlineIcon}
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }

  private generateClassName(): string {
    const baseClassName = "input-container";
    const classNames = [baseClassName];
    if (this.props.className) {
      classNames.push(this.props.className);
    }
    return classNames.join(" ");
  }

  private generateInputClassName(): string {
    const baseClassName = "input";
    const classNames = [baseClassName];
    if (this.props.buttonInside) {
      classNames.push("button-inside");
    }
    if (this.props.inputClassName) {
      classNames.push(this.props.inputClassName);
    }
    return classNames.join(" ");
  }

  private generateRandomId(): number {
    return Math.floor(Math.random() * 1000000000 + 1);
  }

  private onChange(event: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ value: event.target.value });
    if (this.props.onChange) {
      this.props.onChange(event);
    }
  }

  private onBlur(event: React.FocusEvent<HTMLInputElement>) {
    if (this.props.onValueChangeEnded) {
      this.props.onValueChangeEnded(this.state.value);
    }
  }

  private onInputKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
    const enterKey = 13;
    if (event.keyCode === enterKey) {
      const inputElement = ReactDOM.findDOMNode(
        this.inputRef.current,
      ) as HTMLElement;
      inputElement.blur();
      if (this.props.onValueChangeEnded) {
        this.props.onValueChangeEnded(this.state.value);
      }
      if (this.props.returnPressed) {
        this.props.returnPressed();
      }
      this.buttonInsideOnClick();
    }
  }

  private onDocumentKeyDown(event: KeyboardEvent) {
    const F = 70;
    if (event.ctrlKey && event.keyCode === F) {
      const inputElement = ReactDOM.findDOMNode(
        this.inputRef.current,
      ) as HTMLElement;
      inputElement.focus();
      event.preventDefault();
    }
  }

  private buttonInsideOnClick() {
    if (this.props.buttonOnClick) {
      this.props.buttonOnClick(this.state.value);
    }
  }
}
