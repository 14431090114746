import React from "react";
import { useSelector } from "react-redux";

import { selectors } from "../../../store/store";

const RestrictedQuotaLeft = ({ children }: { children: React.ReactNode }) => {
  const isQuotaLeft = useSelector(selectors.user.isQuotaLeft);

  if (!isQuotaLeft) {
    return null;
  }

  return <>{children}</>;
};

export default RestrictedQuotaLeft;
