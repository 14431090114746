import useSWR, { SWRConfiguration } from "swr";
import { SQMApi } from "../../util/api";
import _ from "lodash";
import { AttributeMetadataDetails } from "../../util/models/AttributeMetadata";

export default function useAttributes() {
  const sqmApi = new SQMApi();
  const options: SWRConfiguration = {
    revalidateOnFocus: false,
    dedupingInterval: 40000,
  };

  async function getAttributes() {
    const result = await sqmApi.getAttributes();
    return _.orderBy<AttributeMetadataDetails>(result, ['name']);
  }

  const { data, error } = useSWR(
    "api/attributes/",
    getAttributes,
    options,
  );

  return {
    attributes: data,
    isLoading: !error && !data,
    isError: error,
  };
}
