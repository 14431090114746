import React from "react";

export default class Math extends React.Component {
  public render() {
    return (
      <svg
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 959.125596 631.677489"
        preserveAspectRatio="xMidYMid meet"
      >
        <g
          transform="translate(-0.258392,631.843887) scale(0.100000,-0.100000)"
          fill="#000000"
          stroke="none"
        >
          <path
            d="M3165 6300 c-309 -81 -665 -301 -876 -541 -316 -361 -516 -783 -683
-1435 l-43 -169 -509 -6 c-568 -6 -558 -5 -670 -77 -106 -68 -175 -177 -196
-308 -37 -220 123 -445 350 -493 34 -7 197 -11 452 -11 220 0 400 -2 400 -5 0
-3 -23 -134 -50 -291 -143 -812 -260 -1238 -426 -1552 -81 -153 -126 -216
-238 -327 -111 -110 -185 -156 -348 -215 -147 -53 -223 -118 -280 -236 -83
-176 -51 -362 86 -500 95 -95 225 -143 349 -130 74 7 215 54 335 111 508 241
858 688 1094 1400 111 335 189 678 304 1330 35 204 67 380 69 393 l5 22 643 0
c707 0 702 0 819 62 153 81 247 273 219 447 -31 193 -184 343 -376 371 -39 5
-304 10 -597 10 l-527 0 9 28 c5 15 27 89 50 166 182 616 453 968 845 1096
107 35 164 67 225 129 169 168 171 436 5 610 -65 68 -128 105 -216 126 -83 19
-138 18 -224 -5z"
          />
          <path
            d="M4939 5247 c-109 -41 -166 -110 -275 -332 -118 -242 -244 -648 -308
-995 -173 -939 -147 -1911 75 -2795 103 -409 291 -839 412 -941 126 -107 359
-92 495 31 50 44 87 115 102 190 14 74 8 105 -89 415 -149 480 -203 694 -255
1020 -87 540 -94 1068 -20 1585 38 267 139 695 249 1060 37 121 79 258 93 305
14 48 25 109 25 141 0 124 -71 232 -191 291 -60 30 -76 33 -172 36 -70 1 -117
-2 -141 -11z"
          />
          <path
            d="M8649 5246 c-140 -38 -241 -153 -255 -293 -5 -53 2 -85 91 -378 177
-587 261 -966 307 -1390 20 -189 17 -849 -5 -1040 -54 -457 -124 -757 -323
-1400 -59 -191 -68 -230 -67 -295 0 -91 23 -149 85 -216 89 -97 250 -143 384
-111 129 32 192 99 303 322 206 416 353 1037 413 1750 16 193 16 796 0 990
-57 686 -200 1301 -402 1732 -123 262 -219 344 -403 342 -45 0 -103 -6 -128
-13z"
          />
          <path
            d="M5889 4151 c-98 -31 -212 -128 -259 -221 -25 -48 -50 -156 -50 -215
0 -70 28 -157 73 -221 23 -34 195 -226 382 -428 187 -201 340 -369 340 -374 0
-5 -175 -197 -389 -427 -214 -231 -411 -448 -437 -484 -63 -85 -90 -156 -96
-256 -13 -194 78 -353 247 -434 73 -35 78 -36 195 -36 111 0 124 2 176 28 91
44 175 131 516 535 179 210 328 382 332 382 4 0 148 -165 318 -367 452 -535
494 -572 669 -581 155 -8 270 34 364 134 62 66 95 136 110 232 21 130 -10 248
-95 367 -19 27 -211 240 -427 473 -216 233 -394 427 -396 431 -1 4 157 180
351 390 215 232 369 407 390 443 88 149 62 366 -59 497 -97 105 -196 151 -324
151 -152 0 -249 -36 -343 -128 -32 -31 -169 -195 -305 -364 -135 -170 -249
-308 -253 -308 -4 0 -120 141 -257 313 -272 339 -331 403 -407 441 -97 47
-263 60 -366 27z"
          />
        </g>
      </svg>
    );
  }
}
