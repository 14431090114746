import { atom, selector } from "recoil";
import { ESRI_SATELLITE_BINARY, OSM_BINARY, STAMEN_BINARY } from "../../util/models/TileProviderBinaries";
import Cookies from "js-cookie";
import { COOKIE_TILE_LAYER_KEY } from "../../App.constants";

export interface TileLayerConfiguration {
  key: string;
  previewBinary: string;
  providerAttribution: string;
  providerURL: string;
  maxZoom: number;
}

export const OSM_KEY = "osm";
export const NONE_KEY = "none";
export const STAMEN_KEY = "stamen";
export const CARTO_KEY = "carto";
export const ESRI_KEY = "esri";

export const defaultTileLayerConfiguration = (): TileLayerConfiguration => ({
  key: OSM_KEY,
  providerURL: "https://tile.osm.ch/switzerland/{z}/{x}/{y}.png",
  providerAttribution: "<a href=\"https://www.openstreetmap.org/copyright\">OpenStreetMap</a> contributors",
  maxZoom: 21,
  previewBinary: OSM_BINARY,
});

export const noneTileLayerConfiguration = (): TileLayerConfiguration => ({
  key: NONE_KEY,
  providerURL: "",
  providerAttribution: "",
  maxZoom: 21,
  previewBinary: "",
});

export const stamenTileLayerConfiguration = (): TileLayerConfiguration => ({
  key: STAMEN_KEY,
  providerURL: "https://stamen-tiles-{s}.a.ssl.fastly.net/toner/{z}/{x}/{y}{r}.png",
  providerAttribution: "Map tiles by <a href=\"http://stamen.com\">Stamen Design</a>, <a href=\"http://creativecommons.org/licenses/by/3.0\">CC BY 3.0</a> &mdash; Map data &copy; <a href=\"https://www.openstreetmap.org/copyright\">OpenStreetMap</a> contributors",
  maxZoom: 20,
  previewBinary: STAMEN_BINARY,
});

export const cartoTileLayerConfiguration = (): TileLayerConfiguration => ({
  key: CARTO_KEY,
  providerURL: "https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png",
  providerAttribution: "Map tiles by <a href=\"https://carto.com/\">Carto</a>, Map data &copy; <a href=\"https://www.openstreetmap.org/copyright\">OpenStreetMap</a> contributors",
  maxZoom: 18,
  previewBinary: STAMEN_BINARY,
});

export const esriTileLayerConfiguration = (): TileLayerConfiguration => ({
  key: ESRI_KEY,
  providerURL: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
  providerAttribution: "Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community",
  maxZoom: 21,
  previewBinary: ESRI_SATELLITE_BINARY,
});


export const currentTileLayerState = atom({
  key: "currentTileLayerState", // unique ID (with respect to other atoms/selectors)
  default: defaultTileLayerConfiguration(), // default value (aka initial value)
});

export const cookieBasedTileLayerState = selector({
  key: "cookieBasedTileLayerState",
  get: ({ get }) => {
    const cookieKey = Cookies.get(COOKIE_TILE_LAYER_KEY);
    let currentTile = get(currentTileLayerState);
    if (currentTile.key !== cookieKey) {
      switch (currentTile.key) {
        case NONE_KEY:
          currentTile = noneTileLayerConfiguration();
          break;
        case ESRI_KEY:
          currentTile = esriTileLayerConfiguration();
          break;
        case STAMEN_KEY:
          currentTile = defaultTileLayerConfiguration();
          break;
        case CARTO_KEY:
          currentTile = cartoTileLayerConfiguration();
          break;
        default:
          currentTile = defaultTileLayerConfiguration();
          break;
      }
    }
    return currentTile;
  },
});
