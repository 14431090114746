import BaseApi from "./BaseApi";
import Mixpanel, { MIXPANEL_EVENTNAME_LOGIN, MIXPANEL_EVENTNAME_LOGOUT, MIXPANEL_PAGENAME_DEFAULT } from "../Mixpanel";

export default class AuthApi extends BaseApi {
  public async getAuthorizationToken(
    username: string,
    password: string,
  ): Promise<any> {
    const json = JSON.stringify({ username, password });
    let errorMessage = "";
    await this.postAuthorized("token/", json)
      .then(async (response: any) => {
        if (response.status === 200) {
          const token = await response.json();
          localStorage.setItem("authorizationToken", token.access);
          localStorage.setItem("username", username);

          Mixpanel.getInstance().trackEvent(MIXPANEL_EVENTNAME_LOGIN, { "Component": MIXPANEL_PAGENAME_DEFAULT });
        } else if (response.status === 403) {
          const res = await response.json();
          if (res.details === "Account expired.") {
            errorMessage = "Your account has expired. Please contact support@urbandatalab.net for an update."
          } else {
            errorMessage =
              "To log into system user needs to be part of an organization. Please contact the system administrator.";
          }
        } else {
          errorMessage = `The user "${username}" does not exist or the provided password is wrong`;
        }
      })
      .catch((reason: any) => {
        errorMessage = "Could not connect. Is your Internet connection alive?";
      });
    if (errorMessage.length > 0) {
      throw new Error(errorMessage);
    }
  }

  public logout() {
    Mixpanel.getInstance().trackEvent(MIXPANEL_EVENTNAME_LOGOUT, { "Component": MIXPANEL_PAGENAME_DEFAULT });
    Mixpanel.getInstance().dispose();
    localStorage.clear();
  }

  public isAuthenticated(): boolean {
    return localStorage.getItem("authorizationToken") != null;
  }

  public getUsername(): string {
    const username = localStorage.getItem("username");
    return username ? username : "";
  }
}
