import React, { useEffect, useState } from "react";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileAlt } from "@fortawesome/free-solid-svg-icons";
import { UserIcon } from "../../../components/icons";
import { AuthApi } from "../../../util/api";
import { ToolTab, User } from "../../tools";
import { ReactComponent as DocsIcon } from "../../icons/docs.svg";

import "./ToolbarManager.css";
import { useMeasure } from "react-use";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { currentManagerTableHeight } from "../../../recoil/states/styles/styleStates";
import { managerContentDetailState } from "../../../recoil/states/managerContentDetailState";

const setDefaultActiveTab = (pathname: string) => {
  switch (pathname) {
    case "/manager/property":
      return 1;
    case "/manager/building":
      return 2;
    case "/manager/unit":
      return 3;

    default:
      return 0;
  }
};

const ToolbarManager = () => {
  // const location = useLocation();
  const [activeTab, setActiveTab] = useState(
    setDefaultActiveTab("portfolio"),
  );

  const [tabRef, { height }] = useMeasure<any>();
  const setTableHeight = useSetRecoilState(currentManagerTableHeight);
  const contentDetails = useRecoilValue(managerContentDetailState);

  // const history = useHistory();

  const changeLocation = (tab: number) => {
    switch (tab) {
      // case 0:
      //   return history.push("/manager/portfolio");
      // case 1:
      //   return history.push("/manager/property");
      // case 2:
      //   return history.push("/manager/building");
      // case 3:
      //   return history.push("/manager/unit");

      default:
        return tab;
    }
  };
  //
  // useEffect(() => {
  //   setTableHeight(window.innerHeight - (3 * 78) - 300);
  // }, [height, setTableHeight]);

  useEffect(() => {
    setTableHeight(window.innerHeight - (3 * 78) - (contentDetails.collapsed ? contentDetails.height + 100 : 480));
  }, [height, setTableHeight, contentDetails]);

  return (
    <Tabs
      domRef={tabRef}
      className="tabs"
      selectedIndex={activeTab}
      onSelect={(tabIndex) => {
        const tabsNams = [
          "Portfolio",
          // "Property",
          // "Building",
          // "Unit",
          "Docs",
          "Profile",
        ];

        if (tabIndex === activeTab && tabsNams[tabIndex] === "Profile") {
          return setActiveTab(0);
        }

        if (tabsNams[tabIndex] === "Docs") {
          return setActiveTab(activeTab);
        }
        // if (tabIndex !== 0) {
        //
        // }
        setActiveTab(tabIndex);

        changeLocation(tabIndex);
      }}
    >
      <TabList className="tablist">
        <Tab>
          <ToolTab name="Portfolio">
            <FontAwesomeIcon icon={faFileAlt} size="2x" />
          </ToolTab>
        </Tab>
        {/*<Tab>*/}
        {/*  <ToolTab name="Property">*/}
        {/*    <FontAwesomeIcon icon={faTh} size="2x" />*/}
        {/*  </ToolTab>*/}
        {/*</Tab>*/}
        {/*<Tab>*/}
        {/*  <ToolTab name="Building">*/}
        {/*    <FontAwesomeIcon icon={faBuilding} size="2x" />*/}
        {/*  </ToolTab>*/}
        {/*</Tab>*/}
        {/*<Tab>*/}
        {/*  <ToolTab name="Unit">*/}
        {/*    <PlanIcon />*/}
        {/*  </ToolTab>*/}
        {/*</Tab>*/}
        <div className="spacer" />
        <Tab>
          <ToolTab name="Docs">
            <a
              href="https://docs.udl.ai/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <DocsIcon />
            </a>
          </ToolTab>
        </Tab>
        <Tab>
          <ToolTab name={new AuthApi().getUsername()} className="profile">
            <UserIcon />
          </ToolTab>
        </Tab>
      </TabList>
      <div className="panel">
        <TabPanel>{null}</TabPanel>
        {/*<TabPanel>{null}</TabPanel>*/}
        {/*<TabPanel>{null}</TabPanel>*/}
        {/*<TabPanel>{null}</TabPanel>*/}
        <TabPanel>{null}</TabPanel>
        <TabPanel>
          <User />
        </TabPanel>
      </div>
    </Tabs>
  );
};

export default ToolbarManager;
