import reducer from "./user.reducer";
import actions from "./user.actions";
import selectors from "./user.selectors";

const user = {
  reducer,
  actions,
  selectors,
};

export default user;
