export default class AttributeConfiguration {
  public attributeKey?: number;
  private isExpert: boolean;
  private rangeMax: number;
  private rangeMin: number;
  private transformation: string;
  private weight: string;
  private normalization: boolean;

  constructor(
    isExpert: boolean = false,
    rangeMax: number = 0,
    rangeMin: number = 0,
    transformation: string = "",
    weight: string = "1",
    normalization: boolean = false,
  ) {
    this.isExpert = isExpert;
    this.rangeMax = rangeMax;
    this.rangeMin = rangeMin;
    this.transformation = transformation;
    this.weight = weight;
    this.normalization = normalization;
    this.toJSON = this.toJSON.bind(this);
  }

  public getIsExpert(): boolean {
    return this.isExpert;
  }

  public setIsExpert(value: boolean) {
    this.isExpert = value;
  }

  public getRangeMax(): number {
    return this.rangeMax;
  }

  public setRangeMax(value: number) {
    this.rangeMax = value;
  }

  public getRangeMin(): number {
    return this.rangeMin;
  }

  public setRangeMin(value: number) {
    this.rangeMin = value;
  }

  public getTransformation(): string {
    if (this.isExpert && this.transformation) {
      return this.transformation;
    }
    return this.simpleTransformation();
  }

  public setTransformation(value: string) {
    this.transformation = value;
  }

  public getWeight(): string {
    if (this.isExpert || !isNaN(parseInt(this.weight, 10))) {
      return this.weight;
    }
    return "1";
  }

  public setWeight(value: string) {
    this.weight = value;
  }

  public setNormalization(value: boolean) {
    this.normalization = value;
  }

  public getNormalization() {
    return this.normalization;
  }

  public toJSON() {
    return {
      attributeKey: this.attributeKey,
      isExpert: this.isExpert,
      normalization: this.normalization,
      rangeMax: this.rangeMax,
      rangeMin: this.getRangeMin(),
      transformation: encodeURIComponent(this.getTransformation()),
      weight: encodeURIComponent(this.getWeight()),
    };
  }

  public getCopy(): AttributeConfiguration {
    const newAttribute = new AttributeConfiguration();
    newAttribute.attributeKey = this.attributeKey;
    newAttribute.setRangeMin(this.getRangeMin());
    newAttribute.setRangeMax(this.getRangeMax());
    newAttribute.setIsExpert(this.getIsExpert());
    newAttribute.setNormalization(this.getNormalization());
    newAttribute.setWeight(this.getWeight());
    newAttribute.setTransformation(this.getTransformation());

    return newAttribute;
  }

  private simpleTransformation(): string {
    return `1 * ((x >= ${this.rangeMin}) & (x <= ${this.rangeMax}))`;
  }
}
