import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";

import user from "./modules/user";
import profiler from "./modules/profiler";
import manager from "./modules/manager";

const rootReducer = combineReducers({
  user: user.reducer,
  profiler: profiler.reducer,
  manager: manager.reducer,
});

export const store = configureStore({
  reducer: rootReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;

export const actions = {
  user: user.actions,
  profiler: profiler.actions,
  manager: manager.actions,
};

export const selectors = {
  user: user.selectors,
  profiler: profiler.selectors,
  manager: manager.selectors,
};

export const helpers = {
  manager: manager.helpers,
};

export const useAppDispatch = () => useDispatch<AppDispatch>();

export default store;
