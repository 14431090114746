import { atom } from "recoil";

export interface SearchResult {
  focusLat: number,
  focusLon: number,
  bounds: number[][]
}

export const currentSearchLocationState = atom({
  key: "currentSearchLocationState", // unique ID (with respect to other atoms/selectors)
  default: [] as Array<SearchResult> , // default value (aka initial value)
});
