import { sprintf } from "sprintf-js";

import APIInstance from "./api";

const URLS = {
  OBJECTS: "/objects/",
  OBJECTS_FILES: "/objects/%s/files/",
  OBJECT_KEY_NOTES: "/objects/%s/notes/",
  KEY_NOTES_SLUGS: "/objects/notes/slugs/",
  OBJECT_KEY_NOTE: "/objects/%s/notes/%s/note/",
};

const profilerApi= {
  getUserObjects: () =>
    APIInstance.request({
      method: "GET",
      url: URLS.OBJECTS,
    }),
  getObjectFiles: ({ objectId }: { objectId: number }) =>
    APIInstance.request({
      method: "GET",
      url: sprintf(URLS.OBJECTS_FILES, objectId),
    }),
  getObjectKeyNotes: ({ objectId }: { objectId: number }) =>
    APIInstance.request({
      method: "GET",
      url: sprintf(URLS.OBJECT_KEY_NOTES, objectId),
    }),
  getKeyNotesSlugs: () =>
    APIInstance.request({
      method: "GET",
      url: URLS.KEY_NOTES_SLUGS,
    }),
  createObjectKeyNote: ({
    objectId,
    key_note,
    key_note_slug,
  }: {
    objectId: number;
    key_note: string;
    key_note_slug: string;
  }) =>
    APIInstance.request({
      method: "POST",
      url: sprintf(URLS.OBJECT_KEY_NOTES, objectId),
      data: {
        key_note,
        key_note_slug,
      },
    }),
  updateObjectKeyNote: ({
    objectId,
    key_note,
    key_note_slug,
  }: {
    objectId: number;
    key_note: string;
    key_note_slug: string;
  }) =>
    APIInstance.request({
      method: "PUT",
      url: sprintf(URLS.OBJECT_KEY_NOTE, objectId, key_note_slug),
      data: {
        key_note,
      },
    }),
};
export default profilerApi;