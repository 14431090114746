import React from "react";
import Info from "../../icons/Info";

interface IRadioItemProps {
  name: string;
  value: string;
  tooltip?: string;
}

export default class RadioItem extends React.Component<IRadioItemProps> {
  public render() {
    if (this.props.tooltip) {
      return (
        <>
          {this.props.name}
          <span
            className="tooltip tooltip-top"
            data-tooltip={this.props.tooltip}
          >
            <Info />
          </span>
        </>
      );
    }
    return this.props.name;
  }
}
