import React from "react";

import Loading from '../../icons/Loading';
import './AsyncLoadingIndicator.css';

const AsyncLoadingIndicator: React.FC<{}> = (): JSX.Element => (
  <div className="async-loading-indicator"><Loading /></div>
)

export default AsyncLoadingIndicator;
