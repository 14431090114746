import { atom } from "recoil";

export const LAYER_CONFIGURATION_SCALE_FOCAL = "focal";
export const LAYER_CONFIGURATION_SCALE_GLOBAL = "global";
export const LAYER_CONFIGURATION_SCALE_RATED = "rated";

export interface LayerConfiguration {
  attributeMetadataKey: number,
  rating: boolean,
  scale: string
}

export const defaultLayerConfigurationForComposite = (): LayerConfiguration=>({
  attributeMetadataKey:-3,
  rating:false,
  scale: LAYER_CONFIGURATION_SCALE_FOCAL
});

export const initCurrentLayerConfigurationState = (): LayerConfiguration => ({
  attributeMetadataKey: -1,
  rating: true,
  scale: LAYER_CONFIGURATION_SCALE_FOCAL,
});

export const currentLayerConfigurationState = atom({
  key: "currentLayerConfigurationState", // unique ID (with respect to other atoms/selectors)
  default: initCurrentLayerConfigurationState(), // default value (aka initial value)
});
