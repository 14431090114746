const env = {
  API_URL: process.env.REACT_APP_API_URL || "",
  IS_PRODUCTION: process.env.REACT_APP_BUILD === "Production",
  IS_STAGING: process.env.REACT_APP_BUILD === "Staging",
  IS_DEV: process.env.REACT_APP_BUILD === "Development",
  IS_NODE_ENV_PRODUCTION: process.env.NODE_ENV === "production",
  ENVIRONMENT: process.env.REACT_APP_BUILD || "Staging",
  GA_ID: process.env.REACT_APP_GA_ID || "",
  GTM_ID: process.env.REACT_APP_GTM_ID || "",
  SENTRY_DSN: process.env.REACT_APP_SENTRY_DSN || "",
  GA_DOMAINS: ["urbandatalab.ch", "app.udl.ai"],
  HOTJAR_ID: process.env.REACT_APP_HJID,
  HOTJAT_SV: process.env.REACT_APP_HJSV,
  MIXPANEL_TOKEN: process.env.REACT_APP_MIXPANEL_TOKEN || "",
  REGISTER_URL: process.env.REACT_APP_REGISTER_URL || "https://api.udl.ai/register/freemium/web"
};
export default env;
