import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps, useHistory, withRouter } from "react-router-dom";
import { format, parseISO } from "date-fns";

import { BasePanel } from "..";
import { AuthApi } from "../../../util/api";
import { selectors } from "../../../store/store";
import { UserReducerType } from "../../../store/modules/user/user.types";

import "./User.css";
import Mixpanel from "../../../util/Mixpanel";
import DropdownLanguage from "../DropdownLanguage/DropdownLanguage.component";
import Box from "@mui/material/Box";
import { useSetRecoilState } from "recoil";
import { currentMarkersState } from "../../../recoil/states/currentMarkersState";
import { Paper } from "@mui/material";
import { Translation } from "react-i18next";
import Link from "@mui/material/Link";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

interface UserProps extends RouteComponentProps {
  user: UserReducerType["profile"];
}

const valueOrNa = (value: any) => {
  if (value === null || value === undefined) {
    return "n/a";
  }

  if (typeof value === "string") {
    return value.length === 0 ? "n/a" : value;
  }

  return value;
};

const LogOutButtonComponent = () => {
  const setMarkers = useSetRecoilState(currentMarkersState);
  const history = useHistory();

  async function logout() {
    setMarkers([]);
    const authApi = new AuthApi();
    authApi.logout();
    if (!authApi.isAuthenticated()) {
      history.push("/login");
    }
  }

  return (<Button variant="contained" color={"primary"} onClick={() => logout()}>Logout</Button>);
};

class User extends React.Component<UserProps> {
  public render() {
    const { user } = this.props;
    return (
      <BasePanel className="user-panel">
        <div style={{ display: "flex", flexFlow: "column" }}>
          {
            Mixpanel.getInstance().setSubPageName("App")
          }

          <Typography variant={"h5"}>Welcome, {valueOrNa(user.first_name)} {valueOrNa(user.last_name)}</Typography>

          <Box marginTop={2} marginBottom={2}>
            <Paper style={{ marginLeft: 8, padding: 16 }} elevation={4}>
              <div className="user-panel-about">
                <Typography variant={"subtitle1"}>Profile</Typography>
                <ul className="user-panel-about-components">
                  <li>Username: {new AuthApi().getUsername()}</li>
                  <li>Organization: {valueOrNa(user.organization)}</li>
                  <li>Email: {valueOrNa(user.email)}</li>
                  <li>Nr of reports: {valueOrNa(user.reports_limit)}</li>
                  <li>
                    Expiry date:{" "}
                    {user.expiry_date !== null
                      ? format(parseISO(user.expiry_date), "dd-MM-yyyy H:mm")
                      : "n/a"}
                  </li>
                </ul>
              </div>
            </Paper>
          </Box>

          {
            !this.props.user.simple_mode_enabled && (
              <Box marginTop={1} marginBottom={2}>
                <div style={{marginLeft: 8}}>
                  <DropdownLanguage />
                </div>
              </Box>
            )
          }

          <Box alignSelf={"flex-start"} position={"absolute"} bottom={140}>
            <LogOutButtonComponent />
          </Box>

          <Box marginTop={1} position={"absolute"} bottom={100} alignSelf={"flex-start"}>
            <Translation>
              {
                (t, { i18n }) =>
                  <React.Fragment>
                    <Link variant="body2" color="secondary" href="/assets/210910_AGB_UDL4cust.pdf"
                          download="210910_AGB_UDL4cust.pdf">{t("termsConditions.title")}</Link>
                  </React.Fragment>
              }
            </Translation>

          </Box>


        </div>
      </BasePanel>
    );
  }
}

export default connect(
  (state) => ({
    user: selectors.user.profile(state),
  }),
)(withRouter(User) as any);
