import reducer from "./manager.reducer";
import actions from "./manager.actions";
import selectors from "./manager.selectors";
import helpers from "./manager.helpers";

const manager = {
  reducer,
  actions,
  selectors,
  helpers,
};

export default manager;
