import React, { useCallback, useEffect, useState } from "react";
import { sort } from "ramda";
import { connect } from "react-redux";
import axios from "axios";
import Papa from "papaparse";

import { ProfilerTable } from "../../../elementar";
import { KeyNotes, ProfilerGraph } from "../../../tools";
import { selectors } from "../../../../store/store";
import { ObjectFileType } from "../../../../store/modules/profiler/profiler.types";
import { TABS_NAMES } from "../../../../store/modules/profiler/profiler.selectors";

import ContentTabs from "./ContentTabs";
import Mixpanel, {
  MIXPANEL_EVENTNAME_OPEN_LOCAL_REPORT,
  MIXPANEL_EVENTNAME_PAGEVIEWED,
  MIXPANEL_EVENTNAME_REGENERATE_REPORT,
  MIXPANEL_PAGENAME_PROFILER,
} from "../../../../util/Mixpanel";
import { AutorenewOutlined, CloudDownloadOutlined, MoreVert } from "@mui/icons-material";
import Button from "@mui/material/Button";
import { Box, IconButton, ListItemIcon, Menu, MenuItem } from "@mui/material";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import * as htmlToImage from "html-to-image";
import { useRecoilState, useRecoilValue } from "recoil";
import { currentObjectState } from "../../../../recoil/states/currentObjectState";
import { useTranslation } from "react-i18next";
import { Severity, snackbarState } from "../../../../recoil/states/snackbarState";
import { SQMApi } from "../../../../util/api";

interface ContentDataProps {
  tableFiles: ObjectFileType[];
  graphFile: ObjectFileType | undefined;
  tableNames: string[];
  graphName: string;
  slug: string;
  title: string;
}

const ContentData = ({
                       graphFile,
                       tableFiles,
                       slug,
                       title,
                     }: ContentDataProps) => {
  const currentObject = useRecoilValue(currentObjectState);
  const [currentSnackbarState, setCurrentSnackbarState] = useRecoilState(snackbarState);
  const sqmApi = new SQMApi();

  const [tabs, setTabs] = useState<{ name: string; data: any }[]>([]);
  const [isFetching, setIsFetching] = useState(false);

  const [t] = useTranslation();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  function showToast(message: string) {
    setCurrentSnackbarState({ ...currentSnackbarState, severity: Severity.Info, open: true, message: message });
  }

  function showError(message: string) {
    setCurrentSnackbarState({
      ...currentSnackbarState,
      open: true,
      severity: Severity.Error,
      message: message,
    });
  }

  const getData = useCallback(() => {
    if (tableFiles.length > 0) {
      setIsFetching(true);
      const contentTabs = tableFiles.map((tab) => ({
        name: tab.name,
        priority: TABS_NAMES[tab.name].priority,
        data: [],
      }));

      axios
        .all(tableFiles.map((file) => axios.get(file.file)))
        .then(
          axios.spread((...csv) => {
            csv.forEach((f, k) => {
              contentTabs[k].data = Papa.parse(f.data).data.filter(
                (value: any) => value.length > 1,
              ) as any;
            });
            setTabs(
              sort((tabA, tabB) => tabA.priority - tabB.priority, contentTabs),
            );
          }),
        )
        .finally(() => {
          setIsFetching(false);
        });
    }
  }, [tableFiles]);

  const onRegenerateReport = () => {
    showToast(t("markedLocations.localReportInfo"));
    Mixpanel.getInstance().trackEvent(MIXPANEL_EVENTNAME_REGENERATE_REPORT, { "Component": MIXPANEL_PAGENAME_PROFILER });

    if (currentObject.id > -1) {
      let lat = 0;
      let lng = 0;

      if (currentObject.location.latitude != null) {
        lat = parseFloat(currentObject.location.latitude);
      }
      if (currentObject.location.longitude != null) {
        lng = parseFloat(currentObject.location.longitude);
      }

      sqmApi
        .getLocalReportProxyV2(lat, lng)
        .then((file) => {
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
          const link = document.createElement("a");
          link.href = fileURL;
          link.download = `report_${lat}_${lng}_${Date.now()}.pdf`;
          link.click();
          link.remove();
        })
        .catch((error) => {
          console.error(error);
          showError(t("Unable to generate report, please try again later."));
          //setErrorMsg("Unable to generate report, please try again later.");
        });
    }
  };
  const onDownloadReport = () => {
    showToast(t("markedLocations.localReportDownloadInfo"));
    Mixpanel.getInstance().trackEvent(MIXPANEL_EVENTNAME_OPEN_LOCAL_REPORT, { "Component": MIXPANEL_PAGENAME_PROFILER });

    const regex = /-/gi;

    if (currentObject.object_id) {
      const currentObjectId = currentObject.object_id.replace(regex, "");

      sqmApi
        .getExistingLocalReportProxyV2(currentObjectId)
        .then((file) => {
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
          const link = document.createElement("a");
          link.href = fileURL;
          link.download = `report_${currentObject.address}_${Date.now()}.pdf`;
          link.click();
          link.remove();
        })
        .catch((error) => {
          console.log(error);
          showError(t("Unable to download existing report, please try again later."));
          //setErrorMsg("Unable to generate report, please try again later.");
        });
    }
  };

  function downloadCSV() {
    setIsFetching(true);
    axios
      .all(tableFiles.map((file) => axios.get(file.file)))
      .then(
        axios.spread(async (...csv) => {
          const zip = new JSZip();
          try {
            if (graphFile) {
              const c: any = document.getElementById("pdf-graph");
              const blobData: any = await htmlToImage.toBlob(c);
              zip.file(`graph.png`, blobData);
            }
          } catch (ex) {
            console.error("oops, something went wrong!", ex);
          }

          for (let i = 0; i < csv.length; i++) {
            const file: any = csv[i];
            const fileName = TABS_NAMES[tableFiles[i].name].name;
            try {

              zip.file(`${fileName}_${new Date().getMilliseconds()}.csv`, file.data);
            } catch (error) {
              console.error("oops, something went wrong!", error);
            }
          }

          try {
            zip.generateAsync({ type: "blob" })
              .then(function(content) {
                // see FileSaver.js
                saveAs(content, `${title}_Export.zip`);
              });
          } catch (ex) {
          }
        }),
      )
      .finally(() => {
        setIsFetching(false);
      });
  }

  useEffect(() => {
    Mixpanel.getInstance().setSubPageName(title);
    Mixpanel.getInstance().trackEvent(MIXPANEL_EVENTNAME_PAGEVIEWED, { "Component": MIXPANEL_PAGENAME_PROFILER });
    getData();
  }, [slug, getData, title]);

  return (
    <div className="profiler__content">
      <h1>{title}</h1>
      <div className="profiler__content__data">
        <ProfilerGraph src={graphFile?.file} />

        {tabs.length > 0 && (
          <React.Fragment>
            <Box display={"flex"} alignItems={"flex-end"} flexDirection={"column"}>
              <Box display={"flex"}>
                <Button startIcon={<CloudDownloadOutlined />} variant={"text"} color={"secondary"} onClick={() => {
                  onDownloadReport();
                }}>Download report</Button>

                <IconButton
                  aria-label="more"
                  id="long-button"
                  aria-controls="long-menu"
                  aria-expanded={open ? "true" : undefined}
                  aria-haspopup="true"
                  onClick={handleClick}
                >
                  <MoreVert />
                </IconButton>
                <Menu
                  id="long-menu"
                  MenuListProps={{
                    "aria-labelledby": "long-button",
                  }}
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: "visible",
                      filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                      mt: 1.5,
                      "&:before": {
                        content: "\"\"",
                        display: "block",
                        position: "absolute",
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: "background.paper",
                        transform: "translateY(-50%) rotate(45deg)",
                        zIndex: 0,
                      },
                    },
                  }}
                  transformOrigin={{ horizontal: "right", vertical: "top" }}
                  anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                >
                  <MenuItem onClick={() => {
                    onRegenerateReport();
                    handleClose();
                  }}>
                    <ListItemIcon>
                      <AutorenewOutlined />
                    </ListItemIcon>
                    {"Regenerate Report"}
                  </MenuItem>
                  <MenuItem onClick={() => {
                    downloadCSV();
                    handleClose();
                  }}>
                    <ListItemIcon>
                      <CloudDownloadOutlined />
                    </ListItemIcon>
                    {"Download CSV"}
                  </MenuItem>
                </Menu>
              </Box>
            </Box>
            <ContentTabs tabs={tabs.map((tab) => TABS_NAMES[tab.name].name)}>
              {tabs.map((tab) => (
                <ProfilerTable key={tab.name} data={tab.data} />
              ))}
            </ContentTabs>
          </React.Fragment>
        )}

        <KeyNotes slug={slug} isFetchingData={isFetching} />
      </div>
    </div>
  );
};

export default connect(
  (
    state,
    ownProps: {
      tableNames: string[];
      graphName: string;
    },
  ) => ({
    tableFiles: selectors.profiler.tableFiles(state)(ownProps.tableNames),
    graphFile: selectors.profiler.graphFile(state)(ownProps.graphName),
  }),
)(ContentData);
