import React from "react";
import { AttributeMetadata } from "../../../util/models";
import "./MetadataVisualization.css";

interface IProps {
  attributeMetadata: AttributeMetadata;
}

export default class MetadataVisualization extends React.Component<IProps> {
  public render() {
    return (
      <div className="metadata-visualization">
        <p>{this.props.attributeMetadata.fields.description}</p>
        <ul>
          <li>
            <span className="metadata-name">Unit</span>
            <span className="metadata-value">
              {this.props.attributeMetadata.fields.unit}
            </span>
          </li>
          <li>
            <span className="metadata-name">Range</span>
            <span className="metadata-value">
              from {this.props.attributeMetadata.fields.rangeMin} to{" "}
              {this.props.attributeMetadata.fields.rangeMax}
            </span>
          </li>
          <li>
            <span className="metadata-name">Mean μ</span>
            <span className="metadata-value">
              {this.props.attributeMetadata.fields.mean}
            </span>
          </li>
          <li>
            <span className="metadata-name">Std Dev σ</span>
            <span className="metadata-value">
              {this.props.attributeMetadata.fields.standardDeviation}
            </span>
          </li>
        </ul>
      </div>
    );
  }
}
