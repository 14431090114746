import { filter } from "ramda";

import { Building, Property, Unit, Portfolio } from "./manager.types";

// eslint-disable-next-line
export default {
  updateUnit: ({ units, unit }: { units: Unit[]; unit: Unit }) => {
    const filteredUnits = filter(({ id }: Unit) => unit.id !== id, units);
    return [unit, ...filteredUnits];
  },
  updateBuilding: ({
    buildings,
    building,
  }: {
    buildings: Building[];
    building: Building;
  }) => {
    const filteredBuildings = filter(
      ({ id }: Building) => building.id !== id,
      buildings,
    );
    return [building, ...filteredBuildings];
  },
  updateProperty: ({
    properties,
    property,
  }: {
    properties: Property[];
    property: Property;
  }) => {
    const filteredProperties = filter(
      ({ id }: Property) => property.id !== id,
      properties,
    );
    return [property, ...filteredProperties];
  },
  updatePortfolio: ({
    portfolios,
    portfolio,
  }: {
    portfolios: Portfolio[];
    portfolio: Portfolio;
  }) => {
    const filteredProperties = filter(
      ({ id }: Portfolio) => portfolio.id !== id,
      portfolios,
    );
    return [...filteredProperties, portfolio];
  },
  // mapDataWithRating: (columns: any[], data: any[]) =>
  //   data.map((prop: any) => {
  //     const rating = prop.rating_data.columns || [];
  //     const ratingData = columns.reduce((prev, current) => {
  //       const values: any = rating.find((val: any) => val.label === current);
  //       return {
  //         ...prev,
  //         [current]: {
  //           label: current,
  //           rating: values ? values.rating : "N/A",
  //           is_edited: values ? values.is_edited : false,
  //         },
  //       };
  //     }, {});

  //     return {
  //       ...prop,
  //       rating_mapped: ratingData,
  //     };
  //   }),
  mapRatingColumns: (columns: any[]) =>
    columns.map((column) => ({
      Header: column,
      accessor: (originalRow: any) => {
        const rating = originalRow.rating_data.columns || [];
        const values: any = rating.find((val: any) => val.label === column);
        return values ? values.rating : "N/A";
      },
    })),
  getaAccessor: (row: any, column: string) => {
    const rating = row.rating_data.columns || [];
    const values: any = rating.find((val: any) => val.label === column);
    return values ? values.rating : "N/A";
  },
};

// `rating_mapped.${column}.rating`

// rating_data:
// 0: {label: "Another5Layers", rating: "N/A", is_edited: false}
// 1: {label: "Distances", rating: "N/A", is_edited: false}
// 2: {label: "NewConfig", rating: 4, is_edited: false}
// 3: {label: "Test5Layers", rating: "N/A", is_edited: false}
// 4: {label: "gastro", rating: 1, is_edited: false}
// 5: {label: "pub_railnoise", rating: "N/A", is_edited: false}
// 6: {label: "usefit_ba_price", rating: "N/A", is_edited: false}
