import React from "react";
import Loadable from "react-loadable";

import {AsyncLoadingIndicator} from "../../components/elementar";
import withUser from '../../components/tools/withUser/withUser';



const LoadableComponent = Loadable({
  loader: () => import("./ScoutPage"),
  loading: AsyncLoadingIndicator
});

class ScoutPageAsync extends React.Component {
  public render() {
    return <LoadableComponent/>;
  }
}

export default withUser(ScoutPageAsync)